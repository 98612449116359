@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.accordion-section {
  padding: 2.5rem 0 4rem;
  text-align: center;
  @include mobile {
    padding: 3rem 0;
  }
  .title {
    margin: 1.5rem 0 2.5rem;
  }
  h2:has(+ p) {
    margin: 0 auto !important;
  }
  .sub-title {
    margin: 1.25rem 0 2.5rem;
    line-height: 22px;
  }
  span {
    font-size: 1.25rem;
    line-height: 2rem;
    color: $color-darkgray;
    margin: 0;
    @include mobile {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
    &.text {
      padding: 0.625rem 0px 35px 0px;
    }
    &.content {
      padding: 0.625rem 0px 35px 0px;
      font-size: 1rem;
      line-height: 1.25rem;
      @include mobile {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
    &.section-heading {
      font-size: 1.5rem;
      line-height: 2.125rem;
      @include mobile {
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
  }
  .button {
    &.blue {
      background-color: $mpg-color-blue-50;
      border-color: $mpg-color-blue-50;
      color: $white !important;
      margin-top: 1.25rem;
      &:hover {
        background-color: $mpg-color-blue-75;
        border-color: $mpg-color-blue-75;
      }
    }
  }
  .accordion-item {
    position: relative;
    text-align: left;
    border-bottom: 1px solid $color-light-body;
    cursor: pointer;
    &:first-child {
      border-top: 1px solid $color-light-body;
    }
    &:last-child {
      margin-bottom: 2.5rem;
    }
    .accordion-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 1.875rem;
      &:focus-visible {
        @include outlineFocus();
      }
      .accordion-title {
        @include heading(4);
        font-size: 1.25rem;
        padding: 0;
        margin-bottom: 0px;
        @include mobile {
          font-size: 1rem;
          line-height: 1.5rem;
        }
        .missing-text {
          color: $color-maroon;
          opacity: 1;
          font-size: 0.875rem;
          font-style: italic;
          margin-left: 0.625rem;
        }
      }
      .icon {
        width: 0.75rem;
        height: 0.75rem;
        border-left: 2px solid;
        border-bottom: 2px solid;
        color: inherit;
        transform: rotate(-45deg);
        transition: all 200ms ease-in-out;
        outline: none !important;
        &:focus-visible {
          outline: 5px auto -webkit-focus-ring-color !important;
        }
        @include mobile {
          top: 3px;
        }
      }
    }

    .accordion-content {
      display: none;
      margin: 0;
      height: 0;
      max-height: 0;
      font-size: 1.25rem;
      line-height: 2rem;
      @include mobile {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
      a {
        white-space: initial;
        font-weight: 400;
        font-size: inherit;
        @include ctaLink($mpg-color-blue-100, $mpg-color-blue-75);
        display: inline;
        &.cta-text {
          @include anchor();
          display: inline;
          &--orange {
            @include ctaLink(
              $ui-primary-button-color,
              $ui-primary-button-color-hover
            );
          }
          &--darkblue {
            @include ctaLink($mpg-color-blue-100, $mpg-color-blue-75);
          }
          &--blue {
            @include ctaLink(
              $ui-secondary-button-color,
              $ui-secondary-button-color-hover
            );
          }
        }
      }
    }
    &.active {
      .accordion-header {
        .icon {
          transform: rotate(135deg);
        }
      }
      .accordion-content {
        display: block;
        height: 100%;
        max-height: 100%;
        transition: max-height 400ms ease-in-out;
      }
    }
    &.gradient__green-blue {
      .accordion-header {
        $gradientVal: $mpg-color-blue-100, $mpg-color-green-100;
        @include gradient(267deg, $gradientVal);
        color: $white;
      }
    }
    &.gradient__blue-violet {
      .accordion-header {
        $gradientVal: $mpg-color-blue-100, $color-darkblue;
        @include gradient(to top, $gradientVal);
        color: $white;
      }
    }
  }
  &.standard {
    background-color: $white-faded;
    .accordion-item {
      border-radius: 0.75rem;
      padding: 0;
      border-bottom: none;
      box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.08);
      overflow: hidden;
      margin-bottom: 1.25rem;
      .accordion-title {
        font-family: $font-heading;
        font-weight: 600;
        @include mobile {
          font-size: 1rem;
          line-height: 1.2;
          padding: 1.25rem 2.5rem 1.25rem 1.875rem;
        }
      }
      .accordion-content {
        background-color: $white;
        padding: 1.5rem 2.25rem 1.5rem 2.25rem;
        @include mobile {
          padding: 1.25rem 2rem 1rem 2rem;
        }
      }
      &:first-child {
        border-top: none;
      }
      .accordion-header {
        &:focus {
          outline-offset: -5px;
        }
      }
    }
  }
  &.utility {
    .accordion-item {
      .accordion-header {
        padding: 1.5rem 0;
      }
      .accordion-content {
        padding: 0 2rem 0.25rem 0;
      }
    }
  }
  &.standard,
  &.utility {
    .accordion-content {
      * {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        color: $color-darkgray;
      }
      span {
        font-size: 1rem;
        line-height: 1.5;
      }
      div {
        line-height: 1.5;
      }
      h5 {
        @include heading(5);
        letter-spacing: initial;
        text-transform: uppercase;
      }
      ul,
      ol {
        padding-left: 2rem;
        li {
          line-height: 1.5;
          a {
            display: inline;
          }
        }
      }
      img {
        width: 100%;
      }
    }
  }
}
