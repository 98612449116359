@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.std-large-card-container {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 52px 0px;
}

.std-large-card-image {
  border-radius: 0px;
  padding: 100px 150px;
  text-align: center;
  color: $white;
  background-blend-mode: multiply;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;
  @media (min-width: 1024px) and (max-width: 1400px) {
    padding: 10px 80px;
  }
}

.std-large-card-title {
  font-size: 48px;
  margin-bottom: 10px;
}

.std-large-card-body {
  font-size: 1rem;
  margin-bottom: 20px;
}

.std-large-card-content-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  text-align: left;
  margin: 100px auto;
}
.std-large-card-button{
  padding-top: 36px;
}
@include mobile {
  .std-large-card-container {
    padding-bottom: 0px;
    grid-template-columns: 1fr;
    height: auto
  }
  .std-large-card-content-wrapper{
    height: auto;
  }
  .std-large-card-title {
    font-size: 32px;
  }
  .std-large-card-image{
    padding: 30px
  }
}