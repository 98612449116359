@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  background-color: transparent !important;

  li {
    &:last-child {
      min-width: 100px;
      position: relative;
      // top: -2px;
      @include mobile {
        top: 0;
      }
      @include tablet {
        top: 0;
      }

      &:after {
        display: none;
      }
      a {
        &:hover,
        &:focus {
          opacity: 1 !important;
        }
         text-transform: none;

        color: $color-lightgray !important;
        cursor: initial;
        letter-spacing: normal;
        text-decoration: underline;
      }
    }
    a,
    .breadcrumb-link {
      @include heading(5);
      line-height: 18px;
      color: $color-blue !important;
      letter-spacing: 0.72px;
      .breadcrumb-link:hover {
        cursor: pointer;
      }
      &:hover,
      &:focus {
        opacity: 0.6;
      }
      text-transform: none;
    }
    .breadcrumb-link {
      cursor: pointer;
    }
  }
}

.insights-breadcrumb-item {
    display: flex;
    align-items: center;
    .home-icon{
      width: 16px;
      height: 16px;
      @include mobile{
        width: 12px;
        height: 12px;
      }
    }
  }
  
  .insights-breadcrumb-item svg {
    margin: 0px 2px;
  }
    
  .breadcrumb-item.last-item svg {
    display: none;
  }

