@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.countries {
    display: block;
    font-family: $font-copy;
    font-size: 1.125rem;
    list-style: none;
    padding-left: 2rem;
    li {
      word-break: break-word;
      span {
        color: $color-darkgray;
        padding-right: 5px;
        font-size: 1.125rem;
      }
    }
    a {
      padding: 0 5px;
      color: $color-blue;
      font-size: 1rem;
      line-height: 2;
      font-weight: 400;
    }
  }

  .accordion-section .accordion-item .accordion-content .countries a {
    padding: 0 5px;
    color: $color-blue;
    font-family: $font-copy;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    letter-spacing: normal;
    text-transform: none;
    &:hover {
      text-decoration: underline;
    }
  }