@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.site-header {
  .main-nav__item {
    .sub-nav {
      .sub-nav__list {
        .sub-nav__item {
          a {
            line-height: 15px;
            color: $color-darkgray;
            padding: 4px 0;
            &.sub-nav__link--highlighted {
              text-shadow: 0px 0px 1px $color-orange;
              color: $color-orange;
            }
            span {
              font-family: $font-copy;
              font-weight: 400;
              font-size: 1rem !important;
              line-height: 22px;
              &::after {
                content: none;
              }
            }
          }
          &.sub-nav__item--bold {
            &:first-of-type {
              padding-top: 0;
            }
            padding-top: 0.625rem;
            a {
              color: $color-lightblue;
              &.sub-nav__link--highlighted {
                text-shadow: 0px 0px 1px $color-orange;
                color: $color-orange;
              }
              span {
                font-family: $font-helvetica-bold;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}
