@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.newsletter-calloutbar-insights {
  padding: 20px 0;
  color:  $white;
  position: relative;

  @include mobile{
    padding: 20px;
    background: $pale-grey;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .newsletter-calloutbar-insights-container{
    border-radius: 28px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;

    @media (min-width: 1024px) and (max-width: 1400px) {
      margin: 15px;
    }
  }

  .newsletter-icon-div{
    display: flex;
    justify-content: center;
  }

  .newsletter-icon-container {
    text-align: center;
    .newsletter-icon {
      max-width: 50px;
      margin-bottom: 10px;
      width: 48px;
      height: 46px;

      @include mobile{
        width: 28px;
        height: 28px;
      }

      &.d-none {
        display: none;
      }

      &.d-block {
        display: block;
      }

      &.d-md-none {
        display: none;
      }

      &.d-md-block {
        display: block;
      }
    }
  }

  .newsletter-content {
    text-align: left;
    padding: 0px 8px;

    .newsletter-title {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .newsletter-description {
      font-size: 14px;
      margin: 8px 0px; 
    }
  }

  .hubspotForm {
    padding: 0px 30px 0px 15px;
    .hubspot-title {
      margin-bottom: 20px;

      .hubspotformTitle {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .hs-main-font-element {
        p{
          display: none;
        }
    }
    .hubspot-form-render {
      // Add any additional styles for the Hubspot form here
      .hs-richtext {
       p{
        margin: 10px 0px;
        
        span,a{
          color: $white !important;
          font-size: 12px;
        }
       } 
      }
      .hs-input{
        border-radius: 28px;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color-darkgray-50;
        background: $white;
        color:$black;
        height:3rem;
      }
      .hs-button{
        height: 2.5rem;
        padding: 0px 30px;
        background-color: rgb(224, 105, 15);
        border-color: rgb(224, 105, 15);
      }

      .legal-consent-container .hs-form-booleancheckbox-display input{
        margin-top: 12px;
        height: auto;
      }

      .legal-consent-container .hs-form-booleancheckbox-display p{
        color:  $white;
        a{
          color:  $white;
          
        }
      } 

    .legal-consent-container .hs-error-msgs label{
        color: $white;
        margin: 4px 16px;
    }
    .submitted-message{
        text-align: center;
    }
    .hs-error-msgs .hs-main-font-element{
        margin: 4px 16px;
    }
    .hs-email {
        label>span{
           display: none;
        }
    }
    .hs-submit{
      margin-top: 15px;
    }

    }
  }
  

  .newsletter-icon-container{
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 60px;
    background:$white;
    width: 90px;
    height: 90px;
        svg{
            position: absolute;
            left: 33px;
            top: 17px;
        
        }
    @include mobile{
      width: 75px;
      height: 75px;
    }    
  }

  .row-newsletter-container{
        padding: 20px 0px;
  }
  
  .newsletter-icon-div, .newsletter-content-div, .newsletter-form-div{
    padding: 0px;
  }

}


@include mobile {
    .newsletter-calloutbar-insights {
        .newsletter-icon-div{
            justify-content: left;
          }
          .newsletter-icon-div, .newsletter-content-div, .newsletter-form-div{
            padding: 0px 15px;
          } 
    }

    .row-newsletter-container{
        padding: 0px;
     }


    .newsletter-icon-container {
      text-align: left;
      .newsletter-icon {
        max-width: 40px;
        margin: 0 auto 10px;
      }
    }

    .newsletter-content {
      margin-top: 20px;
      text-align: left;

      .newsletter-title {
        font-size: 20px;
      }

      .newsletter-description {
        font-size: 14px;
      }
    }

    // hubspot form
    .newsletter-calloutbar-insights{
         .hubspotForm {
            padding: 0px;
         }
    }

}
