@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
$input-transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);

.sitecore-form {
  form {
    fieldset.row {
      position: relative;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
      margin: 0 0 1rem 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    button,
    input {
      overflow: visible;
    }

    input:not([type='checkbox']) {
      display: block;
      width: 100%;
      height: 4rem;
      line-height: 4rem;
      border: 1px solid rgba(103, 105, 111, 0.5);
      border-radius: 2px;
      background-color: $white;
      color: #282a32;
      font-family: $font-copy;
      font-size: 1rem;
      padding: 0 1.25rem;
      outline-color: rgba(0, 0, 0, 0);
      transition: $input-transition;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    input[type='checkbox'] {
      position: relative;
      width: 1.25rem;
      height: 1.25rem;

      &:before {
        position: relative;
        content: '';
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        background: $white;
        border: 1px solid $color-lightgray;
        border-radius: 2px;
      }

      &:after {
        position: absolute;
        content: '';
        display: block;
        left: 0;
        top: 0;
        width: 1.25rem;
        height: 1.25rem;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:checked {
        &:before {
          background-color: $mpg-color-blue-100;
        }

        &:after {
          left: 4px;
          top: 6px;
          height: 6px;
          width: 12px;
          border-left: 2px solid $white;
          border-bottom: 2px solid $white;
          transform: rotate(-45deg);
        }
      }
    }
    input[type='radio'],
    input[type='checkbox'] {
      box-sizing: border-box;
      padding: 0;
    }

    .form-label {
      display: none;
    }

    button {
      display: inline-block;
      align-items: center;
      text-align: center;
      border: 2px solid $ui-primary-button-color;
      background-color: $ui-primary-button-color;
      color: $white !important;
      font-family: $font-heading;
      font-size: 1.188rem;
      font-weight: 600;
      letter-spacing: 1.2px;
      border-radius: 2rem;
      line-height: 1.2;
      padding: 1.125rem 2.125rem 0.875rem;
      text-transform: uppercase;
      transition: $btn-transition;

      @include mobile {
        padding: 1.125rem 1.125rem 0.875rem;
      }

      &:hover {
        border-color: $ui-primary-button-color-hover;
        background-color: $ui-primary-button-color-hover;
        opacity: 1;

        .cta-icon {
          transform: translateX(5px);
        }
      }
    }

    select {
      display: block;
      width: 100%;
      max-width: 100%;
      box-shadow: none;
      height: 4rem;
      line-height: 1;
      border: 1px solid rgba(103, 105, 111, 0.5);
      border-radius: 2px;
      color: #282a32;
      font-family: $font-copy;
      font-size: 1rem;
      padding: 0 2.25rem 0 1.5rem;
      outline-color: rgba(0, 0, 0, 0);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      background-color: #fff;
      background-image: url(../../../assets/images/chevron-down.svg);
      background-repeat: no-repeat;
      background-position: right 0.7em top 50%, 0 0;
      background-size: 1.125rem auto, 100%;
    }

    textarea {
      display: block;
      width: 100%;
      border: 1px solid rgba(103, 105, 111, 0.5);
      border-radius: 2px;
      background-color: #fff;
      color: #282a32;
      font-family: $font-copy;
      font-size: 1rem;
      padding: 1rem 1.25rem 0;
      outline-color: rgba(0, 0, 0, 0);
      transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
      min-height: 12.5rem;
      box-shadow: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: calc(100% - 1rem);
    }

    .consent {
      display: flex;
      p {
        font-size: 0.6875rem;
        padding-left: 10px;
        margin: 0 0 0.875rem 0;
      }
    }
    .position-center {
      display: flex;
      justify-content: center;
    }
    .btn-reversed {
      button {
        color: #c25700 !important;
        background-color: #fff;
        border: 0;
        // padding: 0;
        &:hover {
          color: #e0690a !important;
          opacity: 1;
        }
      }
    }

    .form-errors {
      display: none;
      // color: red;
    }

    fieldset {
      input:has(+ div.invalid > p) {
        border: 1px solid $mpg-color-red-100;
        box-shadow: 0 0 0 1px $mpg-color-red-100;
      }
      > div.invalid p {
        font-size: 0.75rem;
        color: red;
      }
    }

    h2 {
      @include heading(5);
      padding-bottom: 1.5rem;
      margin-bottom: 0;
      font-weight: 600;
      letter-spacing: 1.125px;
    }
  }

  &.sitecore-form--newsletter {
    p {
      margin: 0 0 0.875rem 0;
    }
    h2 {
      color: $mpg-color-blue-100;
      font-size: 1rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 0;
    }
    form h2 {
      display: none;
    }
  }
  &.sitecore-form--contact_us {
    padding: 4rem 0 4.5rem;
    @include mobile {
      padding: 2rem 0;
    }
    h2 {
      @include heading(2);
      color: $color-darkgray-100;
    }
  }
}
