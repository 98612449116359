@import '../../../styles/scss/colors';

.social-share-container {
    display: flex;
    gap: 16px;

    .social-share {
        display: flex;
        z-index: 1;
        flex-direction: column;
        padding: 8px;
        gap: 16px;
        position: absolute;
        bottom: 25%;
        right: 0%;
        border-radius: 16px 0px 0px 16px;
        background: $white;
        box-shadow: 0px 0px 24px 0px rgba(147, 148, 152, 0.20);

        a {
            display: flex;
            align-items: center;
            justify-content: center;

            .social-icon-circle {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                border: 1px solid $mpg-color-blue-100;
                display: flex;
                align-items: center;
                justify-content: center;

                img{
                    font-size: 10px;
                }
            }
        }
    }

    .social-share-button {
        display: none;
        z-index: 1;

        .share-toggle {
            display: flex;
            width: 38px;
            height: 38px;
            position: absolute;
            bottom: 20%;
            right: 8px;
            cursor: pointer;
            border: none;
            box-shadow: 0px 0px 10px 0px rgba(56, 96, 151, 0.40);
            border-radius: 50%;
            backdrop-filter: blur(2px);
            background: linear-gradient(90deg, $mpg-color-green-75 0%, $mpg-color-blue-50 46.87%, $color-blue 100%);
            border: 1px solid $mpg-color-green-75;
            align-items: center;
            justify-content: center;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    .social-share-container {
        display: flex;
        //gap: 16px;

        .social-share {
            display: none;
            padding: 8px;
            gap: 8px;
            bottom: 30%;
            border-radius: 8px 0px 0px 8px;

            &.mobile-social-share {
                display: flex;
                transition: all 0.7s;
            }

            a {
                .social-icon-circle {
                    width: 38px;
                    height: 38px;
                }
            }
        }

        .social-share-button {
            display: flex;

            .share-toggle {
                padding: 0%;
            }
        }
    }
}