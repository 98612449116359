@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/theme';

.banner-section {
  background-color: transparent;
  position: relative;
  @include tablet {
    min-height: initial;
  }
  @include flexbox();
  @include align-items(center);

  .container {
    @include flexbox();
    @include align-items(center);
    .row {
      @include flexbox();
      @include align-items(center);
    }
  }
  &.banner--content {
    .banner-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: 75%;
      background-repeat: no-repeat;
      @include tablet {
        background-position: 100%;
      }
      @include mobile {
        position: static;
        width: 100%;
        height: 18rem;
        margin-bottom: 2rem;
        background-position: 100%;
      }
    }
    .content-wrapper {
      min-width: 100%;
      .banneroverlay {
        background-color: rgba(255, 255, 255, 0.9) !important;
        border-radius: 0.875rem;
        padding: 3rem 6rem 3.5rem 3rem;
        @include desktop {
          min-width: 600px;
        }
        @include tablet {
          min-width: unset;
        }
        @include mobile {
          padding: 0 1rem;
        }
        > :first-child {
          margin-top: 0;
        }
      }

      .sub-title {
        font-family: $font-heading;
        font-size: 1.125rem;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.72px;
        color: $mpg-color-blue-100;
      }
      .breadcrumb {
        padding: 0;
        li a {
          line-height: 1;
          letter-spacing: 0.72px;
          @include mobile {
            font-size: 1rem !important;
          }
        }
      }
      .title {
        @include heading(2);
        margin: 0;
        margin-top: 1rem;
        // @include mobile {
        //   margin-top: 1rem;
        // }
      }
      p.large {
        margin: 0;
        margin-top: 1rem;
        line-height: 26px;
        // @include mobile {
        //   margin-top: 1.5rem;
        // }
      }
      .button {
        margin-top: 1rem;
      }
    }
  }
  &.banner--standard {
    padding: 3rem 0 6rem;
    min-height: 35rem;
    @include mobile {
      padding: 0 0 2rem;
      @include flex-direction(column);
    }
  }
  &.banner--subpage {
    padding: 5rem 0;
    min-height: 28rem;
    @include tablet {
      min-height: initial;
    }
    @include mobile {
      padding: 0 0 2rem;
      @include flex-direction(column);
    }
  }

  /* Gated Content Page Banner */
  &.campaigns {
    padding: 5rem 0;
    &.default {
      color: #fff;
      background-color: $color-darkgray-100 !important;
    }
    .container {
      @include mobile {
        padding-left: 1.875rem !important;
        padding-right: 1.875rem !important;
      }
    }
    .title {
      margin-bottom: 1.5rem;
      @include heading(2);
    }

    div.large,
    p {
      margin: 0;
      font-size: 1.25rem;
      line-height: 26px;
    }
    div.large {
      line-height: 1.75;
    }

    .sub-header {
      border-bottom: 0.25rem solid $white;
      margin-bottom: 2.5rem;
      &.green-blue {
        @include gradientBorderBottom(0.75rem, $linear-gradient-green-blue);
        border-radius: 0.875rem 0.875rem 0 0;
        border-bottom: 0.25rem solid;
      }
      &.blue-violet {
        @include gradientBorderBottom(0.75rem, $linear-gradient-blue-violet);
        border-radius: 0.875rem 0.875rem 0 0;
        border-bottom: 0.25rem solid;
      }
      @include mobile {
        margin-bottom: 1.25rem;
      }
      &.dark,
      &.default {
        border-bottom: 0.25rem solid $white;
      }
      &.light {
        border-bottom: 0.25rem solid $color-darkgray-100;
      }
      .sub-title {
        font-size: 1.25rem;
        font-weight: 600;
        font-family: $font-heading;
        text-transform: uppercase;
        line-height: 26px;
        margin-bottom: 8px;
      }

      .date {
        font-size: 1rem;
        font-family: $font-heading;
        margin-bottom: 2rem;
        line-height: 1;
        font-weight: 400;
        text-transform: capitalize;
        @include mobile {
          margin-bottom: 1.25rem;
        }
      }
    }

    .primary-button {
      margin-top: 2rem;
      // &.orange-sd {
      //   // font-size: 1.125rem;
      //   border-radius: 2rem;
      //   margin-top: 2rem;
      //   text-transform: uppercase;
      //   padding: 1.125rem 2.125rem 0.875rem;
      //   @include mobile {
      //     padding: 1.125rem 1.125rem 0.875rem;
      //   }

      //   .arrow-icon {
      //     display: none !important;
      //   }
      // }
    }

    .social-icons {
      margin-top: 3rem;
      .social-icon {
        margin-right: 3rem !important;
        @include tablet {
          margin-right: 2rem !important;
        }
        @include mobile {
          margin-right: 2.5rem !important;
        }
        img {
          max-height: 2rem;
        }
      }
    }
    .filter {
      .social-icon {
        img {
          filter: contrast(3);
        }
      }
    }

    .featured-image {
      display: flex;
      align-items: center;
      justify-content: center;

      @include tablet {
        justify-content: flex-start;
        margin-top: 1.5rem;
        display: none;
      }

      img {
        max-width: 100%;
        border-radius: 0.875rem;
      }
      &.mobile {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }
    .video__title {
      display: flex;
      justify-content: center;
      margin-top: 0.625rem;
    }
    .video__duration {
      position: absolute;
      bottom: 0.625rem;
      right: 0.625rem;
      font-size: 0.75rem;
      font-weight: 400;
      color: $white;
      letter-spacing: normal;
      background-color: $black;
      padding: 0.25rem 0.5rem;
    }
    .video__link {
      display: block;
      margin: 1.5rem 0;
      cursor: pointer;
      position: relative;
    }
    .form-container--gated-content {
      .gated-content {
        .hubspotForm {
          padding: 0;
          color: inherit !important;
          .hubspot-render {
            form {
              .hs-richtext h2 span {
                color: inherit !important;
              }
              a,
              p,
              em {
                font-size: 0.8125rem;
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
  /* Gated Content Page Banner End */
}


// page-banner-image-insights component
.page-banner-image-insights{
  .page-banner-title-insights{
    margin: 0px;
  }

  .page-banner-topics-insights, .page-banner-topics-insights > span{
    overflow: hidden;
    color: $mpg-color-gray-50;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 12px; 
    margin: 12px 0px 24px 0px;
    .topics-icon{
      width: 16px;
      height: 16px;
      padding-right:2px
    }
  }

  .page-banner-bgimage-insights {
    display: block;
    width: 100%;
    height: auto;

    img, source {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 14px;
    }
  }


  @include mobile {
    .page-banner-title-insights{
      font-size: 32px;
    }
    .page-banner-topics-insights,.page-banner-topics-insights > span{
      font-size: 10px;
      margin: 8px 0px 16px 0px;
      .topics-icon{
        width: 12px;
        height: 12px;
      }
    }
  }
}