@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.hyperlinks-nav {
  padding: 3.5rem 0;
  h2 {
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 0;
  }
  .hyperlinks-navitem {
    margin: 27px 0;
    text-align: center;
    .hyperlink {
      font-family: $font-heading;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0;
    }
  }
}
