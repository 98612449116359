.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  
    .pagination-label {
      margin-right: 10px;
      font-size: 1.2em;
    }
  
    .pagination-button {
        margin: 0 5px;
        padding: 10px 15px;
        display: flex;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        width: 36px;
        height:36px;
        border: 1px solid #EFEFEF;
        background:#FFF;
      cursor: pointer;
  
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
  
      &:hover:not(:disabled) {
        background-color: #f1f1f1;
      }
    }
  
    .page-numbers {
      display: flex;
  
      .page-number {
        margin: 0 5px;
        padding: 10px 15px;
        display: flex;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        width: 36px;
        height:36px;
        border: 1px solid #EFEFEF;
        background:#FFF;
        &.active {
            background: #282A32;
            color:#fff;
        }
  
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }