@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.home-featured-insights {
    margin-left: 80px;

    @media (max-width: 768px) {
      margin-left: 0px;
    }
    @media (min-width: 768px) and (max-width: 940px) {
      margin: 0px 15px;
    }

    .featured-title {
      margin-top: 0.5rem;
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }

    .home-featured-topics-insights{
        margin: 6px 0px 6px 0px;
        span{
            overflow: hidden;
            color: $mpg-color-gray-50;
            text-overflow: ellipsis;
            font-size: 12px;
            line-height: 12px;
        }
        .topics-icon{
          width: 16px;
          height: 16px;
        }
    }

    .featured-card{
        border-bottom: 1px solid $mpg-color-gray-25;
        padding: 10px 0px;    
        width: 100%;
    }
  
    .desktop-view {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
  
      .featured-card {
  
        .card-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
        }
  
        .card-date,
        .card-topic {
          font-size: 14px;
          margin-bottom: 10px;
        }
  
        .card-cta {
          font-size: 20px;
          font-family: "Inter";
          line-height: 20px;
          font-weight: 600;
          color: $mpg-color-orange-55;
          text-decoration: none;
          &:hover {
            color: $mpg-color-orange-100;
        }
        }
        .home-featured-cta-container{
        display: flex;
        align-items: center;
        cursor: pointer;
        .card-icon-container {
            display: flex;
            align-items: center;

            .cta-icon {
                width: 20px;
                height: 20px;
                object-fit: contain;
                margin-left: 5px;
                margin-top: 6px;
            }
        }}
      }
    }
  
    .mobile-view {
      display: none;
    }
  
    @media (max-width: 767px) {
     margin-left: 0px;
     padding: 5px;
        
      .desktop-view {
        display: none;
      }

      .featured-title {
        padding: 0px 20px;
      }

      .featured-card{
        border-bottom: 1px solid $mpg-color-gray-25;
        padding: 10px 0px;    
        width: auto;
    }
  
      .mobile-view {
        display: block;
      }
    }

    @media (max-width:768px){
      .card-cover {
        background: $white;
        border: 1px solid $pale-grey;
        border-radius: 14px;
        padding: 15px;
      }
  
      .slick-slide {
        margin: 6px !important;
  
      }
      .slick-slider{
        margin: 15px;
      }

      .slick-prev,.slick-next{
        display: none !important;
      }
  
      .card-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
  
        .card-body {
          display: flex;
          flex-direction: column;
          padding: 0px 4px;
  
          .card-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
  
            .publication-details > span {
              margin: 8px 0px;
            }
  
            .publication-details {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 0;
  
              .icon-container {
                padding: 0px 5px 0px 0px;
                margin: 0px !important;
  
                .icon {
                  height: 16px;
                  width: 16px;
                }
              }
  
              .publication-details-text {
                font-family: $font-heading;
                font-size: 12px;
                line-height: 13px;
                font-weight: 400;
                color: $mpg-color-gray-50;
                padding-left: 5px;
  
                @include mobile {
                  font-size: 10px;
                  line-height: 13px;
                }
              }
  
              .topic {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 20ch;
  
                @include mobile {
                  display: inline-block;
                  max-width: 18ch;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
  
                @media (width: 768px) {
                  max-width: 10ch;
                }
              }
            }
          }
        }
  
        .story-text {
          display: flex;
          flex-grow: 1;
  
          .story-title {
            color: $color-darkgray;
            font-family: Inter;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            @include mobile {
              min-height: 110px;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              max-width: fit-content;
              line-height: 27px;
            }
          }
        }
  
        .cta-container {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding-top: 25px;
  
          .cta-link {
            font-family: $font-heading;
            font-size: 20px;
            line-height: 20px;
            font-weight: 600;
            color: $mpg-color-orange-55;
            text-decoration: none;
            text-align: left;
            display: flex;
            align-items: center;
  
            @include mobile {
              font-size: 17px;
              line-height: 20px;
            }
  
            &:hover {
              color: $mpg-color-orange-100;
            }
          }
  
          .icon-container {
            display: flex;
            align-items: center;
  
            .cta-icon {
              width: 20px;
              height: 20px;
              object-fit: contain;
              margin-left: 5px;
              margin-top: 6px;
            }
          }
        }
      }
    }


  
}