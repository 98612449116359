@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  background-color: transparent !important;

  li {
    &:last-child {
      min-width: 100px;
      position: relative;
      // top: -2px;
      @include mobile {
        top: 0;
      }
      @include tablet {
        top: 0;
      }

      &:after {
        display: none;
      }
      a {
        &:hover,
        &:focus {
          opacity: 1 !important;
        }
        // text-transform: uppercase;

        color: $color-lightgray !important;
        cursor: initial;
        letter-spacing: normal;
        text-decoration: underline;
      }
    }
    &:after {
      position: relative;
      top: -2px;
      @include mobile {
        top: 0;
      }
      @include tablet {
        top: 0;
      }
      display: inline-block;
      content: '>';
      color: $color-light-body;
      font-family: $font-heading;
      font-size: 1.125rem;
      padding: 0 8px;
    }
    a,
    .breadcrumb-link {
      @include heading(5);
      line-height: 18px;
      color: $color-blue !important;
      letter-spacing: 0.72px;
      .breadcrumb-link:hover {
        cursor: pointer;
      }
      &:hover,
      &:focus {
        opacity: 0.6;
      }
      // text-transform: uppercase;
    }
    .breadcrumb-link {
      cursor: pointer;
    }
  }
}
