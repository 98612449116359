@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';

// Manpower Theme Filters Components Overrides
.article-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1.25rem 0;
  @include mobile {
    flex-direction: column;
    align-items: flex-end;
    margin: 0 1.5rem 1.5rem;
  }
  .filter {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0.625rem;
    justify-content: flex-end;
  }
  .fullwrapper {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .searchbtn {
    background: none;
    position: absolute;
    right: 1.125rem;
    top: 1.375rem;
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
    background-repeat: no-repeat;
    border: none;
  }
  .filterwrapper {
    width: auto;
    border: 2px solid $color-lightgray;
    border-radius: 1.688rem;
    opacity: 1;
    box-shadow: 0 0 6px $color-jet-black;
    color: $color-dark-border;
    padding-right: 10px;
    padding-left: 5px;
    margin: 1px 2px 10px 2px;
    .closeDetail {
      padding-top: 5px;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1;
      color: $color-lightgray;
      text-shadow: 0 1px 0 $white;
      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }

  .closebutton span {
    text-align: center;
    white-space: nowrap;
    font: normal normal 600 8px $font-copy;
    letter-spacing: 0.9px;
    color: $color-lightgray;
    text-transform: uppercase;
    opacity: 1;
    padding: 5px;
    display: inline-flex;
    padding-bottom: 7px;
    line-height: 1;
  }
  .form-block-wrapper {
    @include mobile {
      width: 100%;
    }
  }
  .form-block {
    width: auto;
    @include mobile {
      width: 100%;
    }
    input {
      width: 500px;
      max-width: 100%;
      background-repeat: no-repeat;
      background-position: 95% 50%;
      padding-right: 60px;
      font-size: 1rem;
      padding: 0 1.25rem;
      height: 4rem;
      @include mobile {
        width: 100%;
      }
    }
  }
  .filter-block {
    margin-right: 0;
    margin-left: 5px;
  }
  .filter-block .filter-block-inner .text {
    font-family: $font-helvetica-med;
    font-weight: 400;
    font-size: 1rem;
  }
  .filter-block .dropdown-block {
    left: initial;
    right: 0;
    .title {
      font-family: $font-helvetica-med;
      font-weight: 400;
      font-size: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }
  .filter-block .dropdown-block .range-slider-container {
    display: none;
  }
}
.filter-block {
  position: relative;
  display: inline-block;
  .filter-block-inner {
    .icon-down.active {
      transform: rotate(180deg);
    }
  }
  .triangle {
    display: none;
    &.active {
      display: block !important;
    }
  }
  .filter-block-inner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    .filter-block-clicker {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      cursor: pointer;
      &.blocked {
        cursor: not-allowed;
      }
    }
    .icon-filters {
      display: flex;
      padding-right: 5px;
      img {
        height: 1rem;
        @include mobile{
          height: 24px;
          width: 24px;
        }
      }
      div.filter-icon {
        background-image: url('../../../../assets/images/icon-filters.svg');
        height: 1rem;
        width: 1.313rem;
        background-size: 100%;
        background-position: center;
        box-sizing: border-box;
        background-repeat: no-repeat;
      }
    }
    .icon-down {
      display: flex;
      padding: 0 5px;
      img {
        height: 0.75rem;
      }
      div.down-arrow {
        background-image: url('../../../../assets/images/icon-down.svg');
        height: 0.75rem;
        width: 0.75rem;
        background-size: 100%;
        background-position: center;
        box-sizing: border-box;
        background-repeat: no-repeat;
      }
    }
    .text {
      font-family: $font-heading;
      &.blocked {
        opacity: 0.5;
      }
    }
  }
  .triangle {
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: $white;
    border-top: 1px solid $color-dark-border;
    border-left: 1px solid $color-dark-border;
    left: 50%;
    top: 100%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 4;
    margin-top: 7px;
    display: none;
  }
}
