$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
);

// Bootstrap
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/grid';
// Modal
@import '../../../node_modules/bootstrap/scss/modal';

// Partials
@import 'fonts';
@import 'mixins';
@import 'helpers';
@import 'typography';
@import 'theme';

// $grid-breakpoints: ( // *TODO Investigate variables load order issue
//     xs: 0,
//     sm: 576px,
//     md: 768px,
//     lg: 992px,
//     xl: 1400px
// );

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// $container-max-widths: (
//     sm: 540px,
//     md: 720px,
//     lg: 960px,
//     xl: 1340px
// );

@include _assert-ascending($container-max-widths, '$container-max-widths');

$grid-columns: 12;
$grid-gutter-width: 30px;
$enable-grid-classes: true;

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-size: 16px;
  font-family: $font-copy;
  // @include mobile {
  //   font-size: 14px;
  // }
}

body {
  color: $color-darkgray;
  padding-top: 96px;
  @include tablet {
    padding-top: 65px;
  }
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.container {
  @include mobile {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
    .container {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

a {
  @include cta();
}
