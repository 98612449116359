$black: #000000;
$white: #ffffff;
$white-faded: #f3f3f4;
$pale-grey: #efefef;
$warm-grey: #dedede;

$color-blue: #386097;
$color-lightblue: #4c79af;
$color-orange: #c25700;
$color-teal: #5c7d70;
$green: #5c7d70;
$color-green: #5c7d70;
$color-medium-green: #5c7d70;
$color-lightgreen: #d6dedb;
$color-maroon: #9d323d;
$color-grayblue: #b1c7e2;

$color-lightgray: #67696f;
$color-gray-subtitle: #67696f;
$color-darkgray: #282a32;
$text-body: #282a32;
$color-footergray: #282a32;

$color-light-body: #a3a5aa;
$color-sidebar-item: #424242;
$color-light-border: #c9cacc;
$color-dark-border: #ababab;
$color-indicator: #83858b;

/* For Use w Gradients ONLY */
$color-darkblue: #1c3e6b;
$color-darkgreen: #355c4c;

/* DNU | Inverse Dark Colors - Dark Cards */
$color-inverse-blue: #7593bc;
$color-inverse-maroon: #b5656d;

$color-azure-blue: #30588a;
$charcoal: #36454f;
$color-black-light: rgba($black, 0.3);
$color-columbia-blue: #cbd5e3;
$color-celestial-blue: #3893cd;
$color-dazzled-blue: #345f9a;
$color-dark-slate-gray: #355c4c;
$color-blue-gray: #6391c6;
$color-black-transparent: rgba($color-darkgray, 0.001);
$color-graphite-gray: #5e5f65;
$color-indigo: #1c3e6b;
$color-iron: #d9d9db;
$color-pale-pink: #f8d7da;
$color-orchid-pink: #f5c6cb;
$color-peru: #d28140;
$color-accordion-grey: #212529;
$color-banner-gray: #494b54;
$color-smoky-gray: #f0f1f1;
$color-success-grey: #f5f5f5;
// $color-traffi-white: #eff2f6;
// $color-cream: #dcdcdc;
$color-ghost-white: #f9f9f9;
$color-lotion-white: #fbfbfb;
$color-chinese-silver: #ccc;
$color-bone: #dbd9bf;
$color-transparent: rgba($white, 0.001);
$color-chambray: #30588f;
$color-white-shade: #ffffff;
$color-black-olive: #3c3535;
$color-jet-black: #0000001f;
$color-black-richshade: #00000014;
$color-cobalt-blue: #8aacd5;
$color-dark-pastel-blue: #799bc3;
$color-palatinate-blue: #2e48e0;
$color-rainbow-indigo: #143460;
$color-button-blue: #2196f3;
$color-light-steel-blue: #a5bcd7;
$color-mangor-orange: #e0690a;
$color-vivid-orange: #e77c22;
$color-german-gray: #949599;
$color-italy-gray: #939498;
$color-platinum-gray: #979797;
$color-dark-black: #00000082;
$color-philippine-gray: #8d8e93;
$color-platinum: #e8e8e8;
$color-tele-gray: #919296;
$color-signal-gray: #999;
$color-cool-gray: #c8c8c8;
$color-css-gray: #808080;
$color-bright-gray: #eff0f0;
$color-dark-silver: #707070;
$color-antiflash-white: #f1f1f1;
$color-mouse-gray: #67696f80;
$color-xanadu-green: #6f8f82;
$color-green-oxley: #6e8f82;
$columbia-blue: #c9e0de;

$old-rose: #b57e7f;
$cultured: #f9f8f8;
$pale_green_light: #849d93;
$light-green: #d6dedb;
$pale_green: #859d94;
$color-hover: #6a88b1;
$color-hover-green: #92afa4;
$seo-blue: #386097;
$ship-cove: #7593bc;
$sunset-orange: #fd5e53;
$vivid-tangelo: #f07427;
$warm-black: #8d8f93;
$white-light: rgba($white, 0.6);
$linkedin-blue: #0077b7;
$color-banner-gray: #494b54;
$color-heavy-metal: #212521;
$color-success-grey: #f5f5f5;
$color-chambray: #30588f;
$color-transparent: rgba($white, 0.001);
$color-black-transparent: rgba($color-darkgray, 0.001);
$color-mine-shaft: #212121;
$color-botticelli: #d2ddeb;
$color-iron: #d9d9db;
$color-accordion-grey: #212529;
$color-black-light: rgba($black, 0.3);
$light-green: #d6dedb;
$linkedin-blue: #0077b7;
$color-darkblue: #1c3e6b;
$form-link-color: #494b5480;
$theme-color: (
  'experis': $color-blue,
  'jw': $green,
  'manpower': $green,
);
$color-lightgray-100: #67696f;
$dropdown-border-color: #ced4da;

/* All Brand Colors */
// DARK GRAY
$color-darkgray-100: #282a32;
$color-darkgray-75: #5e6066;
$color-darkgray-50: #939498;
$color-darkgray-25: #c9cacc;

//BLUE
$color-blue-100: #386097;
$color-blue-75: #6a88b1;
$color-blue-50: #9bafcb;
$color-blue-25: #cdd7e5;

//LIGHT BLUE
$color-lightblue-100: #4c79af;
$color-lightblue-75: #799ac3;
$color-lightblue-50: #a5bcd7;
$color-lightblue-25: #d2ddeb;

// ORANGE
$color-orange-100: #c25700;
$color-orange-75: #d18140;
$color-orange-50: #e0ab80;
$color-orange-25: #f0d5bf;

// GREEN
$color-green-100: #5c7d70;
$color-green-75: #859d94;
$color-green-50: #adbeb7;
$color-green-25: #d6dedb;

// LIGHT GRAY
$color-lightgray-100: #67696f;
$color-lightgray-75: #8d8f93;
$color-lightgray-50: #b3b4b7;
$color-lightgray-25: #d9d9db;

// RED
$color-red-100: #9d323d;
$color-red-75: #b5656d;
$color-red-50: #ce989e;
$color-red-25: #e6ccce;

/* 
 * NEW ManpowerGroup Colors 
 */

/* ORANGE */
$mpg-color-orange-100: #b43700;
$mpg-color-orange-75: #c25700; // OG Orange
$mpg-color-orange-50: #e0690a;
$mpg-color-orange-25: #ff7a12;
$mpg-color-orange-00: #ff9442; // used for reversed orange hover
$mpg-color-orange-55: #e0690f;

/* RED */
$mpg-color-red-100: #9d323d; // OG Red
$mpg-color-red-75: #cc334d;
$mpg-color-red-50: #e4415f;
$mpg-color-red-25: #fc5c7d;

/* VIOLET */
$mpg-color-violet-100: #4f4296;
$mpg-color-violet-75: #5c4bb9;
$mpg-color-violet-50: #736bde;
$mpg-color-violet-25: #9e94fa;

/* GREEN */
$mpg-color-green-100: #5c7d70; // OG Green
$mpg-color-green-75: #669e66;
$mpg-color-green-50: #70bf5c;
$mpg-color-green-25: #a6eb8c;

/* BLUE */
$mpg-color-blue-100: #386097; // OG Blue
$mpg-color-blue-75: #4c79af; // OG Light Blue
$mpg-color-blue-50: #3893cd;
$mpg-color-blue-25: #56bded;
$mpg-color-blue-00: #4895b9;

/* GRAY */
$mpg-color-gray-100: #282a32; // OG Dark Gray
$mpg-color-gray-75: #5e5f65;
$mpg-color-gray-50: #939498;
$mpg-color-gray-25: #efefef; // OG pale grey
$mpg-color-gray-70: #67696f;

/* 2 COLOR GRADIENTS */
$linear-gradient-blue: $mpg-color-blue-100, $mpg-color-blue-25;
$linear-gradient-green: $mpg-color-green-100, $mpg-color-green-25;
$linear-gradient-violet: $mpg-color-violet-100, $mpg-color-violet-25;
$linear-gradient-red: $mpg-color-red-100, $mpg-color-red-25;
$linear-gradient-orange: $mpg-color-orange-100, $mpg-color-orange-25;

/* MULTI COLOR GRADIENTS */
$linear-gradient-blue-violet: $mpg-color-blue-50, $mpg-color-violet-75,
  $mpg-color-violet-50;
$linear-gradient-orange-red: $mpg-color-orange-25, $mpg-color-red-25,
  $mpg-color-red-50;
$linear-gradient-green-blue: $mpg-color-green-50, $mpg-color-blue-50,
  $mpg-color-blue-100;

$ui-gradient-primary: $linear-gradient-green-blue;
$ui-gradient-secondary: $linear-gradient-blue-violet;

/* UI CONFIG */
$ui-color-text-default: $mpg-color-gray-100;
$ui-color-heading-default: $mpg-color-gray-100;
$ui-color-heading-blue: $mpg-color-blue-100;

$ui-primary-button-color: $color-orange;
$ui-primary-button-color-hover: $color-orange-75;

$ui-secondary-button-color: $mpg-color-blue-50;
$ui-secondary-button-color-hover: $mpg-color-blue-75;

$ui-bg-color-light: $white;
$ui-bg-color-gray: $mpg-color-gray-25;
$ui-bg-color-dark: $mpg-color-gray-100;
$ui-bg-color-orange: $mpg-color-orange-50;
$ui-bg-color-blue: $mpg-color-blue-50;
$ui-bg-color-green: $mpg-color-green-50;
$ui-bg-color-violet: $mpg-color-violet-50;

$ui-form-color-success: $mpg-color-green-75;
$ui-form-color-error: $mpg-color-red-100;
$ui-form-bg-color-error: rgba(230, 204, 206, 0.75);
