@import '../../../styles/scss/mixins';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
$input-transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);

form {
  font-family: $font-copy;
  font-size: 1rem;
  label {
    font-family: $font-copy;
    font-size: 0.75rem;
    transition: $input-transition;
    padding: 0;
    margin: 0;
  }
  input,
  button,
  select,
  textarea {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  input:not([type='checkbox']),
  select,
  textarea {
    display: block;
    width: 100%;
    border: 1px solid rgba(103, 105, 111, 0.5);
    background-color: $white;
    border-radius: 2px;
    color: $color-darkgray;
    outline: none;
    outline-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    &:focus {
      border-color: $color-darkgray;
    }
  }
  input:not([type='checkbox']) {
    height: 4rem;
    line-height: 4rem;
    padding: 0 1.25rem;
    transition: $input-transition;
  }
  select {
    background-image: url('../../../assets/images/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1.125rem auto, 100%;
    max-width: 100%;
    height: 4rem;
    line-height: 1;
    padding: 0 2.25rem 0 1.5rem;
  }
  textarea {
    min-height: 12.5rem;
    height: calc(100% - 1rem);
    transition: $input-transition;
    padding: 1rem 1.25rem 0;
  }
  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='checkbox'] {
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    &:before {
      position: relative;
      content: '';
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      background: $white;
      border: 1px solid $color-lightgray-100;
      border-radius: 2px;
    }
    &:after {
      position: absolute;
      content: '';
      display: block;
      left: 0;
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:checked {
      &:before {
        background-color: $mpg-color-blue-100;
      }
      &:after {
        left: 4px;
        top: 6px;
        height: 6px;
        width: 12px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
      }
    }
    &:focus {
      @include outlineFocus();
    }
  }
  input[type='submit'],
  button {
    width: auto;
    display: inline-block;
    align-items: center;
    text-align: center;
    border: 2px solid $ui-primary-button-color;
    background-color: $ui-primary-button-color;
    color: $white !important;
    font-family: $font-heading;
    font-size: 1.188rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    border-radius: 2rem;
    line-height: 1.2;
    padding: 1.125rem 2.125rem 0.875rem;
    text-transform: uppercase;
    transition: $btn-transition;
    @include mobile {
      padding: 1.125rem 1.125rem 0.875rem;
    }
    &:hover {
      border-color: $ui-primary-button-color-hover;
      background-color: $ui-primary-button-color-hover;
      opacity: 1;
      .cta-icon {
        transform: translateX(5px);
      }
    }
  }
  ul,
  li,
  label {
    display: flex;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      align-items: center;
      justify-content: flex-start;
      label {
        align-items: flex-start;
        margin-bottom: 0px;
      }
    }
  }
  a,
  p {
    position: relative;
    color: $color-lightgray-100;
    letter-spacing: unset;
    font-size: 0.8125rem;
  }
  a {
    text-transform: none;
    font-weight: 400;
    text-decoration: underline;
    &:hover {
      color: $color-lightgray-75;
      text-decoration: underline !important;
    }
  }
  p {
    text-transform: unset;
    font-weight: 400;
    transition: $input-transition;
    line-height: 1;
  }
  em {
    font-size: 0.8125rem;
  }
}

.hubspotForm {
  &-content {
    .hubspotformTitle {
      font-size: 1rem;
    }
    .hubspot-render {
      .hs-richtext {
        p {
          margin: 0;
          color: $color-lightgray;
          font-size: 1rem;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .hs-form-field {
        margin-bottom: 1.5rem !important;
      }
      .hs_submit.hs-submit {
        text-align: center;
      }
      .hs-input.invalid.error {
        box-shadow: inset 0px 0px 0px 1px $mpg-color-red-100;
        border-color: $mpg-color-red-100;
      }
      .hs-error-msgs {
        padding: 1rem;
        margin: 0;
        text-align: center;
        list-style: none;
        font-family: $font-copy;
        font-size: 0.75rem;
        line-height: 1;
        color: $color-white-shade;
        background-color: $mpg-color-red-100;
      }
      .hs-input::placeholder {
        color: $color-darkgray;
      }
      .hs-form-booleancheckbox-display {
        p {
          color: $color-darkgray;
        }
      }
      .hs_error_rollup {
        .hs-error-msgs {
          background: transparent;
          color: $mpg-color-red-100;
        }
      }
    }
  }
}
.newsletter-footer {
  &.container {
    padding-top: 1.5rem !important;
  }
  .hubspotForm {
    .hubspotformTitle {
      color: $color-blue;
      margin-bottom: 1.5rem;
      line-height: 24px;
      letter-spacing: 1.28px;
    }
    .hubspot-render {
      .hs-richtext {
        margin-bottom: 1.5rem;
      }
      .hs_submit.hs-submit {
        text-align: left;
        input[type='submit'] {
          color: $mpg-color-orange-75 !important;
          background-color: $white;
          border: 0;
          padding: 0;
          height: auto;
          &:hover {
            color: $mpg-color-orange-50 !important;
            opacity: 1;
          }
        }
      }
      .hs-form-booleancheckbox {
        a,
        p,
        em {
          color: $color-lightgray;
          font-size: 13px;
          line-height: 18px;
        }
      }
      // .hs-input.invalid.error{
      //   box-shadow: unset;
      //   border-color: unset;
      // }
      // .hs-error-msgs {
      //   display: none;
      // }
    }
    .legal-consent-container .hs-form-booleancheckbox-display > span {
      display: block;
      margin-left: 20px;
      line-height: 1;
    }

    .legal-consent-container .hs-error-msgs .hs-error-msg {
      color: #ffff;
    }
  }
}
.gated-content,
.contact-us {
  .hubspotForm {
    padding: 4rem 0;
    .hubspotformTitle {
      display: none;
    }
    .hubspot-render {
      .hs-richtext {
        margin-bottom: 1.5rem;
        text-align: center;
        p span,
        h2 span {
          font-family: $font-heading !important;
          font-size: 1.5rem !important;
          line-height: 1.25 !important;
          font-weight: 600 !important;
          color: $color-darkgray !important;
          background-color: transparent !important;
        }
      }
    }
  }
}
.contact-us {
  .hubspotForm {
    padding: 2.5rem 0 4rem;
    .hubspot-render {
      input:not([type='checkbox']),
      select,
      textarea {
        &.hs-input {
          width: 100% !important;
        }
      }
      input[type='submit'],
      button {
        height: auto;
      }
      .hs-error-msgs {
        margin-right: 0.5rem;
      }
      .hs-richtext {
        margin-bottom: 2.5rem;
      }
    }
  }
}
.form-container--contact-us {
  background-color: $mpg-color-gray-25;
}

.gated-hubspot-form {
  padding: 4rem 0;
  @include tablet {
    padding: 3.5rem 0;
  }
  .form-container--gated-content .gated-content .hubspotForm {
    padding: 0;
    color: inherit !important;
    .hubspot-render {
      form {
        .hs-richtext h2 span {
          color: inherit !important;
        }
        a,
        p,
        em {
          font-size: 0.8125rem;
          color: inherit;
        }
      }
      .hs_submit.hs-submit {
        text-align: left;
      }
    }
  }
  &.gated-hubspot-form--full {
    .hs_submit.hs-submit {
      text-align: center !important;
    }
  }
  .featured-image {
    display: flex;
    align-items: center;
    justify-content: center;

    @include tablet {
      justify-content: flex-start;
      margin-top: 1.5rem;
      display: none;
    }

    img {
      max-width: 100%;
      border-radius: 0.875rem;
    }
    &.mobile {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
}
