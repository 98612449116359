@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';
@import '../../CardBlocks/cardblock.scss';

// Manpower Theme Card Block Components Overrides
.blog.card-block {
  .search-global-pagination {
    .search-pagination-wrap {
      display: flex;
      justify-content: center;
      padding: 1.25rem 0;
      @include mobile {
        flex-direction: column;
        align-items: center;
      }
      .page-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        @include mobile {
          margin-bottom: 1.25rem;
        }
        .page-item {
          margin: 0 1.25rem;
          font-family: $font-helvetica-bold;
          font-weight: 400;
          font-size: 1.125rem;
          @include mobile {
            margin: 0 .438rem;
          }
          &:first-child {
            margin-left: 0;
            span {
              font-size: 1.125rem;
              font-weight: 600;
              margin-right: 0.313rem;
            }
          }
          .page-link {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: inline-flex;
            padding: inherit;
            border: none;
            color: $color-mangor-orange;
            font-family: $font-helvetica-bold;
            font-weight: 400;
            font-size: 1.125rem;
            border-bottom: 1px solid $color-orange;
            background-color: unset;
            line-height: 1;
            letter-spacing: 1.2px;
          }
          .page-link:hover{
            color: $color-orange;
          }
          .more {
            border-bottom: 0;
            color: $color-mangor-orange !important;
            transition: $link-transition;
            span {
              font-family: $font-helvetica-bold;
              font-size: 1.125rem;
              font-weight: 400;
            }
            :hover {           
              color: $color-orange;
            }
          }
         
          &.prev,
          &.next {
            &-disabled {
              cursor: not-allowed;
              .disabledCursor {
                color: $color-mangor-orange;               
                pointer-events: none;
                span {
                  font-family: $font-helvetica-bold;
                  font-size: 1.125rem;
                  font-weight: 400;
                }               
              }
              &:hover {
                color: $color-orange;
              }
            }
          }        
        }
        .page-item.active .page-link {
          border-bottom: none;
          color:  $color-darkgray;
          background: none;
        }
      }
    }
  }
}
