@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.featurette-section {
  position: relative;
  .featurette-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 75%;
    background-repeat: no-repeat;
  }
  &.standard {
    .featurette-imgs {
      @include mobile {
        margin-bottom: 1.25rem;
      }
    }
    .featurette-body {
      .title {
        line-height: 1.25;
      }
      p.large {
        line-height: 22px;
      }
    }
  }
  &.profile {
    .featurette-body {
      .title {
        font-family: $font-heading;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
      }
      .name {
        margin: 18px 0 12px;
      }
      .job-title {
        margin-block: 0.75rem 1.125rem;
      }
      p {
        margin-block: 1.125rem;
      }
    }
  }

  padding: 4rem 0;
  background-color: $color-smoky-gray;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  color: $color-darkgray;
  @include mobile {
    padding: 3.5rem 0;
  }
  .row {
    display: flex;
    align-items: center;
  }

  .featurette-imgs {
    @include tablet {
      margin: 0 auto 2rem;
    }
    @include mobile {
      margin-bottom: 2.5rem;
      .standard {
        margin-bottom: 1.25rem;
      }
    }
    .blurred {
      display: none !important;
    }
    img {
      position: relative;
      display: block;
      width: 100%;
      z-index: 2;
      height: auto;
      border: 0;
      border-radius: 0.875rem;
      object-fit: cover;
      aspect-ratio: 1;
    }

    &.standard {
      &.right {
        &.gradient__primary {
          img {
            @include gradientBorderRight(0.75rem, $linear-gradient-green-blue);
            border-radius: 0.875rem 0 0 0.875rem;
            @include mobile {
              @include gradientBorderBottom(0.75rem, $linear-gradient-green-blue);
              border-radius: 0.875rem 0.875rem 0 0;
            }
          }
        }
        &.gradient__secondary {
          img {
            @include gradientBorderRight(0.75rem, $linear-gradient-blue-violet);
            border-radius: 0.875rem 0 0 0.875rem;
            @include mobile {
              @include gradientBorderBottom(0.75rem, $linear-gradient-blue-violet);
              border-radius: 0.875rem 0.875rem 0 0;
            }
          }
        }
      }
      &.left {
        &.gradient__primary {
          img {
            @include gradientBorderLeft(0.75rem, $linear-gradient-green-blue);
            border-radius: 0 0.875rem 0.875rem 0;
            @include mobile {
              @include gradientBorderBottom(0.75rem, $linear-gradient-green-blue);
              border-radius: 0.875rem 0.875rem 0 0;
            }
          }
        }
        &.gradient__secondary {
          img {
            @include gradientBorderLeft(0.75rem, $linear-gradient-blue-violet);
            border-radius: 0 0.875rem 0.875rem 0;
            @include mobile {
              @include gradientBorderBottom(0.75rem, $linear-gradient-blue-violet);
              border-radius: 0.875rem 0.875rem 0 0;
            }
          }
        }
      }
    }
  }

  .featurette-body {
    padding-top: 0;
    padding-left: 3rem;
    // margin-top: -10px;
    .title {
      @include heading(5);
      font-size: 1.5rem;
      font-family: $font-heading;
      line-height: 1;
      font-weight: 600;
      text-transform: none;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: normal;
      @include tablet {
        margin: 0;
      }
    }
    .name {
      font-size: 1.5rem;
      font-family: $font-heading;
      font-weight: 600;
      line-height: 30px;
      color: inherit;
      margin: 1.5rem 0 1rem;
    }
    .job-title {
      line-height: 22px;
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
    }

    .sub-title {
      font-family: $font-heading;
      font-size: 1.125rem;
      line-height: 18px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.72px;
      margin: 0 0 1.125rem;
    }
    p {
      font-size: 1rem;
      margin: 1.125rem 0;
      color: inherit;
    }
    .cta-textlink {
      margin-top: 1.5rem;
      display: inline-block;
    }
    @include desktop {
      padding-left: 1.5rem;
    }
    @include tablet {
      padding-left: 2rem;
    }
    @include mobile {
      padding-left: 0;
    }
  }
  .quote {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    position: absolute;
    width: 6.25rem;
    height: 4.75rem;
    opacity: 0.1;
    background-image: url(../../../assets/images/icon-quote.svg);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &.right {
    .featurette-body {
      @include desktop {
        padding-right: 1.5rem;
      }
      @include tablet {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
