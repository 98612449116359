@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/variables';
@import '../../../styles/scss/base';

$user-icon: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzI4MkEzMiIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNLjAwNCAyMC4zMzRjLjA1My0uNDA0LjA5NC0uODEyLjE2NC0xLjIxMS41MzctMi45MzUgMi4wNDctNS4yMyA0LjQ0Mi02Ljk1NyAxLjY1NyAxLjU5NSAzLjYxIDIuNDM2IDUuODkgMi40MzEgMi4yNzIgMCA0LjIyLS44MzIgNS44OTQtMi40NC4zMzIuMjY3LjY4LjUyMSAxIC44MDkgMi4wMTkgMS44MTkgMy4yMDggNC4wODggMy41MzIgNi44MTQuMDIuMTgzLjA1LjM3LjA3NC41NTRWMjFIMGMuMDA0LS4yMi4wMDQtLjQ0NS4wMDQtLjY2NnoiLz48cGF0aCBkPSJNMTAuOTczIDBjLjQuMDguODA3LjEzIDEuMTk1LjI0MSAyLjM3Ni42NjEgNC4xMzQgMi43NjIgNC4zOCA1LjIxOGE2LjA4MiA2LjA4MiAwIDAgMS01LjU4NyA2LjY3OGMtMy4wODUuMjU4LTUuOTI0LTEuOTM4LTYuNDQ3LTQuOTg4QzMuOTUyIDMuODg5IDUuOTkxLjg1IDkuMjI2LjEzNWMuMjYyLS4wNi41My0uMDkxLjc5Ni0uMTM1aC45NXoiLz48L2c+PC9zdmc+);
$user-icon-active: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0yMi40MjQgMThhLjU5Ny41OTcgMCAwIDAtLjQwNy4xNjJsLTMuMzM5IDMuNDQ1LTEuNzEyLTEuNzdhLjU4NC41ODQgMCAwIDAtLjM5OC0uMTYyLjU3OC41NzggMCAwIDAtLjU2OC41ODFjMCAuMTU0LjA2LjMuMTYxLjQwMmwyLjExOSAyLjE4YS41ODQuNTg0IDAgMCAwIC4zOTguMTYyYy4xNTMgMCAuMjk3LS4wNi40MDctLjE2MmwzLjczNy0zLjg0N0EuNjc3LjY3NyAwIDAgMCAyMyAxOC41OWEuNTg3LjU4NyAwIDAgMC0uNTc2LS41OXoiIGlkPSJhIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTS4wMDQgMjAuMzM0Yy4wNTMtLjQwNC4wOTQtLjgxMi4xNjQtMS4yMTEuNTM3LTIuOTM1IDIuMDQ3LTUuMjMgNC40NDItNi45NTcgMS42NTcgMS41OTUgMy42MSAyLjQzNiA1Ljg5IDIuNDMxIDIuMjcyIDAgNC4yMi0uODMyIDUuODk0LTIuNDQuMzMyLjI2Ny42OC41MjEgMSAuODA5IDIuMDE5IDEuODE5IDMuMjA4IDQuMDg4IDMuNTMyIDYuODE0LjAyLjE4My4wNS4zNy4wNzQuNTU0VjIxSDBjLjAwNC0uMjIuMDA0LS40NDUuMDA0LS42NjZ6IiBmaWxsPSIjMjgyQTMyIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNMTAuOTczIDBjLjQuMDguODA3LjEzIDEuMTk1LjI0MSAyLjM3Ni42NjEgNC4xMzQgMi43NjIgNC4zOCA1LjIxOGE2LjA4MiA2LjA4MiAwIDAgMS01LjU4NyA2LjY3OGMtMy4wODUuMjU4LTUuOTI0LTEuOTM4LTYuNDQ3LTQuOTg4QzMuOTUyIDMuODg5IDUuOTkxLjg1IDkuMjI2LjEzNWMuMjYyLS4wNi41My0uMDkxLjc5Ni0uMTM1aC45NXoiIGZpbGw9IiMyODJBMzIiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxjaXJjbGUgZmlsbD0iIzFEQkMxQiIgY3g9IjE5LjUiIGN5PSIyMC41IiByPSI2LjUiLz48dXNlIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTIiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48L2c+PC9zdmc+);

$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

body {
  &.menu-open {
    overflow: hidden !important;
    .site-header {
      height: 100vh;
      &.sticky {
        top: 0;
        transition: none;
      }
    }
  }
}

.storybook-readme-story > div {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.bg-blackout {
  position: fixed;
  background-color: $color-rainbow-indigo;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  &.active {
    opacity: 0.85;
    z-index: 99;
  }
}

.site-header {
  background-color: $white;
  box-shadow: 0 2px 0.875rem 0 rgba($black, 0.06);
  z-index: 100;
  position: fixed;
  width: 100%;
  top: -120px;
  left: 0;
  transition: 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  &.sticky,
  &.active {
    top: 0;
    &.active {
      top: 0;
      height: 100%;
      transition: none;
      .main-nav-slideout {
        overflow-y: scroll;
      }
    }
  }
  &.not-fixed {
    position: relative;
  }
  .newsletter .privacy-notice .cta a {
    color: $white !important;
  }
  .skip-to-main-content {
    height: 1.875rem;
    left: 45%;
    padding: 0.5rem;
    position: absolute;
    transform: translateY(-200%);
    transition: transform 0.3s;
    @include mobile {
      left: 30%;
    }
    @include mobile-small {
      left: 25%;
    }
  }
  .skip-to-main-content:focus {
    transform: translateY(0%);
    @include outlineFocus();
    @include mobile {
      left: 30%;
      top: -0.8rem;
    }
    @include mobile-small {
      left: 25%;
    }
  }
  .site-header-bar {
    height: $header-bar-height;
    align-items: center;
    // padding: 0 1.875rem;
    padding: 0;
    margin: 0;
    @include tablet {
      height: 65px;
    }
    a {
      color: $mpg-color-gray-100;
    }
    .site-logo {
      // width: 105px; //commented for theming
      height: auto;
      @extend .site-logo;
    }
    .main-nav__list {
      float: right;
      .main-nav__item {
        display: inline-block;
        margin-left: 2.375rem;
        top: 0.5rem;
        transition: 0.25s ease-in-out;
        cursor: pointer;
        position: relative;
        @include tablet {
          display: none;
        }
        &:hover,
        &.link-focused,
        &:focus {
          a {
            text-decoration: none;
            span {
              &:after {
                width: 100%;
              }
              .icon-search {
                background-image: url('../../../assets/images/icon-magnifying_glass-nav-orange.svg');
              }
            }
          }
          .main-nav-dropdown {
            display: block;
            transform: translateX(-50%) scale(1, 1);
            opacity: 1;
            &.login-dropdown {
              @include desktop-medium {
                transform: translateX(-75%) scale(1, 1) !important;
              }
            }
            @include mobile {
              display: none;
            }
          }
        }
        &.login {
          display: inline-block;
          @include tablet {
            span {
              &:after {
                bottom: 1.25rem;
              }
            }
          }
          @media (max-width: 286px) {
            margin-left: 4px;
          }
          .main-nav-dropdown {
            top: 90px;
            width: 400px;
            left: 35%;
            @include mobile {
              width: 280px !important;
            }

            @media (max-width: 286px) {
              width: 230px !important;
            }
            @include tablet {
              top: 50px !important;
            }
          }
        }
        &.logged-in {
          .main-nav-dropdown {
            z-index: 1;
            .main-nav-login-block {
              display: none;
            }
            .main-nav-user-block {
              display: block;
            }
          }
          .user-icon {
            background-image: $user-icon-active;
            width: 1.625rem;
          }
        }
        &.hamburger {
          &:after {
            display: none;
          }
        }
        .icon-search {
          display: inline-block;
          position: relative;
          transform: translateY(1px);
          width: 15px;
          height: 15px;
          background-repeat: no-repeat;
          background-image: url('../../../assets/images/icon-searchjobs-mag_glass.svg');
          transition: 0.25s ease-in-out;
        }
        .user-icon {
          display: inline-block;
          position: relative;
          transform: translateY(10px);
          width: 1.375rem;
          height: 1.875rem;
          background-image: $user-icon;
          background-repeat: no-repeat;
          background-position: top left;
        }
        a {
          position: relative;
          font-size: 1.125rem;
          line-height: 96px;
          // text-transform: capitalize;
          color: $black;
          display: inline-block;
          font-size: 1.125rem;
          // text-transform: capitalize;
          font-weight: 600;
          font-family: $font-heading;
          @include tablet {
            line-height: 65px;
          }
          span {
            display: inline-block;
            position: relative;
            font-family: $font-heading;
            font-size: 1.125rem;
            &:after {
              display: block;
              position: absolute;
              content: '';
              background-color: $color-orange;
              bottom: 2.25rem;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 2px;
              transition: 0.25s ease-in-out;
            }
          }
          &:hover,
          &.header-link-active {
            opacity: 1;
            color: $color-orange;
            span {
              &:after {
                width: 100%;
              }
            }
          }
        }
        .main-nav-dropdown {
          position: absolute;
          left: 50%;
          top: 90px;
          width: 400px;
          user-select: none;
          padding-top: 1.25rem;
          cursor: default;
          opacity: 0;
          transform: translateX(-50%) scale(1, 0);
          transform-origin: bottom right;
          @include tablet {
            display: none;
          }
          @media (max-width: 1024px) {
            display: none;
          }
          .sub-nav__content {
            background-color: $white;
            padding: 2rem 1.75rem;
            border: 1px solid $color-dark-border;
            box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
          }
          &.login-dropdown {
            @include desktop-medium {
              left: 0;
              transform: translateX(-75%) scale(1, 0) !important;
            }
          }
          &.link-active {
            @include tablet {
              display: block;
            }
            @media (max-width: 1024px) {
              display: block;
            }
          }
          &.link-hidden {
            opacity: 0 !important;
            display: none !important;
          }
          &.sub-nav {
            min-width: 207px;
            width: auto;
            white-space: nowrap;
          }
          .sub-nav__arrow {
            position: absolute;
            width: 1.25rem;
            height: 1.25rem;
            top: 0.625rem;
            left: calc(50% - 10px);
            transform: translateX(-50%);
            background-color: $white;
            transform: rotate(45deg);
            transition: 0.25s ease-in-out;
            border-top: 1px solid $color-dark-border;
            border-left: 1px solid $color-dark-border;
            &.login-triangle {
              @include desktop-medium {
                left: calc(78% - 10px) !important;
              }
            }
          }
          .title {
            @include heading(4);
            margin-bottom: 0.625rem;
          }
          // label {
          //   display: none;
          // }
          .label-link {
            text-decoration: underline;
            color: $color-tele-gray;
            cursor: pointer;
          }
          .verify-identity-option {
            .col-auto {
              flex: 1;
              button {
                width: 100%;
              }
            }
          }
          .verify-identity-label {
            padding: 1.25rem 0 1.875rem 0;
            color: $color-tele-gray;
          }
          .verify-identity-label-link {
            padding: 0;
          }
          .login-checkbox {
            .form-block {
              margin-bottom: 0;
              label {
                display: block;
              }
            }
          }
          .btns-container {
            align-items: center;
            margin-top: 1.75rem;
            .col {
              text-align: right;
            }
          }
          .main-nav-user-block {
            display: none;
            .welcome-user {
              font-size: 25px;
              font-family: $font-copy;
              color: $color-darkgray;
              margin-bottom: 1.75rem;
              overflow-wrap: break-word;
              width: 70%;
            }
            .main-nav-user-links {
              a {
                width: max-content;
                display: block;
                line-height: 1;
                margin: 1.25rem 0;
                color: $color-blue !important;
                &:last-child {
                  margin-bottom: 0;
                }
                &:hover {
                  color: $color-orange !important;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      .hamburger {
        display: inline-block;
        line-height: 96px;
        padding: 0px 0.625rem;
        @include media-breakpoint-down(lg) {
          padding: 0px 7px;
        }
        cursor: pointer;
        @include tablet {
          line-height: 65px;
          padding-top: 4px;
        }
      }
      .hamburger-menu-toggle {
        width: 1.875rem;
        height: 1.375rem;
        display: inline-block;
        &:focus-visible {
          @include outlineFocus();
          outline-offset: 2px;
        }
        &:hover {
          .line1 {
            width: 100%;
          }
          .line2 {
            width: 100%;
            transition-delay: 0;
            @include tablet {
              width: 85%;
            }
          }
          .line3 {
            width: 100%;
            transition-delay: 0.05s;
            @include tablet {
              width: 70%;
            }
          }
        }
        &.active {
          .line1 {
            transform: rotate(45deg) translateY(11px);
            margin-bottom: 3px;
          }
          .line2 {
            opacity: 0;
            width: 0;
          }
          .line3 {
            transform: rotate(-45deg) translateY(-11px);
            width: 100%;
          }
        }
        .line1 {
          position: relative;
          background-color: $black;
          width: 100%;
          height: 4px;
          border-radius: 2px;
          margin-bottom: 5px;
          transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
          @include tablet {
            transition: none;
          }
        }
        .line2 {
          position: relative;
          background-color: $black;
          width: 85%;
          height: 4px;
          border-radius: 2px;
          margin-bottom: 5px;
          transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-delay: 0.05s;
          @include tablet {
            transition: none;
          }
        }
        .line3 {
          position: relative;
          background-color: $black;
          width: 70%;
          height: 4px;
          border-radius: 2px;
          transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
          @include tablet {
            transition: none;
          }
        }
      }
    }
  }
  .main-nav-slideout {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    @extend .themeBgColor;
    display: none;
    .search-tab-temp {
      margin-top: 42px;
    }
    @include tablet {
      .not-active {
        display: none;
      }
    }
    .container {
      margin-bottom: 96px;
    }
    @include tablet {
      top: 0;
    }
    &.active {
      height: 100vh;
      overflow-y: scroll;
      display: block;
      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 100%;
        width: 100%;
        height: 100%;
        background-color: $color-blue;
      }
    }
    .mobile-nav-header {
      display: none;
      padding-top: 1.25rem;
      margin-bottom: 2.5rem;
      @include tablet {
        display: flex;
      }
      .mobile-site-logo {
        height: 31px;
        // width: 100px;
        @extend .mob-site-logo;
        img {
          width: 100%;
          height: auto;
        }
      }
      .close-text {
        margin-top: 0.625rem;
        text-align: right;
        color: $white;
        // text-transform: uppercase;
        font-family: $font-heading;
        font-size: 9px;
        opacity: 0.65;
      }
    }
    .slideout-nav-content {
      padding-right: 50px;
      @include desktop {
        padding-right: 25px;
      }
      @include tablet {
        padding-right: 15px;
        &.solutions,
        &.industries,
        &.careers,
        &.about-us {
          .search-box {
            display: none;
          }
          & + .slideout-nav-sidebar {
            display: none;
          }
        }
        &.solutions {
          .slideout-nav-block {
            &.industries-section,
            &.careers-section,
            &.about-us-section {
              display: none;
            }
          }
        }
        &.industries {
          .slideout-nav-block {
            &.solutions-section,
            &.careers-section,
            &.about-us-section {
              display: none;
            }
          }
        }
        &.careers {
          .slideout-nav-block {
            &.solutions-section,
            &.industries-section,
            &.about-us-section {
              display: none;
            }
          }
        }
        &.about-us {
          .slideout-nav-block {
            &.solutions-section,
            &.industries-section,
            &.careers-section {
              display: none;
            }
          }
        }
      }
    }
    .newsletter {
      .title {
        padding-bottom: 0;
      }
    }
    .title {
      @include heading(5);
      text-transform: uppercase;
      color: $white;
      font-weight: 600;
      font-size: 1.25rem;
      font-family: $font-heading !important;
      @include tablet {
        border-bottom: 1px solid rgba($white, 0.13);
        font-size: 17px;
        line-height: 1.25rem;
        background-image: url('../../../assets/images/chevron-right.svg');
        background-position: right top -3px;
        background-size: 1.375rem 1.375rem;
        background-repeat: no-repeat;
        &.no-arrow {
          background: none;
        }
      }
    }
    .search-box {
      @include tablet {
        border-bottom: 1px solid rgba($white, 0.13);
        margin-bottom: 1.25rem;
        padding-bottom: 1.25rem;
      }
      .form-block {
        label {
          display: none;
        }
        margin-bottom: 0.625rem;
      }
      &.all {
        @include tablet {
          .all-search-tab {
            display: block;
          }
          .job-search-tab {
            display: none;
          }
          .search-toggle-text {
            &:first-child {
              text-decoration: none;
            }
            text-decoration: underline;
          }
        }
      }
      .search-toggle-text {
        display: none;
        color: $white;
        // text-transform: uppercase;
        opacity: 0.65;
        font-family: $font-heading;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-bottom: 15px;
        &:first-child {
          margin-right: 1.875rem;
          text-decoration: underline;
        }
        @include tablet {
          display: inline-block;
        }
      }
      .job-search-tab {
        display: none;
        @include tablet {
          display: block;
        }
      }
      .all-search-tab {
        margin: 42px 0;
        @include tablet {
          display: none;
          margin: 0;
        }
      }
    }
    .slideout-nav-block {
      &.main-slide-nav {
        margin-bottom: 1.75rem;
        @include tablet {
          margin-bottom: 0;
        }
      }
      .title-text {
        cursor: pointer;
        transition: $link-transition;
        &:hover {
          opacity: 0.7;
        }
      }
      .back-text {
        display: none;
        text-transform: none;
      }
      &.active {
        .title {
          @include tablet {
            background-image: url('../../../assets/images/chevron-left.svg');
            background-position: left top -3px;
            padding-left: 1.875rem;
            .title-text {
              display: none;
            }
            .back-text {
              position: relative;
              top: -3px;
              display: block;
            }
          }
        }
        nav {
          @include tablet {
            display: block;
          }
        }
        a {
          &.nav-title {
            @include tablet {
              display: block;
            }
          }
        }
      }
      .slideout-nav-block {
        nav {
          @include tablet {
            border: 0;
          }
        }
      }
      nav {
        margin-bottom: 1.75rem;
        &.no-padding {
          margin-bottom: 0;
        }
        &.last-sub-tile {
          margin-bottom: 1.375rem;
        }
        @include tablet {
          display: none;
          margin-bottom: 1.25rem;
        }
        a {
          display: block;
          color: $white !important;
          font-size: 15px;
          line-height: 27px;
          font-weight: 400;
          font-family: $font-copy;
          // text-transform: capitalize;
          letter-spacing: 0;
          margin-bottom: 5px;
          cursor: pointer;
          transition: $link-transition;
          &:hover {
            opacity: 0.5;
          }
          @include tablet {
            font-size: 0.875rem;
            line-height: 35px;
          }
          &.sub-title {
            font-weight: 600;
            font-size: 1.125rem;
            @extend .headerSubtitleColor;
            margin-bottom: 15px;
            @include tablet {
              margin-bottom: 5px;
              font-size: 1rem;
            }
          }
        }
      }
      a {
        &.nav-title {
          display: none;
          @include heading(5);
          text-transform: uppercase;
          color: $white;
          font-weight: 600;
          margin-bottom: 15px;
          padding-top: 0.625rem;
          @include tablet {
            font-size: 17px;
            line-height: 1.25rem;
          }
        }
      }
    }
    .slideout-nav-sidebar {
      @extend .headerSidebarBgColor;
      padding: 50px 50px 200px;
      @include desktop {
        padding: 50px 25px 150px;
      }
      @include tablet {
        background-color: $color-blue;
        padding: 0 15px 70px;
      }
      .title {
        @include tablet {
          border-bottom: 0;
          background-image: none;
        }
      }
    }
    .slideout-nav-newsletter {
      @include tablet {
        .title {
          margin-bottom: 0;
        }
        p {
          margin-top: 0;
        }
      }
    }
    .slideout-nav-insights {
      @extend .headerSidebarBottomline;
      padding-top: 15px;
      padding-bottom: 1.875rem;
      margin-bottom: 2.5rem;
      @include tablet {
        padding-top: 0;
      }
      .title {
        margin-bottom: 2.5rem;
        @include tablet {
          margin-bottom: 0;
        }
      }
      img {
        margin-bottom: 0.625rem;
        width: 100%;
      }
      p {
        color: $white;
        margin-top: 0;
        @include tablet {
          margin-top: 0;
        }
      }
      a {
        color: $white !important;
        // text-transform: capitalize;
        font-weight: 400;
        font-size: 1.125rem;
      }
    }
    .slideout-nav-login {
      display: none;
      @include tablet {
        display: block;
      }
      margin-top: 1.875rem;
      a {
        color: $color-grayblue !important;
        // text-transform: capitalize;
      }
      .user-icon {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin-right: 0.625rem;
        background-image: url('../../../assets/images/user-icon-nav.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .slideout-nav-country {
      padding-top: 2.5rem;
      @include tablet {
        padding-top: 1.875rem;
      }
      /**span {
        color: $color-grayblue;
        padding-right: 0.625rem;
        font-family: $font-heading;
        font-weight: 600;
        font-size: 1rem;
      }
      a {
        color: $white !important;
        text-transform: capitalize;
        font-weight: 400;
        text-decoration: underline !important;
      }*/
    }
    .slideout-nav-language {
      padding-top: 1.875rem;
    }
  }
  .search-header {
    @extend .themeBgColor;
    .container {
      padding: 0;
      margin-bottom: 42px;
    }
    .search-everything {
      padding-bottom: 0;
    }
  }
  .search-pad-lg {
    @include devices(
      map-get($grid-breakpoints, lg),
      map-get($grid-breakpoints, xl)
    ) {
      padding-left: 0;
    }
  }
  .searchbtn-pad-lg {
    @include media-breakpoint-up(xl) {
      padding-left: 13px;
      padding-right: 13px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 15px;
    }
    @include devices(
      map-get($grid-breakpoints, lg),
      map-get($grid-breakpoints, xl)
    ) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .slideout-nav-sidebar {
    .apsis-form {
      &.newsletter {
        .text {
          color: $white;
        }
        color: $white;
        padding-top: 5px;
        form {
          &.al-form {
            .al-form-reset-span,
            * {
              color: $white;
            }
            .al-errorValidationText {
              color: $color-maroon;
              font-size: 0.75rem;
              height: 1.25rem;
              margin-left: 0;
              margin-top: 0;
              padding-left: 15px;
              padding-top: 4px;
              background-color: $white-light;
            }
            input[type='button'] {
              color: $white;
            }
            input[type='checkbox'] + label {
              &:after {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.login-submenu {
  list-style: none;
  padding: 0px;
}
