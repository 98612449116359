@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.vidyard-content.fancybox-content {
  width: 80%;

  @include mobile {
    width: 100%;
  }

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  background: transparent;
  .fancybox-button svg {
    color: $color-bone;
  }
}
