$theme-list: 'experis', 'jw', 'manpower';

.themeBgColor {
  &.experis {
    @include setThemeBgColor('experis');
  }
  &.jw {
    @include setThemeBgColor('jw');
  }
  &.manpower {
    @include setThemeBgColor('manpower');
  }
}

.themeFontColor {
  &.experis {
    @include setThemeFontColor('experis');
  }
  &.jw {
    @include setThemeFontColor('jw');
  }
  &.manpower {
    @include setThemeFontColor('manpower');
  }
}

.headerSubtitleColor {
  &.experis {
    @include setThemeSubtitleColor('experis');
  }
  &.jw {
    @include setThemeSubtitleColor('jw');
  }
}

.headerSidebarBgColor {
  &.experis {
    @include setThemeHeaderSidebarBgColor('experis');
  }
  &.jw {
    @include setThemeHeaderSidebarBgColor('jw');
  }
}

.headerSidebarBottomline {
  &.experis {
    @include setHeaderSidebarBottomlineColor('experis');
  }
  &.jw {
    @include setHeaderSidebarBottomlineColor('jw');
  }
}

.headerCountryLinkColor {
  &.experis {
    @include setHeaderCountryLinkColor('experis');
  }
  &.jw {
    @include setHeaderCountryLinkColor('jw');
  }
}
.articleBlockHeadingColor {
  &.experis {
    @include setThemeFontColor('experis');
  }
  &.jw {
    @include setThemeFontColor('jw');
  }
  &.manpower {
    @include setThemeFontColor('manpower');
  }
}
.localBranchbgColor {
  &.experis {
    @include setLocalBranchThemeBgColor('experis');
  }
  &.jw {
    @include setLocalBranchThemeBgColor('jw');
  }
  &.manpower {
    @include setLocalBranchThemeBgColor('manpower');
  }
}

.__main-background-theme {
  &.experis {
    @include bg-theme-color('experis');
  }
  &.jw {
    @include bg-theme-color('jw');
  }
  &.manpower {
    @include bg-theme-color('manpower');
  }
}

.__table-header-theme {
  &.experis {
    @include table-header-theme('experis');
  }
  &.jw {
    @include table-header-theme('jw');
  }
  &.manpower {
    @include table-header-theme('manpower');
  }
}

.__navigation-hover {
  &.experis {
    a:hover,
    a.active {
      @include navigation-hover('experis');
    }
  }
  &.jw {
    a:hover,
    a.active {
      @include navigation-hover('jw');
    }
  }
  &.manpower {
    a:hover,
    a.active {
      @include navigation-hover('manpower');
    }
  }
}
.__dashboard-color {
  &.experis {
    @include setDashboardBackground('experis');
  }
  &.jw {
    @include setDashboardBackground('jw');
  }
  &.manpower {
    @include setDashboardBackground('manpower');
  }
}

.__tip-bg-color {
  &.experis {
    @include tip-bg-color('experis');
  }
  &.jw {
    @include tip-bg-color('jw');
  }
}

.__progress-bar-theme {
  &.experis {
    @include progressbar-bg-color('experis');
  }
  &.jw {
    @include progressbar-bg-color('jw');
  }
  &.manpower {
    @include progressbar-bg-color('manpower');
  }
}
.errorScreenbgColor {
  &.experis {
    $gradient: #30588f, #1c3e6b;
    @include gradient(180deg, $gradient);
  }
  &.jw {
    $gradient: #5c7d70, #355c4c;
    @include gradient(180deg, $gradient);
  }
}
.site-logo {
  &.experis {
    @include logoWidth('experis');
  }
  &.jw {
    @include logoWidth('jw');
  }
  &.manpower {
    @include logoWidth('manpower');
  }
}


.homcareerBlock {
  &.experis {
    @include home-career-block('experis');
  }
  &.jw {
    @include home-career-block('jw');
  }
}
.mob-site-logo {
  &.experis {
    @include moblogoWidth('experis');
  }
  &.jw {
    @include moblogoWidth('jw');
  }
}
.homestatblock-bgimage {
  &.experis {
    @include home-stat-block-bgimage('experis');
  }
  &.jw {
    @include home-stat-block-bgimage('jw');
  }
}
.__border-top-color {
  &.manpower {
    @include set-border-top('manpower');
  }
}
