@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.insights-list-container {
  margin: 40px 0px;
    .content-wrapper {
      padding: 0px;
    }
    @include mobile {
      padding: 10px 0px;
      margin:0px;
      background: $pale-grey;
      .container-xl {
        padding: 0px;
      }
      .content-wrapper {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  