@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';

.content-insights-block {
  position: relative;

  .container-xl {
    padding: 0px;
    @media (max-width: 575px) {
      padding: 0px 15px;
    }
  }

  .content-insights {
    font-family: $font-copy;
    text-align: left;
    color: $mpg-color-gray-100;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (max-width: 575px) {
      max-height: calc(1.2em * 15);
      -webkit-line-clamp: 15;
    }

    &.expanded {
      -webkit-line-clamp: none;
      line-clamp: none;
      max-height: none;
    }
  }

  .read-more-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 98px;
    background: linear-gradient(
      360deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.875) 56%,
      rgba(255, 255, 255, 0) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    z-index: 0;
    opacity: 0;

    /* Show the container only on mobile */
    @media (max-width: 575px) {
      opacity: 1;
      pointer-events: none;
    }

    .read-more-btn {
      display: none;

      @media (max-width: 575px) {
        display: inline-block; /* Show button on mobile */
        width: 115px;
        height: 38px;
        padding: 8px 16px;
        gap: 8px;
        border-radius: 58px;
        border: 1px solid #efefef;
        background: var(--MPG-color-gray-00, #ffffff);
        opacity: 0.9;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        z-index: 2;
        pointer-events: auto;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
