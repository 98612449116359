/* autoprefixer grid: autoplace */

@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.card-section {
  display: flex;
}

.card {
  display: block;
  box-shadow: 3px 8px 1rem 0 rgba(0, 0, 0, 0.06);
  background-color: $white;
  margin: 0 13px;
  height: 100%;
  @include mobile {
    margin: 0;
  }
  .card-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    // grid-template-rows: 1fr 0.625rem;
  }

  .imgName {
    width: 128px;
    height: 128px;
  }
  &.connector {
    box-shadow: 5px 8px 1rem 0 rgba(97, 97, 97, 0.1);
    display: flex;
    align-items: center;
    @include tablet {
      display: block;
    }
    .card-image {
      @include tablet {
        height: 300px;
        overflow: hidden;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 100%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .card-body {
      flex: 1;
      padding-inline: 56px;
      @include tablet {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
      }
      @include mobile {
        padding-left: 15px;
        padding-right: 15px;
      }
      .title {
        @include heading(2);
        margin-bottom: 1.5rem;
      }
      .cta {
        margin-left: -200px;
        @include tablet {
          margin-left: 0;
          .connector-button {
            .fat-line,
            .line {
              display: none;
            }
          }
        }
      }
    }
  }
  &.stat {
    color: inherit;
    box-shadow: none;
    text-align: center;
    margin: 0.625rem 13px;
    .stat-line {
      @include flexbox();
      @include align-items(baseline);
      @include justify-content(center);
      .stat,
      .stat-symbol {
        font-size: 100px;
        font-family: $font-heading;
        font-weight: 400;
        letter-spacing: -4px;
        line-height: 100px;
        @include tablet {
          font-size: 6.25rem;
          letter-spacing: -4px;
        }
      }
      .stat-symbol {
        font-size: 5.625rem;
      }
      .icon {
        height: 4.5rem;
        max-height: 4.5rem;
        width: auto;
        margin-left: 1rem;
        svg {
          vertical-align: unset;
          height: 4.5rem;
          width: auto;
          @include tablet {
            height: 4rem;
          }
        }
        @include tablet {
          max-height: 2.5rem;
          height: 2.5rem;
        }
      }
    }
    p {
      color: inherit;
      font-size: 1.25rem;
      line-height: 1.3;
      padding: 12px 10px;
    }
  }
  &.related-content {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba($color-light-body, 0.5);
    transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    .card-body {
      padding: 1.25rem 0;
    }
    .sub-title {
      @include heading(5);
      color: $color-blue !important;
    }
    .first-card {
      // padding-top: 0.625rem;
      // img {
      //   margin-top: 0;
      // }
    }
    .date {
      color: $color-sidebar-item;
      font-family: $font-heading;
      font-size: 1.25rem;
      line-height: 1;
      padding-top: 0.75rem;
      padding-bottom: 8px;
      font-weight: 400;
    }
    .text {
      color: $color-sidebar-item !important;
      letter-spacing: 0;
      font-family: $font-heading;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
      text-transform: none;
    }
  }
  &.light {
    padding: 1.25rem 0;
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    img {
      display: inline-block;
      max-width: 60px;
      height: auto;
    }
    .title {
      font-size: 1.5rem;
      line-break: 29px;
    }
    p {
      color: $color-light-body;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }
  &.dark {
    padding: 1.25rem 0;
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    .img-container {
      min-height: 60px;
    }
    .title {
      color: $white;
      font-size: 1.5rem;
      line-height: 29px;
      font-weight: 400;
    }
    p {
      // color: $color-darkgray;
      color: inherit;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0;
      // @include mobile {
      //   font-size: 15px;
      //   line-height: 1.25rem;
      // }
    }
    .arrow-icon {
      // @include arrow-Right(orange);
      position: relative;
      background-size: cover;
    }
    // .link-green .arrow-icon {
    // @include arrow-Right(green);
    // }
    .cta-textlink {
      margin-top: auto;
    }
    // a {
    //   white-space: nowrap;
    //   font-size: 1.125rem;
    //   margin-top: auto;
    //   // padding: 2rem 0 0;
    //   color: $color-darkgray !important;
    //   &:hover {
    //     color: $color-graphite-gray !important;
    //   }
    //   // .cta-text {
    //   //   font-size: 1.125rem;
    //   // }
    // }
    img {
      border-radius: 0.875rem;
      max-width: unset;
      width: 90%;
    }
    .logo {
      display: block;
      max-width: unset;
      width: 100%;
      margin: 1.25rem 0;
      @include tablet {
        margin: 1.25rem 0 1.5rem;
      }
    }
    .icon {
      width: 4rem;
      height: 4rem;
    }
    .heading {
      // @include heading(6);
      font-size: 1.25rem;
      line-height: 1.3;
      font-weight: 600;
      padding: 1.5rem 1.5rem 1rem 1.5rem;
      // line-height: 1.3;
    }
    .content {
      padding: 1.5rem 1.5rem 2rem 1.5rem;
    }
  }
  &.article {
    box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.08);
    text-transform: none;
    &.featured {
      box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.08);
      h5 {
        color: $color-orange;
      }
      .card-body {
        border-color: $color-orange;
        border-top: 4px solid $color-orange;
        padding: 48px;
        @include mobile {
          border-top: 3px solid $color-orange;
          padding: 2rem;
        }
      }
      .article-icon {
        &.arrow-icon {
          @include arrow-Right(orange);
          width: 46px;
          height: 27px;
          background-size: 46px 27px;
          position: absolute;
          right: 48px;
          top: auto !important;
          @include mobile {
            right: 2rem;
            width: 2.25rem;
            height: 21px;
            background-size: cover;
          }
        }
      }
      .title {
        letter-spacing: 0;
        @include mobile {
          font-size: 1.5rem;
          line-height: 1.875rem;
          padding-right: 0;
        }
      }
    }
    .card-body {
      border-top: 4px solid $color-blue;
      padding: 2.5rem;
    }
    &:hover {
      opacity: 0.75;
    }
    .article-icon {
      &.arrow-icon {
        @include arrow-Right(blue);
        width: 46px;
        height: 27px;
        background-size: 46px 27px;
        position: absolute;
        z-index: 1;
        bottom: 2.5rem;
        right: 2.5rem;
        top: auto !important;
        @include mobile {
          bottom: 1.875rem;
          right: 2rem;
          width: 2.25rem;
          height: 21px;
          background-size: cover;
        }
      }
    }
    .sub-title {
      @include heading(5);
      color: $color-blue;
      margin-top: 0;
      font-weight: 400;
      margin-bottom: 1.75rem;
      line-height: 1rem;
      @include mobile {
        margin-bottom: 1rem;
        line-height: 1rem;
      }
    }
    .title {
      @include heading(4);
      color: $color-darkgray;
      font-weight: 400;
      letter-spacing: 0;
      @include mobile {
        font-size: 1.125rem;
        line-break: 1.5rem;
        padding-right: 48px;
      }
    }
    .date {
      color: $color-sidebar-item;
      font-family: $font-heading;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 40;
      margin-bottom: 1rem;
      @include mobile {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
  &.article-cta {
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.05);
    text-transform: none;
    .card-body {
      border-top: 4px solid $color-blue;
      padding: 2.5rem;
    }
    .sub-title {
      @include heading(5);
      color: $color-blue;
      margin-top: 0;
      font-weight: 400;
      margin-bottom: 2rem;
    }
    .title {
      @include heading(4);
      color: $color-darkgray;
      font-weight: 400;
      @include mobile {
        font-size: 1.125rem;
        line-break: 1.5rem;
      }
    }
    .date {
      color: $color-sidebar-item;
      font-family: $font-heading;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 40;
      margin-bottom: 1rem;
      @include mobile {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
    a {
      margin-top: 50px;
      @include desktop {
        margin-top: 1.875rem;
      }
      @include mobile {
        margin-top: 1.25rem;
      }
    }
  }
  &.event {
    box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.08);
    text-transform: none;
    .card-body {
      border-top: 4px solid $color-peru;
      padding: 2.5rem;
    }
    .title {
      @include heading(3);
      color: $color-darkgray;
      font-weight: 400;
    }
    .location {
      color: $color-sidebar-item;
      display: flex;
      margin-bottom: 1rem;
      div {
        font-family: $font-heading;
        font-weight: 40;
        flex: 0.8;
        @include mobile {
          font-size: 0.875rem;
        }
      }
      img {
        height: 1.125rem;
        width: 0.75rem;
        margin-right: 8px;
      }
    }
    .date {
      color: $color-sidebar-item;
      font-family: $font-heading;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: 40;
      margin-bottom: 1rem;
      margin-top: 1.875rem;
      @include mobile {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
    a {
      margin-top: 50px;
      @include mobile {
        margin-top: 1.875rem;
      }
    }
    .card-eventfooter {
      padding-top: 60px;
      border-top: none;
      background-color: transparent;
      border-radius: 0;
      .text-link {
        position: absolute;
        bottom: 0;
        padding: 2.5rem;
      }
    }
  }
  &.article-simple {
    .subpage-image img {
      height: auto;
    }
    .subpage-subcard {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
    }
    .title {
      @include heading(4);
      color: $color-accordion-grey;
    }
    .card-body {
      border-top: 4px solid $color-orange;
      padding: 2.25rem 48px;
      @include mobile {
        padding: 1.5rem;
      }
      // .subpage-button {
      //   bottom: 25px;
      //   position: absolute;
      // }
    }
    p {
      // color: $color-darkgray;
      color: $color-banner-gray; //#494b54;
    }
  }

  img {
    width: 100%;
    display: block;
  }
  .card-body {
    position: relative;
    padding: 1.5rem;
  }
  .title {
    @include heading(6);
    margin-bottom: 0.625rem;
  }
  p {
    color: inherit;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  &.standard {
    .card-body {
      margin-bottom: 15px;
      padding-bottom: 0;
      height: 100%;
      max-height: 208px;
      overflow: hidden;
      p {
        margin-bottom: 0;
      }
      @include desktop {
        max-height: 225px;
      }
      @include tablet {
        max-height: 110px;
      }
      @include mobile {
        max-height: 160px;
      }
      @include mobile-small {
        max-height: 198px;
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(to bottom, $color-transparent 80%, $white);
      }
    }
    .card-footer {
      padding: 1.5rem;
      border-top: none;
      background-color: transparent;
      border-radius: 0;
      .text-link {
        position: absolute;
        bottom: 0;
        padding: 1.5rem 0;
      }
    }
    .article-image {
      height: 316px;
      margin: 0;
      background-size: cover;
      background-position: 100% center;
      background-repeat: no-repeat;
      background-color: $warm-grey;
      width: 100%;
      @include desktop {
        height: 220px;
      }
      @include tablet {
        height: 467px;
      }
      @include mobile {
        height: 316px;
      }
      @include mobile-small {
        height: 231px;
      }
    }
    .card--wipe--apollo:before {
      z-index: 2;
    }
  }
  &.leadership {
    .card-body {
      margin-bottom: 15px;
      // padding-bottom: 0;
      height: 100%;
      max-height: 208px;
      overflow: hidden;
      p {
        margin-bottom: 0;
        padding-bottom: 5px;
        // color: #494b54;
        // opacity: 0.63;
        color: rgba(
          $color: $color-banner-gray,
          $alpha: 0.63
        ); // changing opacity to color transperency
        a {
          color: $color-chambray;
          font-weight: 600;
          // text-decoration: underline;
        }
      }
      // @include desktop {
      //   max-height: 225px;
      // }
      // @include tablet {
      //   max-height: 140px;
      // }
      // @include mobile {
      //   max-height: 160px;
      // }
      // @include mobile-small {
      //   max-height: 198px;
      // }
      // &:before {
      //   content: '';
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   background: linear-gradient(to bottom, $color-transparent 80%, $white);
      // }
    }
    .card-footer {
      padding: 1.5rem;
      border-top: none;
      background-color: transparent;
      border-radius: 0;
      .text-link {
        position: absolute;
        bottom: 0;
        padding: 1.5rem 0;
      }
    }
    .article-image {
      height: 316px;
      margin: 0;
      background-size: cover;
      background-position: 100% center;
      background-repeat: no-repeat;
      background-color: $warm-grey;
      width: 100%;
      @include desktop {
        height: 220px;
      }
      @include tablet {
        height: 467px;
      }
      @include mobile {
        height: 316px;
      }
      @include mobile-small {
        height: 231px;
      }
      @include mobile-medium {
        height: 251px;
      }
      /* iPad Portrait */
      @media only screen and (max-device-width: 768px) and (min-device-width: 768px) and (max-device-height: 1024px) and (min-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        height: 421px;
      }
    }
    .card--wipe--apollo:before {
      z-index: 2;
    }
    .logo {
      height: 100%;
    }
    .card--leaderwipe {
      position: relative;
      overflow: hidden;
      // cursor: pointer;
    }
  }
}

.card-image--wipe {
  overflow: hidden;
  cursor: pointer;
  // padding: 15px;

  &:hover .card-icon {
    opacity: 0.6;
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
    transition-duration: 1s;
  }

  &:hover .card-wipe--apollo::before {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 220deg)
      translate3d(0, 100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 240deg) translate3d(0, 100%, 0);
    transition-duration: 1.25s;
  }

  .card-icon {
    opacity: 0.95;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.05, 1.05, 1);
    transform: scale3d(1.05, 1.05, 1);
    transition-duration: 1s;
  }

  @include card-wipe--apollo(0);
}

//card image

.card-image {
  border-radius: 0.875rem; //cardImage styling
  &.card--wipe {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .card-image--body {
      position: relative;
      padding: 2rem 2.5rem 3rem 3rem;
    }
    &:hover .card-wipe--apollo::before {
      -webkit-transform: scale3d(3, 2, 1) rotate3d(0, 0, 1, 240deg)
        translate3d(0, 0, 0);
      transform: scale3d(3, 2, 1) rotate3d(0, 0, 1, 240deg) translate3d(0, 0, 0);
      transition-duration: 1.25s;
    }
    @include card-wipe--apollo(2rem);
  }
}

.card-image-wrapper {
  color: inherit;
  font-weight: inherit;
  @include flexbox();
  &:hover {
    color: inherit;
    font-weight: inherit;
  }
  .card-image {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 0px;
    .card-image--body {
      display: flex;
      flex-direction: column;
      height: 100%;
      .card-image--title {
        font-family: $font-heading;
        font-size: 1.25rem;
        line-height: 1.3;
        font-weight: 600;
        margin-bottom: 1rem;
        text-align: left;
      }
      .card-image--text {
        color: $color-darkgray;
        margin-top: 0;
        margin-bottom: 1rem;
      }
      .cta-textlink {
        display: flex;
        @include justify-content(flex-end);
        margin-top: auto;
      }
    }
  }
}

.card-article {
  display: block;
  background-color: $white;
  margin: 0 0.8125rem;
  box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.08);
  text-transform: none;
  border-radius: 0 0 0.875rem 0.875rem;
  &__featured {
    border-radius: 0.875rem;
  }
  &.card-wipe {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover .card-wipe--apollo::before {
      -webkit-transform: scale3d(3, 2, 1) rotate3d(0, 0, 1, 240deg)
        translate3d(0, 0, 0);
      transform: scale3d(3, 2, 1) rotate3d(0, 0, 1, 240deg) translate3d(0, 0, 0);
      transition-duration: 1.25s;
    }
    @include card-wipe--apollo(2rem);
    .card-article--body {
      position: relative;
      padding: 1.5rem 2.5rem 2rem;
      border-top: 0.25rem solid #386097;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(stretch);
      flex: 1;
      .sub-title {
        font-family: $font-heading;
        font-size: 1.125rem;
        line-height: 18px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.72px;
        color: #386097;
        margin-top: 0;
        margin-bottom: 0.75rem;
      }
      .date {
        font-family: $font-copy;
        font-size: 1rem;
        font-weight: 400;
        color: #282a32;
        line-height: 16px;
        letter-spacing: initial;
        margin-bottom: 0.75rem;
      }
      .title {
        font-size: 1.25rem;
        color: #282a32;
        font-family: $font-copy;
        letter-spacing: initial;
        margin-bottom: 1rem;
        line-height: 26px;
        font-weight: 400;
      }
    }
  }
  &.gradient__green-blue .card-article--body {
    @include gradientBorderTop(0.5rem, $linear-gradient-green-blue);
  }
  &.gradient__blue-violet .card-article--body {
    @include gradientBorderTop(0.5rem, $linear-gradient-blue-violet);
  }
  &.card-article__case-study {
    @include mobile {
      margin: 0 1.5rem 1.5rem 1.5rem;
    }
  }
  &.card-article__case-study .cta-textlink {
    text-align: end;
    // padding: 1.875rem;
    @include mobile {
      padding: 0;
    }
    svg {
      width: 2.875rem;
      height: 1.688rem;
      @include mobile {
        width: 2.25rem;
        height: 1.313rem;
      }
    }
  }
}

.card-bio {
  margin: 2.5rem 0;
  .image-wrapper {
    img {
      border-radius: 0.875rem 0.875rem 0 0;
      width: 100%;
      height: auto;
    }
    &.green-blue {
      img {
        @include gradientBorderBottom(0.75rem, $linear-gradient-green-blue);
      }
    }
    &.blue-violet {
      img {
        @include gradientBorderBottom(0.75rem, $linear-gradient-blue-violet);
      }
    }
  }
  .title {
    font-family: $font-helvetica-bold;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 1rem 0;
    line-height: 24px;
  }
  .details {
    p {
      margin: 0;
      padding: 0;
    }
    a {
      display: block;
      color: $mpg-color-blue-100;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: normal;
      margin: 0 0 1rem;
      text-decoration: underline !important;
      &:hover {
        color: $mpg-color-blue-75;
      }
    }
  }
}
