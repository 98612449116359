@import '../../../../styles/scss/colors';


.social-icon-circle {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid $mpg-color-blue-100;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        font-size: 10px;
    }
}

.social-share-new-alert{
    position: absolute !important;
    bottom: -35%;
    right: 6%;
    z-index: 9999;
    @media (max-width: 768px){
        bottom: -55%;
        font-size: 12px;
    }
}