@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';

.home-banner-block {
  @include tablet {
    margin-top: -65px;
    height: 100%;
  }
  height: 100%;
  overflow: hidden;
  margin-top: 0;
  .content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.banner-section {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    padding: 6rem 0;
    // height: 100vh;
    position: relative;
    @include tablet {
      position: relative;
      width: 100%;
      min-height: auto;
      height: 100%;
      padding: 65px 0 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    @include mobile {
      @include flex-direction(column);
      // padding: 3rem 0;
    }
    .banner-image {
      background-size: cover;
      background-position: 75%;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include tablet {
        background-position: top right;
        background-size: cover;
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 50vh;
        margin-bottom: 3rem;
      }
      @include mobile {
        min-height: 26rem;
        height: auto;
      }
    }
    .content-wrapper {
      position: relative;
      height: auto;
      margin: 3.875rem 0;
      @include tablet {
        background: unset;
        padding: 0 0 3rem !important;
        margin: 0;
      }
      .content {
        .title {
          line-height: 1.125;
          margin-bottom: 0.5rem;
          font-size: 3rem;
          @include tablet {
            font-size: 2rem;
            color: $color-darkgray !important;
          }
        }
        p {
          margin: 2.25rem 0;
          @include tablet {
            color: $color-darkgray !important;
            margin: 1rem 0 1.5rem 0;
          }
          &.large {
            line-height: 1.3;
          }
        }
        .button {
          font-size: 20px;
          line-height: 20px;
          @include tablet {
            margin: 0;
          }
        }
      }
    }
  }
}

//animation home blocks hero

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-100px);
    -moz-transform: translatex(-100px);
    -o-transform: translatex(-100px);
    transform: translatex(-100px);
  }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0);
  }
}
.in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-duration: 1s;
  animation-delay: 1s;
}
