// @import '../../../styles/scss/colors';
// @import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.home-block__alert {
    @include flex(1);
    @include justify-content(center);
    @include flex-direction(column);
    text-align: center;
    width: 100%;
    padding-bottom: 2rem;
    &-hide {
        display: none;
    }
    &-close {
        text-align: right;
        height: 2.7825rem;
        button {
            background: none;
            border: 0;
            display: inline-block;
            margin-top: 1rem;
            margin-right: 1.5rem;
            cursor: pointer;
            color: inherit;
            padding: 0;
            &:hover {
                opacity: 0.75;
            }
        }
    }
    &-initial {
        .title {
            padding-bottom: 1.25rem;
            margin-bottom: 0;
        }
        .rich-text-wrapper {
            padding-bottom: 1.25rem;
        }
    }
    &-ongoing {
        padding: 1.75rem 0;
        .cta-textlink, .title, .home-block__alert-close {
            display: none;
        }
    }
}