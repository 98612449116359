@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.insights-search-results {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  margin: 25px 0px;
  // .col-md-6 {
  //   flex: 1 1 calc(50% - 30px);
  // }

  // .col-sm-12 {
  //   flex: 1 1 48%;
  // }
  @media (min-width: 1024px) and (max-width: 1400px) {
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 29.33333%;
    }
    .col-lg-8 {
      flex: 0 0 70.66667%;
      max-width: 70.66667%;
    }
    margin: 15px;
  }
  .card-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card-image {
      display: flex;

      img {
        width: 400px;
        height: 200px;
        //object-fit: cover;
        border-radius: 14px;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding: 0px 24px;

      .card-content {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .publication-details > span {
          margin: 8px 0px;
          @include mobile {
            margin: 0px;
          }
        }

        .publication-details {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0;

          .icon-container {
            padding: 0px 5px 0px 0px;
            margin: 0px !important;

            .icon {
              height: 16px;
              width: 16px;
            }
          }

          .publication-details-text {
            font-family: $font-heading;
            font-size: 12px;
            line-height: 13px;
            font-weight: 400;
            color: $mpg-color-gray-50;
            padding-left: 5px;

            @include mobile {
              font-size: 10px;
              line-height: 10px;
            }
          }

          .topic {
            @include mobile {
              display: inline-block;
              max-width: 12ch;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .story-text {
        .story-title {
          color: $color-darkgray;
          font-family: $font-heading;
          font-size: 20px;
          font-weight: 600;
          line-height: 26px;
          text-align: left;
          @include mobile {
            font-size: 16px;
          }
        }
      }

      .cta-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-top: 25px;

        .cta-link {
          font-family: $font-heading;
          font-size: 20px;
          line-height: 20px;
          font-weight: 600;
          color: $mpg-color-orange-55;
          text-decoration: none;
          text-align: left;
          display: flex;
          align-items: center;

          @include mobile {
            font-size: 17px;
            line-height: 20px;
          }

          &:hover {
            color: $mpg-color-orange-100;
          }
        }

        .icon-container {
          display: flex;
          align-items: center;

          .cta-icon {
            width: 20px;
            height: 20px;
            object-fit: contain;
             margin-left: 5px;
            // margin-top: 6px;
          }
        }
      }
    }
  }

  .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .card-wrapper {
      width: calc(50% - 10px); /* Two columns with space between */
      margin-bottom: 20px;

      @media (max-width: 767px) {
        width: 100%; /* One column on mobile */
        padding-bottom: 20px;
        border-bottom: 1px solid;
        border-bottom: 1px solid $pale-grey;
      }
    }

    .card-container {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      overflow: hidden;
      @media (max-width: 767px) {
        flex-direction: row-reverse;
      }
    }

    .card-image {
      img {
        width: 100%;
        @media (max-width: 767px) {
          width: 112px;
          height: 66px;
          align-self: center;
          border-radius: 2px;
        }
      }
    }

    .card-body {
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 767px) {
        padding: 0px;
      }
    }
  }
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  width: 100%;

  @include mobile {
    margin: 0px;
    margin-left: auto;
    width: auto;
  }

  .results-count {
    color: $mpg-color-gray-100;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    @include mobile {
      display: none;
    }
  }

  .results-title,
  .sorting-title {
    color: $color-darkgray-50;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    @include mobile {
      display: none;
    }
  }

  .sorting-section {
    display: flex;
    align-items: center;
    cursor: pointer;

    .sorting-filter {
      margin: 0px;
      color: $mpg-color-gray-100;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      @include mobile {
            position: absolute;
            right: 12px;
            top: -39px;
      }

      @include mobile-small{
       // top: -18%;
      }

      img {
        margin: 0px 5px;
      }

      .sort-text {
        @include mobile {
          display: none;
        }
      }
    }
  }
}

.sort-search-icon {
  svg {
    transition: transform 0.3s ease;
    margin: 0px 5px;
  }

  &.rotate-0 svg {
    transform: rotate(0deg);
  }

  &.rotate-180 svg {
    transform: rotate(180deg);
  }
}



.search-filters-global .filter-block {
  display: flex;
  justify-content: space-between;
  @include mobile{
    margin-bottom: 15px;
  }
}

.job-search-pills {
  @include flexbox;
  @include align-items(baseline);
  @include justify-content(flex-start);
  @include flex-wrap(wrap);
  padding: 0;
  @include mobile {
    @include flex-direction(column);
    display: flex;
  }
  .search-pill {
    margin: 0 1rem 1rem 0;
    @include mobile {
      width: 100%;
      @include flex-basis(100%);
    }
    .closeDetail {
      background-size: 8px 8px;
      width: 8px;
      height: 8px;
      margin-left: 8px;
      transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      float: right;
      img {
        padding-bottom: 3px;
      }
    }

    .filter-pill-text{
      @include mobile{
        font-size: 14px;
      }
    }
  }
  .primary-button.search-pill {
    &.search-pill {
      background-color: $pale-grey;
      border-color: transparent;
      color: $color-darkgray !important;
      text-transform: none !important;
      text-align: left;
      float: left;
      font-family: $font-copy;
      font-weight: 300;
      font-size: 0.875rem;
      padding: 9px 17px 7px 17px;
      border-radius: 20px;
      letter-spacing: normal;
      transition: $btn-transition;
      &::after {
        background-size: 8px 8px;
        width: 8px;
        height: 8px;
        margin-left: 8px;
        transition: $btn-transition;
        float: right;
      }
      &:hover {
        background-color: $pale-grey;
        border-color: transparent;
        color: $color-darkgray !important;
        font-weight: 300;
        &::after {
          filter: brightness(0) invert(1);
        }
      }
    }
    &:hover {
      .closeDetail {
        filter: brightness(0) invert(0);
      }
    }
  }
  .clear-filters {
    font-size: 14px;
    font-family: $font-helvetica-med;
    margin: 1em 1em 1em 0;
    color: $color-philippine-gray;
    @include tablet {
      margin: 1rem 0;
    }
    @include mobile {
      margin: 1em 1em 0em auto;
      padding-bottom: 10px;
    }
    .button-text {
      font-size: 14px;
      font-family: $font-helvetica-med;
      @include mobile {
        font-size: 16px;
        font-family: $font-helvetica-bold;
      }
    }
  }
  &.overlay-pill {
    display: block;
    text-align: right;
  }
}

.desktop-only-social-card{
  display: block;
  @media (max-width:768px) {
    display: none;
  }
}

.mobile-only-social-card{
  display: none;
  @media (max-width:768px) {
    display: block;
  }
}
