@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

$gradient_blue: $color-dazzled-blue, $color-darkblue;
$gradient_green: $green, $color-dark-slate-gray;

.card-block {
  padding: 3.5rem 0;
  .title {
    text-align: center;
    line-height: 1.875rem;
    margin-bottom: 1.25rem;
  }
  .sub-title {
    margin-block: 1.25rem;
  }
  &.card-block__stat {
    padding: 2.5rem 0 3.5rem;
    @include tablet {
      padding: 80px 0 60px;
    }
    @include mobile {
      padding: 60px 0 2.5rem;
    }
    .title,
    .sub-title {
      text-align: center;
      margin: 0;
    }
    .sub-title {
      margin: 1.25rem 0 1.5rem;
    }
    &.no-top-pad {
      padding-top: 0;
    }
    &.gradient {
      background-image: linear-gradient(to right, $green, $color-blue-gray);
      .card.stat {
        background-color: transparent;
      }
      .card.stat .stat-line .stat,
      .card.stat p {
        color: $white;
      }
    }
    .card-wrapper {
      .card {
        border: none;
        display: flex;
        justify-content: space-between;
        padding: 1.25rem 0;
      }
    }

    &.manpower {
      .card.stat {
        background-color: $white;
      }
      .card.stat .stat-line .stat {
        color: $green;
      }
      .card.stat p {
        color: $color-darkgray;
      }
    }
  }

  &.card-block__expertise {
    padding: 2.5rem 0 3.5rem;
    @include mobile {
      padding: 3rem 0;
    }
    .sub-title {
      margin-block: 1.25rem 2.5rem;
    }
    .card-wrapper {
      .card {
        padding: 3rem 2.5rem 2.5rem;
        margin: 0 0.8125rem;
        .icon {
          max-width: 5.25rem;
          margin: 0 auto 1.5rem;
          border-radius: unset;
        }
        .heading {
          padding: 0;
          margin-bottom: 0.75rem;
        }
        .content {
          padding: 0;
          div p {
            padding-bottom: 0.75rem;
            line-height: 22px;
          }
          img {
            width: 100%;
            border-radius: unset;
          }
        }
        .cta-textlink {
          padding-top: 1.25rem;
        }
      }
    }
    .title {
      margin-bottom: 1rem;
    }
  }

  &.card-block__offerings {
    .card-wrapper {
      padding: 28px 32px;
      p {
        line-height: 22px;
        padding: 0;
      }
      .cta-textlink {
        padding-top: 1rem;
      }
    }
  }

  &.card-block__info {
    padding: 3.5rem 0;
    .title {
      margin-bottom: 0;
    }
    .card-wrapper {
      margin-top: 2.5rem;
      .card {
        margin: 0 0.8125rem;
        @include mobile {
          margin: 0;
        }
        img.icon {
          border-radius: unset;
        }
        .heading {
          padding-bottom: 0;
          margin-bottom: 0.75rem;
        }
        .content {
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: 2rem;
          @include tablet {
            margin-bottom: 1.25rem;
          }
        }
      }
    }
  }

  &.card-block__resources {
    padding: 3.5rem 0;
    .card-resource__header {
      display: flex;
      // padding: 0 1rem;
      margin-bottom: 1.5rem;
      @include mobile {
        margin-bottom: 1.25rem;
        // padding: 0 1.875rem;
      }
      .title {
        @include heading(5);
        font-size: 1.25rem;
        text-transform: uppercase;
        flex: 1;
        margin-bottom: 0;
        text-align: left;
      }
      .cta-textlink .cta-text.blue {
        color: $mpg-color-blue-100;
        &:hover {
          color: $mpg-color-blue-75;
        }
      }
    }
    .card-resources__list {
      @include flexbox();
      @include flex-wrap(wrap);
      @include flex-direction(column);
      .card.resource {
        &:first-child {
          border-top: 1px solid rgba(147, 148, 152, 0.34);
          margin-bottom: 0;
          padding-top: 8px;
        }
        @include flex-direction(row);
        @include flexbox();
        @include align-items(center);
        border: none;
        border-radius: 0;
        margin: 1rem 0;
        padding: 0 0 1rem;
        border-bottom: 1px solid rgba(147, 148, 152, 0.34);
        box-shadow: none;
        background: transparent;
        .image {
          > img {
            border-radius: 0.5rem;
          }
          @include mobile {
            max-width: 4.375rem;
          }
        }
        .content {
          @include flexbox();
          align-self: stretch;
          @include flex-direction(column);
          margin: 1rem 0;
          padding-left: 1.5rem;
          flex: 1;
          @include justify-content(space-between);
          .heading {
            font-family: $font-heading;
            margin-bottom: 1rem;
            @include mobile {
              margin-bottom: 1rem;
            }
          }
          .cta-textlink a {
            display: flex;
            align-items: center;
            .cta-icon {
              margin-left: 0.625rem;
            }
          }
        }
      }
    }
  }

  .card-wrapper {
    // display: flex;
    // flex-basis: 25%;
    // padding: 1.5rem 1rem 2.5rem;
    .card {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      box-shadow: none;
      text-align: center;
      margin: 0;
      padding: 0;
      flex: 1;
    }
  }

  &.light {
    padding: 120px 0 100px;
    @include mobile {
      padding: 70px 0 50px;
    }
    .title {
      @include heading(3);
      color: $color-darkgray;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      @include mobile {
        margin-bottom: 50px;
      }
    }
    .card {
      .title {
        // font-size: 1.5rem;
        line-height: 29px;
        margin-bottom: 0.625rem;
        color: $color-darkgray;
        @include mobile {
          font-size: 1.375rem;
          line-height: 27px;
        }
      }
    }
  }
  &.dark {
    padding: 120px 0 100px;
    background-color: $color-darkgray;
    @include mobile {
      padding: 70px 0 50px;
    }
    .title {
      @include heading(2);
      color: $white;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
      @include mobile {
        margin-bottom: 50px;
      }
    }
    .card {
      .title {
        // font-size: 1.5rem;
        line-height: 29px;
        margin-bottom: 0.625rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        white-space: normal;
        @include mobile {
          font-size: 1.375rem;
          line-height: 27px;
        }
      }
      .card-body {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
      }
    }
  }
  &.subpage {
    // background: -webkit-linear-gradient(top, #345f9a, #1c3e6b);
    // background: -moz-linear-gradient(top, #345f9a, #1c3e6b);
    // background: -o-linear-gradient(top, #345f9a, #1c3e6b);
    // background: linear-gradient(top, #345f9a, #1c3e6b);

    // background: -webkit-linear-gradient(180deg, #345f9a, #1c3e6b);
    // background: -moz-linear-gradient(180deg, #345f9a, #1c3e6b);
    // background: -o-linear-gradient(180deg, #345f9a, #1c3e6b);
    // background: linear-gradient(180deg, #345f9a, #1c3e6b);
    // background: -webkit-linear-gradient(180deg, #345f9a, #1c3e6b);
    // background: -ms-linear-gradient(top, #345f9a, #1c3e6b);
    padding: 120px 0;
    //@include gradient(180deg, $gradient);

    @include mobile {
      padding: 50px 0 1.875rem;
    }
    .row-div {
      @include tablet {
        margin-bottom: 1.5rem;
      }
      @include mobile {
        margin-bottom: 1.5rem;
      }
    }
    .card {
      @include tablet {
        margin-bottom: 1.5rem;
      }
      @include mobile {
        margin-bottom: 1.5rem;
      }
    }
    &.diamond {
      padding: 0.625rem 0 90px 0;
    }
  }
  &.gradient_blue {
    @include gradient(180deg, $gradient_blue);
  }
  &.gradient_green {
    @include gradient(180deg, $gradient_green);
  }

  &.insights {
    padding: 1.875rem 0 70px;
    overflow: hidden;
    @include mobile {
      padding-bottom: 1.875rem;
    }
    [class*='col-'] {
      margin-bottom: 48px;
      @include mobile {
        margin-bottom: 2px;
      }
    }
    .container {
      padding: 0;
    }
    .card {
      height: 100%;
      position: relative;
      padding-bottom: 5px;
      margin: 15px;
      border: none;
      @include mobile {
        height: auto;
        padding-bottom: 0;
      }
      &.article {
        @include mobile {
          box-shadow: none;
        }
        .card-body {
          @include mobile {
            border-top: 1px solid $color-light-border;
            padding: 1.25rem 48px 0.625rem 0;
          }
        }
      }
      &.featured {
        @include mobile {
          box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.08);
          margin-bottom: 1.5rem;
          padding-bottom: 1.875rem;
          .card-body {
            padding: 1.25rem 48px 0.625rem 2.25rem;
          }
        }
        .article-image {
          height: 340px;
          margin: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-color: $warm-grey;
          width: 100%;
          @include desktop-medium {
            height: 344px;
          }
          @include desktop-small {
            height: 239px;
          }
          @include ipad-medium {
            height: 380px;
          }
          @include ipad-small {
            height: 281px;
          }
          @include tablet-medium {
            height: 248px;
          }
          @include tablet-mini {
            height: 215px;
          }
          @include tablet-small {
            height: 183px;
          }
        }
      }
      &.ad {
        padding-bottom: 0;
        .title {
          padding-top: 45px;
          max-height: 172px;
          &:before {
            background: linear-gradient(
              to bottom,
              $color-black-transparent 60%,
              $color-darkgray
            );
          }
        }
      }
      .card-body {
        position: static;
      }
      .title {
        height: 100%;
        max-height: 142px;
        overflow: hidden;
        @include mobile {
          max-height: 125px;
        }
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          max-height: 142px;
          background: linear-gradient(to bottom, $color-transparent 60%, $white);
          @include mobile {
            max-height: 125px;
            background: linear-gradient(to bottom, $color-transparent 50%, $white);
          }
        }
      }
      .card--wipe--apollo:before {
        z-index: 2;
        right: 2.5rem;
      }
    }
    .filters {
      padding: 0 13px 1.875rem;
    }
    .load-more-btn {
      text-align: center;
      margin-top: 1.25rem;
      .arrow-icon {
        display: none;
      }
    }
  }
}

.card-block-resources {
  background-color: $white-faded;
  padding: 80px 0;
  @include mobile {
    padding: 2.5rem 0;
    & + .site-footer {
      margin-top: 0;
    }
  }
  .resources-list {
    display: flex;
    flex-wrap: wrap;
    .card-resource {
      width: 50%;
      @include desktop {
        width: 100%;
        &:last-child {
          border-bottom: 1px solid rgba($color-footergray, 0.34);
          padding-bottom: 15px;
        }
      }
    }
  }

  .section-header {
    display: flex;
    margin-bottom: 1.875rem;
    @include mobile {
      margin-bottom: 1.25rem;
    }
    .title {
      flex: 1;
      @include heading(5);
    }
    .button-text {
      font-size: 1.25rem;
      @include mobile {
        font-size: 13px;
      }
    }
  }
}

/* Team CSS */
.team {
  &.card.featured .article-image {
    background-size: contain !important;
    background-color: $white !important;
  }
  .card-body {
    .sub-title {
      text-transform: uppercase !important;
    }
  }
  .card--wipe--apollo:before {
    transition: transform 0.6s !important;
    transform: scale3d(3, 1.4, 1) rotate(220deg) translate3d(0, -100%, 0) !important;
    @include mobile {
      transition: transform 0.6s;
      transform: scale3d(5, 1.4, 1) rotate(220deg) translate3d(0, -100%, 0) !important;
    }
  }
  &.card--wipe:hover .card--wipe--apollo:before {
    transform: scale3d(6, 2, 1) rotate(240deg) translateZ(0) !important;
    transition-duration: 1.25s !important;
  }
}
