@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.video-player-container {
  border-radius: 28px;
  .playIconThumbnail {
    height: 96px;
    width: 96px;
  }
  overflow: hidden;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: $black;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .fullscreen-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    border: none;
    cursor: pointer;
    z-index: 1;
  }
}

.video-player-topics-insights, .video-player-topics-insights > span{
  overflow: hidden;
  color: $mpg-color-gray-50;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 12px; 
  margin: 12px 0px 24px 0px;
  .topics-icon{
    width: 16px;
    height: 16px;
    padding-right:2px
  }
}

@media (max-width: 767px) {
  
  .video-player-container {
    .playIconThumbnail {
      height: 36px;
      width: 36px;
    }
    border-radius: 14px;
    .fullscreen-button {
      bottom: 5px;
      right: 5px;
      padding: 5px 10px;
      display: none;
    }
  }

  .video-player-content{
    .video-player-title-insights{
      font-size: 32px;
    }
    .video-player-topics-insights, .video-player-topics-insights > span{
      font-size: 10px;
      margin: 8px 0px 16px 0px;
      .topics-icon{
        width: 12px;
        height: 12px;
      }
    }
  }
}
