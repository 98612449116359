@import './colors';
@import './fonts';
@import './mixins';

$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.cta-textlink {
  a,
  .link-div,
  .button-text {
    /* TEXT LINK */
    .cta-text {
      @include anchor();
      @include flexbox();
      padding-block: 8px;
      color: $ui-primary-button-color;
      &:hover {
        color: $ui-primary-button-color-hover;
        .cta-icon {
          transform: translateX(5px);
          @include mobile {
            width: 17px !important;
            height: 0.625rem !important;
            background-size: 17px 0.625rem !important;
            top: unset !important;
          }
        }
        .cta-icon--download {
          transform: translateY(5px);
        }
      }
      &.hyperlink {
        border-bottom: 1px solid transparent;
      }
      .cta-icon {
        display: inline-block;
        margin-left: 0.625rem;
        transition: $link-transition;
        position: relative;
        background-repeat: no-repeat;
        // top: 1px;
        @include mobile {
          width: 17px !important;
          height: 0.625rem !important;
          background-size: 17px 0.625rem !important;
          top: unset !important;
        }
      }
      &--reversed {
        color: $white;
        &:hover {
          color: $white;
          opacity: 0.75 !important;
        }
        &.hyperlink {
          border-color: $white;
        }
      }
      &--blue {
        @include ctaLink(
          $ui-secondary-button-color,
          $ui-secondary-button-color-hover
        );
        &-reversed {
          @include ctaLink($mpg-color-blue-25, $mpg-color-blue-00);
        }
      }
      &--darkgray {
        @include ctaLink($color-darkgray-100, $color-darkgray-75);
      }
      &--orange {
        @include ctaLink($ui-primary-button-color, $ui-primary-button-color-hover);
        &-reversed {
          @include ctaLink($mpg-color-orange-25, $mpg-color-orange-00);
          &:hover {
            opacity: 1 !important;
          }
        }
      }
      &--darkblue {
        @include ctaLink($mpg-color-blue-100, $mpg-color-blue-75);
      }
    }
  }
}
