@import '../../../../../styles/scss/colors';
@import '../../../../../styles/scss/fonts';
@import '../../../../../styles/scss/mixins';

$user-icon: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzI4MkEzMiIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNLjAwNCAyMC4zMzRjLjA1My0uNDA0LjA5NC0uODEyLjE2NC0xLjIxMS41MzctMi45MzUgMi4wNDctNS4yMyA0LjQ0Mi02Ljk1NyAxLjY1NyAxLjU5NSAzLjYxIDIuNDM2IDUuODkgMi40MzEgMi4yNzIgMCA0LjIyLS44MzIgNS44OTQtMi40NC4zMzIuMjY3LjY4LjUyMSAxIC44MDkgMi4wMTkgMS44MTkgMy4yMDggNC4wODggMy41MzIgNi44MTQuMDIuMTgzLjA1LjM3LjA3NC41NTRWMjFIMGMuMDA0LS4yMi4wMDQtLjQ0NS4wMDQtLjY2NnoiLz48cGF0aCBkPSJNMTAuOTczIDBjLjQuMDguODA3LjEzIDEuMTk1LjI0MSAyLjM3Ni42NjEgNC4xMzQgMi43NjIgNC4zOCA1LjIxOGE2LjA4MiA2LjA4MiAwIDAgMS01LjU4NyA2LjY3OGMtMy4wODUuMjU4LTUuOTI0LTEuOTM4LTYuNDQ3LTQuOTg4QzMuOTUyIDMuODg5IDUuOTkxLjg1IDkuMjI2LjEzNWMuMjYyLS4wNi41My0uMDkxLjc5Ni0uMTM1aC45NXoiLz48L2c+PC9zdmc+);
$user-icon-active: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0yMi40MjQgMThhLjU5Ny41OTcgMCAwIDAtLjQwNy4xNjJsLTMuMzM5IDMuNDQ1LTEuNzEyLTEuNzdhLjU4NC41ODQgMCAwIDAtLjM5OC0uMTYyLjU3OC41NzggMCAwIDAtLjU2OC41ODFjMCAuMTU0LjA2LjMuMTYxLjQwMmwyLjExOSAyLjE4YS41ODQuNTg0IDAgMCAwIC4zOTguMTYyYy4xNTMgMCAuMjk3LS4wNi40MDctLjE2MmwzLjczNy0zLjg0N0EuNjc3LjY3NyAwIDAgMCAyMyAxOC41OWEuNTg3LjU4NyAwIDAgMC0uNTc2LS41OXoiIGlkPSJhIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTS4wMDQgMjAuMzM0Yy4wNTMtLjQwNC4wOTQtLjgxMi4xNjQtMS4yMTEuNTM3LTIuOTM1IDIuMDQ3LTUuMjMgNC40NDItNi45NTcgMS42NTcgMS41OTUgMy42MSAyLjQzNiA1Ljg5IDIuNDMxIDIuMjcyIDAgNC4yMi0uODMyIDUuODk0LTIuNDQuMzMyLjI2Ny42OC41MjEgMSAuODA5IDIuMDE5IDEuODE5IDMuMjA4IDQuMDg4IDMuNTMyIDYuODE0LjAyLjE4My4wNS4zNy4wNzQuNTU0VjIxSDBjLjAwNC0uMjIuMDA0LS40NDUuMDA0LS42NjZ6IiBmaWxsPSIjMjgyQTMyIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNMTAuOTczIDBjLjQuMDguODA3LjEzIDEuMTk1LjI0MSAyLjM3Ni42NjEgNC4xMzQgMi43NjIgNC4zOCA1LjIxOGE2LjA4MiA2LjA4MiAwIDAgMS01LjU4NyA2LjY3OGMtMy4wODUuMjU4LTUuOTI0LTEuOTM4LTYuNDQ3LTQuOTg4QzMuOTUyIDMuODg5IDUuOTkxLjg1IDkuMjI2LjEzNWMuMjYyLS4wNi41My0uMDkxLjc5Ni0uMTM1aC45NXoiIGZpbGw9IiMyODJBMzIiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxjaXJjbGUgZmlsbD0iIzFEQkMxQiIgY3g9IjE5LjUiIGN5PSIyMC41IiByPSI2LjUiLz48dXNlIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTIiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48L2c+PC9zdmc+);

.dropdown-block {
  position: absolute;
  left: 0;
  top: 100%;
  width: 35rem;
  user-select: none;
  padding-top: 1rem;
  cursor: default;
  opacity: 0;
  margin-top: -1px;
  display: block;
  opacity: 1;
  display: none;
  z-index: 3;
  @include mobile {
    width: 21.563rem;
  }
  @include mobile-small {
    width: 18.125rem;
  }
  &.small {
    width: 13.75rem;
    .dropdown-block-inside {
      padding: 0.938rem 1.875rem;
      max-height: 14.5rem;
      overflow-y: auto;
    }
  }
  &.right {
    left: initial;
    right: 0;
    @include mobile {
      left: -3.125rem;
    }
  }
  .dropdown-block-inside {
    background-color: $white;
    padding: 1.875rem;
    width: 100%;
    border: 1px solid $color-dark-border;
    box-shadow: 0 0.938rem 1.563rem rgba($black, 0.15);
  }
  .title {
    @include heading(4);
    margin-bottom: 0.625rem;
  }
  .job-filters {
    .filter-title {
      @include heading(4);
      margin-bottom: 0.625rem;
    }
    .cta {
      text-align: right;
      .dark-gray {
        margin-right: 3.75rem;
      }
    }
    .update_btn,
    .cancel_btn {
      text-transform: uppercase;
    }
  }
  label {
    display: none;
  }
  .btns-container {
    align-items: center;
    margin-top: 1.75rem;
    .col {
      text-align: right;
    }
  }
}
.dropdown-block.true {
  display: block;
}
.dropdown-block.false {
  display: none;
}
.sort-option {
  padding: 8px 0;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  &:hover {
    transform: translateX(5px);
  }
  &.active {
    color: $color-orange;
    text-shadow: 0px 0px 1px $color-orange;
  }
}
.range-slider-shell {
  position: relative;
  height: 1.25rem;
  margin: 1.25rem 0;
}
.dropdown select {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}
.button-text.dark-gray {
  border-bottom: 1px solid $color-darkgray !important;
}
.range-slider-container {
  .distance {
    margin: 1.875rem 0;
    @include mobile {
      margin: 1.25rem 0;
    }
  }
  .distance,
  strong {
    font-family: $font-copy !important;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 400;
    color: $color-light-body;
    @include mobile {
      font-size: 1rem;
    }
  }
  strong {
    color: $color-darkgray;
  }
  .range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: $color-bright-gray;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:hover {
      opacity: 1;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 1.25rem;
      height: 1.25rem;
      background: $color-blue;
      cursor: pointer;
      border-radius: 0.625rem;
    }
    &::-moz-range-thumb {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 0.625rem;
      background: $color-blue;
      cursor: pointer;
    }
  }
}
