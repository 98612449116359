$mobile-width-small: 374px;
$mobile-width: 768px;
$mobile-width-large: 892px;
$mobile-width-plus: 480px;
$tablet-width: 992px;
$desktop-large-width: 1400px;
$desktop-small-width: 1366px;
$desktop-medium-width: 1280px;

$desktop-small: 1399.5px;

$header-bar-height: 96px;

$tablet-width-nopx: 992;
$mobile-width-medium: 564px;
$tablet-medium: 482px;
$tablet-mini: 420px;
$tablet-small: 370px;

$desktop-width-medium: 1200px;

$ipad-mini-width: 1590px;
$ipad-small: 767.5px;
$ipad-medium: 991.5px;

$medium-iphones-height: 736px;

// $mobile-width-small: 375px;
// $mobile-width: 767px;
// $tablet-width: 991px;
// $desktop-large-width: 1400px;

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

// $ui-image-border-radius: 0.875rem;