@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';
@import '../../../../styles/scss/theme';

$form-transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);

@each $theme in $theme-list {
  &.#{$theme} {
    .form-block {
      &.checkbox {
        input[type='checkbox']:checked + label::before {
          background-color: map-get($theme-color, $theme);
        }
      }
    }
    .form-radio-group {
      .radio-toggle {
        input[type='radio']:checked:after {
          background-color: map-get($theme-color, $theme);
        }
      }
    }
  }
}

.form-block {
  position: relative;
  margin-bottom: 1rem;
  // &.select-active {
  //   select {
  //     & + label {
  //       display: none;
  //     }
  //   }
  // }
  .label-text {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hintText {
    font-size: 13px;
    color: $color-lightgray;
    padding: 15px;
  }
  &.error {
    input,
    textarea,
    .text-area-outer,
    .radio-toggle,
    select {
      border-color: $color-maroon;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMiIgd2lkdGg9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjOUQzMjNEIiBkPSJNNDM3LjUgMzg2LjZMMzA2LjkgMjU2bDEzMC42LTEzMC42YzE0LjEtMTQuMSAxNC4xLTM2LjggMC01MC45LTE0LjEtMTQuMS0zNi44LTE0LjEtNTAuOSAwTDI1NiAyMDUuMSAxMjUuNCA3NC41Yy0xNC4xLTE0LjEtMzYuOC0xNC4xLTUwLjkgMC0xNC4xIDE0LjEtMTQuMSAzNi44IDAgNTAuOUwyMDUuMSAyNTYgNzQuNSAzODYuNmMtMTQuMSAxNC4xLTE0LjEgMzYuOCAwIDUwLjkgMTQuMSAxNC4xIDM2LjggMTQuMSA1MC45IDBMMjU2IDMwNi45bDEzMC42IDEzMC42YzE0LjEgMTQuMSAzNi44IDE0LjEgNTAuOSAwIDE0LTE0LjEgMTQtMzYuOSAwLTUwLjl6Ii8+PC9zdmc+);
      background-size: 1rem 1rem;
      background-repeat: no-repeat;
      background-position: right 1.25rem center;
      // box-shadow: inset 0px 0px 0px 1px $color-maroon;
      border-color: $color-maroon;
      &:focus {
        border-color: $color-maroon;
      }
    }
    &.withoutBg {
      input,
      textarea,
      .text-area-outer,
      select {
        background: none !important;
      }
    }
  }
  &.success {
    input,
    select {
      border-color: $color-teal;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjQ3IDBDMTEuODIgMi41NjUgOC43NDQgNS41MjggNi4wMjIgOC4xNjNMMS4zOTYgNC4zNjYgMCA1Ljk1bDUuMzg0IDQuNDIzLjc1Ny42MjYuNzA3LS42ODNjMi44Ny0yLjc4IDYuMjgtNi4wNjMgOS4xNTItOC44NDZMMTQuNDcgMHoiIGZpbGw9IiM0MzgwODAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-position: right 1.25rem center;
      // box-shadow: inset 0px 0px 0px 1px $color-teal;
      border-color: $color-teal;
      &:focus {
        border-color: $color-teal;
      }
    }
  }
  &.complex {
    label {
      position: absolute;
      left: 25px;
      top: 1rem;
      font-family: $font-copy;
      font-size: 0.75rem;
      color: rgba($color-lightgray, 0.5);
      transition: $form-transition;
      pointer-events: none;
      @include tablet {
        left: 15px;
      }
      .input-icon {
        margin-right: 5px;
        position: relative;
        top: -1px;
      }
      .active {
        .input-icon {
          max-height: 0.75rem;
        }
      }
      .inactive {
        .input-icon {
          max-height: 1rem;
        }
      }
    }
    textarea {
      &:read-only {
        background-color: $color-lotion-white;
      }
      &:focus,
      &.filled {
        padding-top: 15px;
        @include placeholder-text(rgba($color-lightgray, 0));
        & + label {
          top: 4px;
          opacity: 1;
          .active {
            display: block;
            font-size: 0.75rem;
          }
          .inactive {
            display: none;
          }
        }
      }
      & + label {
        top: 11px;
        .active {
          display: none;
        }
        .inactive {
          position: relative;
        }
      }
    }

    select {
      &:read-only {
        background-color: $color-lotion-white;
      }
      &:focus,
      &.filled {
        padding-top: 15px;
        @include placeholder-text(rgba($color-lightgray, 0));
        & + label {
          top: 0.75rem;
          opacity: 1;
          .active {
            display: block;
            font-size: 0.75rem;
          }
          .inactive {
            display: none;
          }
        }
      }
      & + label {
        top: 25px;
        pointer-events: none;
        .active {
          display: none;
        }
        .inactive {
          position: relative;
        }
      }
    }

    input {
      height: 56px;
      // line-height: 56px;
      font-size: 1rem;
      &:read-only {
        background-color: $color-lotion-white;
      }
      &:focus,
      &.filled {
        padding-top: 15px;
        @include placeholder-text(rgba($color-lightgray, 0));
        & + label {
          top: 4px;
          opacity: 1;
          .active {
            display: block;
            font-size: 0.75rem;
          }
          .inactive {
            display: none;
          }
        }
      }
      & + label {
        max-width: 90%;
        .active {
          display: none;
        }
        .inactive {
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &.input-icon {
    input {
      padding-left: 50px;
      background-repeat: no-repeat;
      background-position: left 25px center;
      @include tablet {
        padding-left: 2.25rem;
        background-position: left 15px center;
      }
    }
  }
  input {
    display: block;
    width: 100%;
    height: 64px;
    // line-height: 64px; line-height affect caret sign in IOS
    border: 1px solid rgba($color-lightgray, 0.5);
    border-radius: 2px;
    background-color: $white;
    color: $color-darkgray;
    font-family: $font-copy;
    font-size: 1.25rem;
    padding: 0 1.5rem;
    outline-color: transparent;
    transition: $form-transition;
    box-shadow: none;
    outline: none;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // appearance: none;
    @include placeholder-text(rgba($color-lightgray, 0.5));
    @include tablet {
      height: 50px;
      line-height: 50px;
      font-size: 13px;
      padding: 0 15px;
    }
    &:focus {
      border: 2px solid black;
    }
    &::-ms-clear {
      display: none;
    }
  }
  .text-area-outer {
    padding: 1.25rem 0 5px 25px;
    min-height: 200px;
    border: 1px solid rgba($color-lightgray, 0.5);
    textarea {
      border: none !important;
      height: 100% !important;
      padding: 0 !important;
      background: transparent;
      box-shadow: none;
      resize: none;
      min-height: 0;
    }
    @include tablet {
      padding-left: 15px;
    }
  }
  textarea {
    display: block;
    width: 100%;
    border: 1px solid rgba($color-lightgray, 0.5);
    border-radius: 2px;
    background-color: $white;
    color: $color-darkgray;
    font-family: $font-copy;
    font-size: 1.25rem;
    padding: 1.25rem 25px 0;
    outline-color: transparent;
    transition: $form-transition;
    min-height: 200px;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include placeholder-text(rgba($color-lightgray, 0.5));
    @include tablet {
      font-size: 13px;
      padding: 0.625rem 15px;
    }
    &:focus {
      border-color: $color-darkgray;
    }
  }
  .error-border {
    border: 2px solid $color-maroon;
    &:focus {
      border: 2px solid $color-maroon;
    }
  }
  .error-msg {
    padding: 15px;
    font-family: $font-copy;
    font-size: 13px;
    line-height: 1;
    color: $color-maroon;
    background-color: rgba($color-maroon, 0.25);
    flex-grow: 1;
  }
  select {
    display: block;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0;
    box-shadow: none;
    height: 64px;
    line-height: 64px;
    border: 1px solid rgba($color-lightgray, 0.5);
    border-radius: 2px;
    color: $color-darkgray;
    font-family: $font-copy;
    font-size: 1.25rem;
    padding: 0 35px 0 25px;
    outline-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-color: $white;
    background-image: url('../../../../assets/images/chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1rem 0.75rem, 100%;
    @include tablet {
      height: 50px;
      line-height: 50px;
      font-size: 13px;
      padding: 0 35px 0 15px;
    }
    &::-ms-expand {
      display: none;
    }
    // &:focus {
    //   background-image: url('../../../../assets/images/chevron-up.svg');
    // }
    & + label {
      position: absolute;
      top: 2rem;
      transform: translateY(-50%);
      left: 25px;
      right: 35px;
      overflow: hidden;
      font-family: $font-copy;
      font-size: 1.25rem;
      color: $color-light-body;
      pointer-events: none;
      @include tablet {
        font-size: 13px;
        top: 1.5rem;
      }
    }
    &:focus {
      border-color: $color-darkgray;
    }
  }
}

.form-indicator {
  color: $color-indicator;
  padding-bottom: 5px;
}

.checkbox {
  input[type='checkbox'] {
    opacity: 0; // Hide
    display: initial;
    width: auto;
    height: auto;
    outline: auto;
    position: relative;
    z-index: 1;
    left: 6px;
    bottom: 3px;
  }
  label {
    position: relative;
    left: -10px; // Overlay Native Checkbox Input
    display: inline-flex;
    align-items: center;
    padding-left: 1.75rem;
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
    }
    &:before {
      height: 1.125rem;
      width: 1.125rem;
      background-color: $white;
      border: 1px solid $color-lightgray;
      left: 0;
      top: 0;
      @include mobile{
        border: 1px solid $color-darkgray-100;
        border-radius: 2px;
      }
    }
    &:after {
      height: 5px;
      width: 0.625rem;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-45deg);
      left: 5px;
      top: 8px;
    }
  }

  // Hide by default
  input[type='checkbox'] + label::after {
    content: none;
  }

  // Show when checked
  input[type='checkbox']:checked + label::after {
    content: '';
  }

  input[type='checkbox']:checked + label::before {
    background-color: $color-blue;
  }

  // Focus state
  input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}

.form-radio-group,
.form-checkbox-group {
  &.checklist {
    list-style: none;
    padding: 0;
    margin: 0.625rem 0;
    .checklist__item {
      margin-bottom: 1.25rem;
      .checkbox--toggle {
        span {
          color: $color-darkgray;
          font-size: 1rem;
          line-height: 1.5;
          right: 0;
          position: absolute;
        }
      }
      .radio-toggle {
        span {
          color: $color-darkgray;
          font-size: 1rem;
          line-height: 1.5;
          right: 0;
          position: absolute;
        }
      }
    }
  }

  input[type='checkbox'] {
    &.form-check-input {
      margin-left: 0;
    }
  }

  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    &:before {
      background-color: transparent;
      cursor: not-allowed;
    }
    &:after {
      cursor: not-allowed;
    }
  }

  .radio-toggle {
    input[type='radio'] {
      width: 1.25rem;
      height: 1.25rem;
      margin-top: 2px;
      + label {
        margin-left: 0.625rem;
        margin-top: 0px;
      }
    }

    input[type='radio']:checked:after {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 0.75rem;
      top: -2px;
      @include mobile {
        top: 0px !important;
      }
      left: 4px;
      position: relative;
      background-color: $color-blue;
      content: '';
      display: inline-block;
      visibility: visible;
    }
  }
}
