@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.card-block {
  &.tabbed__block {
    background-color: $white;
    padding: 5rem 0;
    display: flex;
    // .tab__content {
    //   .card-image {
    //     margin: 0 0 2.5rem;
    //   }
    // }
    .card-image {
      box-shadow: 3px 8px 1rem 0 rgba(0, 0, 0, 0.06);
      background-color: $white;
      &.gradient__green-blue .card-image--body {
        @include gradientBorderTop(0.5rem, $linear-gradient-green-blue);
      }
      &.gradient__blue-violet .card-image--body {
        @include gradientBorderTop(0.5rem, $linear-gradient-blue-violet);
      }
    }
    .card-block--image {
      width: 100%;
      display: block;
    }
    .card__tabs {
      position: relative;
      background-color: $mpg-color-gray-25;
      border-radius: 100px;
      width: 36rem;
      margin: 0 auto;
      @include mobile {
        width: 100%;
      }
      .slider {
        position: absolute;
        display: flex;
        height: 4rem;
        width: 18rem;
        background-color: $color-lightgray-100;
        z-index: 1;
        border-radius: 100px;
        transition: 0.25s ease-out;
        @include mobile {
          width: 50%;
          height: 3rem;
        }
      }
    }
    .card__link.active {
      color: $white !important;
    }
    .card__link:hover {
      opacity: 1;
    }
    .card__link {
      color: $color-darkgray !important;
    }
    .card__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      width: 18rem;
      font-family: $font-heading;
      font-size: 1.188rem;
      letter-spacing: 1.5px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 100px;
      cursor: pointer;
      transition: color 0.15s ease-in;
      padding: 0;
      @include mobile {
        width: 50%;
        height: 3rem;
        font-size: 0.8175rem;
        letter-spacing: 1px;
      }
    }
    .card__tabs * {
      z-index: 2;
    }
    &.blue {
      .card__link.active:nth-child(1) ~ .slider {
        background-color: $mpg-color-blue-50;
      }
      .card__link.active ~ .slider {
        background-color: $mpg-color-blue-50;
      }
    }
    &.violet {
      .card__link.active:nth-child(1) ~ .slider {
        background-color: $mpg-color-violet-75;
      }
      .card__link.active ~ .slider {
        background-color: $mpg-color-violet-75;
      }
    }
    .card__link.active:nth-child(1) ~ .slider {
      background-color: $color-darkgray;
      transform: translateX(0);
    }
    .card__link.active ~ .slider {
      background-color: $color-darkgray;
      transform: translateX(100%);
    }
  }
}
.text-center {
  text-align: center;
}

.tab__content {
  > .tab__pane {
    display: none;
  }
  > .active {
    display: block;
  }
  .tab__pane {
    margin: 3rem 0;
  }
}
