@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.detail-biography {
  padding: 2.5rem 0 4.5rem;
  .detail-bio__container {
    border-bottom: 2px solid $mpg-color-gray-75;
    padding-bottom: 2rem;
    .breadcrumb {
      margin-bottom: 2rem;
      padding: 0;
    }
    .detail-bio__header {
      .detail-bio__title {
        @include heading(2);
        font-size: 2rem;
        line-height: 38px;
        margin-bottom: 0;
        color: $mpg-color-blue-100;
      }
      .detail-bio__sub-title {
        margin: 0.75rem 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    .social-bar {
      .social-icons {
        flex-direction: row;
        @include mobile {
          flex-direction: column;
        }
        .social-icon {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 1.25rem;
          @include mobile {
            margin-top: 0.5rem;
            &:first-child {
              margin-top: 0;
              margin-right: 0;
            }
          }
          &:hover {
            opacity: 1;
          }
          img {
            margin-right: 0.5rem;
          }
          .text {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 22px;
            font-weight: 400;
            color: $mpg-color-gray-100;
            text-decoration: none !important;
            letter-spacing: normal;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .detail-bio__info {
    margin-top: 2.5rem;
    margin-right: 2rem;
  }
}
