@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

$user-icon-active: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0yMi40MjQgMThhLjU5Ny41OTcgMCAwIDAtLjQwNy4xNjJsLTMuMzM5IDMuNDQ1LTEuNzEyLTEuNzdhLjU4NC41ODQgMCAwIDAtLjM5OC0uMTYyLjU3OC41NzggMCAwIDAtLjU2OC41ODFjMCAuMTU0LjA2LjMuMTYxLjQwMmwyLjExOSAyLjE4YS41ODQuNTg0IDAgMCAwIC4zOTguMTYyYy4xNTMgMCAuMjk3LS4wNi40MDctLjE2MmwzLjczNy0zLjg0N0EuNjc3LjY3NyAwIDAgMCAyMyAxOC41OWEuNTg3LjU4NyAwIDAgMC0uNTc2LS41OXoiIGlkPSJhIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTS4wMDQgMjAuMzM0Yy4wNTMtLjQwNC4wOTQtLjgxMi4xNjQtMS4yMTEuNTM3LTIuOTM1IDIuMDQ3LTUuMjMgNC40NDItNi45NTcgMS42NTcgMS41OTUgMy42MSAyLjQzNiA1Ljg5IDIuNDMxIDIuMjcyIDAgNC4yMi0uODMyIDUuODk0LTIuNDQuMzMyLjI2Ny42OC41MjEgMSAuODA5IDIuMDE5IDEuODE5IDMuMjA4IDQuMDg4IDMuNTMyIDYuODE0LjAyLjE4My4wNS4zNy4wNzQuNTU0VjIxSDBjLjAwNC0uMjIuMDA0LS40NDUuMDA0LS42NjZ6IiBmaWxsPSIjMjgyQTMyIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNMTAuOTczIDBjLjQuMDguODA3LjEzIDEuMTk1LjI0MSAyLjM3Ni42NjEgNC4xMzQgMi43NjIgNC4zOCA1LjIxOGE2LjA4MiA2LjA4MiAwIDAgMS01LjU4NyA2LjY3OGMtMy4wODUuMjU4LTUuOTI0LTEuOTM4LTYuNDQ3LTQuOTg4QzMuOTUyIDMuODg5IDUuOTkxLjg1IDkuMjI2LjEzNWMuMjYyLS4wNi41My0uMDkxLjc5Ni0uMTM1aC45NXoiIGZpbGw9IiMyODJBMzIiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxjaXJjbGUgZmlsbD0iIzFEQkMxQiIgY3g9IjE5LjUiIGN5PSIyMC41IiByPSI2LjUiLz48dXNlIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTIiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48L2c+PC9zdmc+);
// Manpower Theme Site Header Component Overrides
.site-footer {
  width: 100vw;
  .footer-bottom-links {
    .rich-text-block {
      background-color: $color-darkgray-100;
      padding: 0;
      justify-content: flex-start;
      p {
        margin: 0;
        font-size: 0.75rem;
        color: $white;
      }
      // }
      .offset-lg-1 {
        margin-left: 0;
      }
    }
    .footer-social {
      padding-top: 0;
      .social-bar {
        .social-icons {
          justify-content: center;
        }
      }
    }
    .lang-nav {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      right: 0;
      z-index: 10;
      top: 5px;
      @include tablet {
        margin: -5px 0;
      }
      .language-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        // &:hover {
        //   opacity: 0.5;
        //   transition: all 0.3s ease;
        // }
      }
      .link_title {
        display: none;
        font-family: $font-heading;
        font-size: 0.875rem;
        padding-right: 1.875rem;
        margin-top: -2px;
        font-weight: 400;
        color: $white;
        a {
          text-decoration: none;
          color: $white;
          font-size: 0.75rem;
          margin-right: 5px;
        }
      }
      .title {
        display: flex;
        font-family: $font-heading;
        font-size: 0.75rem;
        margin-right: 5px;
        padding: 0;
        font-weight: 400;
        color: $white;
        align-self: center;
        align-items: center;
        letter-spacing: normal;
        &:before {
          content: '';
          position: relative;
          margin-right: 4px;
          background-image: url('../../../assets/images/icon__globe--white-large.svg');
          background-size: 1.5rem 1.5rem;
          display: flex;
          width: 1.5rem;
          height: 1.5rem;
          align-self: center;
        }
      }
      .country {
        display: flex;
        align-items: center;
        color: $white !important;
        font-weight: 400;
        font-size: 0.75rem;
        letter-spacing: normal;
        text-decoration: none !important;
        text-transform: capitalize;
        z-index: 1;
        background: none;
        border: none;
        padding: 0;
        margin-top: 0px;
        font-family: $font-heading;
        transition: all 0.3s ease;
      }
    }
    @include mobile {
      .footer-social {
        order: 1;
      }
      .country-links {
        order: 2;
      }
      .rich-text-block {
        order: 3;
      }
    }
    &:empty {
      border: none;
      padding: 0.5rem;
    }
  }
}
.site-footer .lang-nav {
  @include mobile {
    display: block;
  }
  @include desktop {
    display: none;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  .title {
    color: $black;
    text-transform: none;
    &:before {
      background-image: url('../../../assets/images/icon__globe--white.svg');
    }
  }
  .link_title,
  .link_title a {
    color: $black;
  }
  button {
    color: $white !important;
    &::after {
      background-image: url('../../../assets/images/icon__caret--white.svg');
    }
  }
}
