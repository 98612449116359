h1,
h2,
h3,
h4,
h5,
.heading {
  font-family: $font-heading, $font-copy, Helvetica, Arial, sans-serif;
  &.reversed {
    color: $white;
  }
}

h1 {
  font-size: 3rem; /* 48px */
  line-height: 1.25;
  font-weight: 600;
}
h2 {
  font-size: 1.5rem; /* 24px */
  line-height: 1.25;
  font-weight: 600;
}
h3 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.3;
  font-weight: 400;
}
h4 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.3;
  font-weight: 600;
}
h5 {
  font-size: 1.125rem; /* 18px */
  line-height: 1.3;
  font-weight: 400;
  color: $color-gray-subtitle;
}
h6,
.heading-6 {
  font-family: $font-copy;
  font-size: 1rem; /* 16px */
  line-height: 1.5;
  font-weight: 400;
  &.light {
    font-weight: 300;
  }
  &.reversed {
    color: $white;
  }
}

p {
  font-family: $font-copy;
  font-size: 1rem; /* 16px */
  line-height: 1.375;
  margin: 1.5rem 0;
  &.large {
    font-size: 1.25rem;
    line-height: 1.4;
  }
  &.x-small {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  &.fine {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $color-darkgray;
  }
  &.reversed {
    color: $white;
  }
}

a {
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
