@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.site-header .site-header-bar .main-nav__list {
  .main-nav__item {
    &.search {
      .main-nav-dropdown {
        .main-nav-dropdown-inside {
          padding-bottom: 0;
          .find-career-block {
            margin-bottom: 1.875rem;
          }
          .footer-section {
            text-align: center;
            margin-left: -30px;
            margin-right: -30px;
            background-color: $color-success-grey;
            box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
            a {
              &.footer-link {
                color: $warm-black;
                letter-spacing: 1.01px;
                line-height: pxtorem(20);
                @include fontrem(16);
                padding: 1.625rem 0 1.375rem;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}
