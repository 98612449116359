@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/theme';

.calloutbar-section {
  position: relative;
  padding: 4rem 0;

  .calloutbar-x {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -ms-background-position-x: 460px;
    background-position: left bottom, right top;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
    margin: 0%;
    z-index: 1;
    @media (max-width: 1280px) {
      -ms-background-position-x: 400px;
    }
    @media (max-width: 1024px) {
      -ms-background-position-x: 280px;
    }
    @media (max-width: 800px) {
      -ms-background-position-x: 150px;
    }
  }
  .calloutbar-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background-image: url('../../../assets/images/icon-x.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: contain;
    z-index: 1;
  }
  .skewed-rectangle {
    display: none;
  }
  .calloutbar-body {
    position: relative;
    z-index: 1;
    text-align: center;
    .title {
      font-family: $font-heading;
      color: inherit;
      @include heading(2);
      margin-bottom: 1.25rem;
      &.reversed {
        color: inherit;
      }
    }
    p {
      color: inherit;
      margin: 0;
    }
  }
  &.footer {
    .calloutbar-x {
      background-position: center center;
      background-size: cover;
    }
    .calloutbar-body {
      text-align: left;
      .title {
        @include heading(2);
        margin-bottom: 1.5rem;
        &.reversed {
          color: inherit;
        }
      }
      p {
        line-height: 1.4;
        margin-bottom: 24px;
      }
    }
  }
  &.standard,
  &.logo {
    padding: 5rem 0;
    @include mobile {
      padding: 2.5rem 0;
    }
  }
  &.logo {
    .calloutbar-x {
      background-position: 50%;
      background-size: cover;
      @include tablet {
        background-position: top;
      }
      @include mobile {
        background-position: top;
      }
    }
    .calloutbar-body {
      max-width: 50%;
      margin: 0 auto;
      @include tablet {
        max-width: 75%;
      }
      @include mobile {
        max-width: 100%;
      }
    }
  }
  &.utility {
    padding: 3.5rem 0;
    .calloutbar-x,
    .skewed-rectangle {
      display: none;
    }
    .calloutbar-body {
      text-align: left;
      .title {
        margin: 0 auto 2rem;
        line-height: 30px;
      }
      .large {
        p {
          margin: 0 0 1.5rem;
        }
      }
      a {
        font-weight: 400;
        font-size: 1rem;
        @include ctaLink($mpg-color-blue-100, $mpg-color-blue-75);
        display: inline;
        &.cta-text {
          @include anchor();
          display: inline;
          &--orange {
            @include ctaLink(
              $ui-primary-button-color,
              $ui-primary-button-color-hover
            );
          }
          &--darkblue {
            @include ctaLink($mpg-color-blue-100, $mpg-color-blue-75);
          }
          &--blue {
            @include ctaLink(
              $ui-secondary-button-color,
              $ui-secondary-button-color-hover
            );
          }
        }
      }
    }
  }
  &.standard {
    padding: 3.5rem 0;
    .calloutbar-body {
      .title,
      p {
        margin-bottom: 1.5rem;
      }
    }
  }
  &.contact {
    padding: 3.5rem 0;
    .calloutbar-body {
      h2 {
        margin-bottom: 1.5rem;
      }
      p {
        margin: 0 0 2rem 0;
      }
    }
    .details-wrapper {
      @include flexbox();
      @include justify-content(center);
      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .details {
        max-width: 12.5rem;
        color: inherit;
        margin: 0 2rem;
        font-size: 1rem;
        &:last-child {
          @include mobile {
            padding-bottom: 0;
          }
        }
        @include mobile {
          padding-bottom: 1.5rem;
          margin: 0 1rem;
        }
        a {
          color: inherit;
          font-family: $font-copy;
          font-weight: 400;
          font-size: 1rem;
          line-height: 22px;
          text-decoration: underline !important;
          letter-spacing: normal;
        }
      }
    }
  }
}

// New components - Insights
.callout-bar-simple{
  .callout-bar-simple-content{
    border-radius: 20px;
  }
  @include mobile {
    .callout-bar-simple-body{
      padding: 0px 0px 0px 0px !important;
    }
    .callout-bar-simple-button{
      margin-top: 1.5rem;
      padding: 0px 0px 0px 15px;
    }
  }
  @media (min-width: 768px) and (max-width: 940px) {
    .callout-bar-simple-body{
      margin-top: 15px;
    }
  }

}