@import '../../../styles/scss/mixins';

.country-link {
  .slideout-nav-country {
    padding-top: 60px;
    @include tablet {
      padding-top: 1.875rem;
    }
  }
  .slideout-nav-language {
    padding-top: 1.875rem;
  }
}
.footer-rightside {
  .slideout-nav-country {
    padding-top: 1.25rem;
    padding-bottom: 5px;
    @include tablet {
      padding-top: 1.25rem;
    }
  }
  .slideout-nav-language {
    padding-top: 1.25rem;
  }
}
