.cookie-style{
    padding-left: 5px;
}

.cookie-btn-style{
    color: #68b631;
    border: 1px solid #68b631;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    padding: 0.8em 2em;
    font-size: 0.8em;
    line-height: 1.2;
    cursor: pointer;
    transition: 0.1s ease;
}

.cookie-btn-style:hover{
    color: #ffffff;
    background-color: #68b631;
}