@import '../../../styles/scss/mixins';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';

.Toastify__toast-container {
  width: auto;
  @include mobile {
    width: 100%;
  }
}

.Toastify__toast--success {
  background-color: $green;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  animation: Toastify__trackProgress linear 1;
}

button.Toastify__close-button {
  margin: auto 0.625rem;
}
.Toastify__toast--error {
  background-color: $color-maroon;
}
