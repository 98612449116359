@import '../../../styles/scss/mixins';
@import '../../../styles//scss/colors';

.social-bar {
  display: flex;
}
.social-icons {
  display: flex;
  //justify-content: space-evenly;
  flex-direction: row;
  margin-left: 0px;
  /*  & > div:not(last-child) {
    margin-right: 50px;
  } */
  .social-icon {
    transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    display: inline-block;
    cursor: pointer;
    margin-right: 1.875rem;
    &:hover {
      opacity: 0.5;
    }
  }
  .custom-tooltip {
    display: inline-block;
    padding: 5px 0.75rem;
    background-color: $black;
    border-radius: 4px;
    color: $white;
    margin: 0 auto;
    width: auto;
    position: absolute;
  }
}
