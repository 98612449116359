@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.site-footer {
  .site-footer-primary {
    // margin: 0 50px;
    padding-top: 2.5rem;
  }
  color: $warm-black;
  .container {
    padding-top: 2.25rem;
    border-top: 1px solid $color-light-border;
  }
  .sitecore-form--newsletter {
    .container {
      padding: 0;
      border: none;
    }
    .form-title {
      padding-top: 1.5rem;
      font-size: 1rem;
    }
    form {
      p {
        color: $color-darkgray;
      }
    }
  }

  .title {
    letter-spacing: 1.28px;
    text-transform: uppercase;
    color: $color-blue;
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    padding: 1.5rem 0;
  }
  .sub-title {
    font-weight: 600;
    color: $color-lightblue;
    font-size: 13px;
    margin-bottom: 5px;
  }
  .footer-nav {
    border-right: 1px solid $color-light-border;
    @include tablet {
      border-right: 0;
    }
    .footer-nav-block {
      margin-bottom: 1.25rem;
      .title {
        cursor: pointer;
        transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      nav {
        margin-bottom: 1.25rem;
      }
      .last-link {
        margin-bottom: 1.25rem;
      }
    }
    a {
      display: block;
      // text-transform: capitalize;
      color: $color-lightgray !important;
      font-weight: 400;
      transition: $link-transition;
      text-transform: capitalize;
      @include fontrem(14);
      line-height: 0.875rem;
      padding-bottom: 12px;
      font-family: $font-copy;
      word-break: break-all; // For other browsers.
      word-break: break-word; // Non standard for WebKit.
      hyphens: auto;
      @include mobile {
        font-size: 0.75rem;
        line-height: 2.25rem;
      }
      &.sub-title {
        font-weight: 600;
        color: $color-lightblue !important;
        font-size: 13px;
        margin-bottom: 5px;
        letter-spacing: normal;
      }
      &:hover {
        color: $mpg-color-gray-75 !important;
      }
    }
  }
  .footer-rightside {
    padding-left: 2.5rem;
    @include tablet {
      padding-left: 0;
    }
    .container {
      border-top: none;
    }
  }
  .footer-country {
    margin-top: 1.875rem;
    .title {
      padding-top: 5px;
      margin-right: 15px;
    }
    .country {
      color: $color-footergray !important;
      // text-transform: capitalize;
      font-weight: 400;
      text-decoration: underline !important;
    }
    .col {
      display: flex;
    }
  }
  .footer-disclaimer {
    text-align: center;
    padding: 4.625rem 2.5rem 4.688rem;
    margin-top: 3.75rem;
    color: $white;
    background-color: $color-darkgray;
    .container {
      padding-top: 0;
      border-top: none;
    }
    p {
      font-size: 0.75rem;
      line-height: 18px;
      margin: 2rem 0;
    }
  }
  .disclaimer-utility__list {
    a {
      position: relative;
      display: inline-block;
      margin-left: 0.625rem;
      font-size: 0.75rem;
      // text-transform: capitalize;
      color: $white;
      font-weight: 400;
      letter-spacing: normal;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '|';
        display: inline-block;
        margin-left: 0.625rem;
      }
      &:hover {
        color: $mpg-color-gray-25;
      }
    }
  }
  .icon-container {
    img {
      // height: 70px;
      // width: 70px;
      margin-bottom: 35px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 35px;
  }
  .disclaimer-logo__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    .disclaimer-logo__item {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: unset;
      img {
        display: block;
        margin: 0;
        height: auto;
        max-width: 110px;
      }
      &--1 {
        flex: 0 1 100%;
        margin-bottom: 1.5rem;
        img {
          max-width: 110px;
        }
      }
    }
    &--secondary {
      margin-bottom: 48px;
      .disclaimer-logo__item {
        margin: 0 15px;
        @include mobile {
          flex: 0 1 100%;
          justify-content: center;
        }
        img {
          margin-right: 0.625rem;
        }
        &--1,
        &--2,
        &--3,
        &--4,
        &--5 {
          @include mobile {
            margin-bottom: 2.25rem;
            margin-right: 0;
          }
        }
        &--1 {
          img {
            max-width: 110px;
          }
        }
        &--2 {
          img {
            max-width: 74px;
          }
        }
        &--3 {
          img {
            max-width: 48px;
          }
        }
        &--4 {
          img {
            max-width: 55px;
          }
        }
        &--5 {
          img {
            max-width: 58px;
          }
        }
      }
    }
  }
  .newsletter {
    p {
      margin-top: 0;
    }
  }
  .footer-bottom-links {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px solid #fff;
    @include tablet {
      @include justify-content(center);
    }
    p {
      margin: 0;
    }
    .copyright {
      text-align: left;
      @include tablet {
        order: 3;
        text-align: center;
        margin-top: 2rem;
      }
    }
    .social-follow {
      text-align: center;
      @include tablet {
        margin-bottom: 2rem;
      }
    }
    .footer-lang-selector {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);
      @include tablet {
        @include justify-content(center);
      }
    }
  }
  .footer-social {
    .col {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      @include tablet {
        justify-content: center;
      }
    }
    .title {
      display: none;
      margin-right: 1.25rem;
      white-space: nowrap;
      padding-left: 5px;
    }
    .social-bar {
      white-space: nowrap;
      .social-icon {
        margin-right: 3rem;
        img {
          max-height: 1.875rem;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .newsletter {
    p {
      margin-top: 0;
    }
  }
  .applinks {
    margin-top: 32px;
    .applink-one {
      margin-right: 20px;
      @include mobile {
        margin-right: 0px;
      }
    }
    .apps-image {
      width: 100%;
      height: auto;
      max-width: 10rem;
    }
    .applink-two {
      @include mobile {
        margin-left: 20px;
      }
    }
    .apps-tablet {
      @include tablet {
        justify-content: center;
      }
    }
    .trust-seal {
      justify-content: end;
      @include tablet {
        justify-content: center;
        margin-top: 24px;
      }
      @include mobile {
        justify-content: center;
        margin-top: 24px;
      }
    }
  }
}
