@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/colors';

.story-insights {
  border-radius: 14px;
  padding: 2rem !important;
  margin-top: 2rem;

  .title {
    font-family: $font-helvetica-med;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    max-width: 185px;
    max-height: 34px;
    padding: 8px 4px;
    color: $white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .icon {
      padding: 5px;
      width: 26px;
      height: 26px;
    }
  }

  .items-container {
    display: flex;
    flex-direction: column;
    padding-left: 1.25rem;
    margin-bottom: 0;
    font-family: $font-copy;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;

    .item {
      color: $text-body;
      padding: 24px 0px;
      line-height: 1.5;
      border-bottom: 1px solid $color-darkgray-50;
      font-family: $font-copy;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      strong {
        display: inline;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  @media (max-width: 575px) {
    border-radius: 0px;
    padding-top: 1.875rem;

    .title {
      text-align: center;
    }

    .items-container {
      padding-left: 2rem;
      .item {
        padding: 4px 4px 8px 4px;
        border-bottom: 1px solid $color-lightgray;
      }
    }
  }
}
