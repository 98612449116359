@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/theme';

@keyframes move {
  0%,
  25% {
    transform: translateX(-100%);
    left: 0%;
  }
  75%,
  100% {
    transform: translateX(0);
    left: 100%;
  }
}

.homeblock--stat {
  position: relative;
  background-color: $white;
  min-height: 100%;
  display: flex;
  align-items: center;
  color: $color-darkgray;
  .statblock__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @extend .homestatblock-bgimage;
    &.bg-image-default {
      background-image: url('../../../assets/images/icon-x-two.svg');
    }
    @include mobile {
      background-position: center;
    }
  }

  .statblock__content {
    width: 100%;
    z-index: 2;
    padding: 75px 0;
    // animation: 2s linear 0s 1 alternate move;
    @include mobile {
      padding: 75px 0 45px;
    }
    .statblock__heading {
      font-family: $font-heading;
      font-weight: 600;
      font-size: 1.25rem;
      margin-top: -15px;
      line-height: 1.3;
      text-transform: uppercase;
      letter-spacing: 1.125px;
      transition: 1s ease-in-out;
      margin-bottom: 1rem;
      @include desktop {
        margin-top: 0;
      }
    }
    .statblock__container {
      display: flex;
      align-items: center;
      transition: 1s ease-in-out;
      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .statblock__count {
        font-size: 7.25rem;
        font-family: $font-heading;
        font-weight: 600;
        line-height: 116px;
      }
      .statblock__title {
        @include heading(1);
        font-size: 2.75rem;
        line-height: 48px;
        padding-left: 1rem;
        margin-bottom: 0px;
        padding-bottom: 0.625rem;
        @include mobile {
          line-height: 1;
        }
      }
    }
    .statblock__text {
      transition: 1s ease-in-out;
      p {
        font-size: 1.25rem;
        line-height: 1.3;
        margin-top: 0;
        margin-bottom: 32px;
        @include tablet {
          margin-top: 0;
        }
        @include mobile {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
    }
    .statblock__btn {
      transition: 1s ease-in-out;
      display: flex;
      flex-direction: column;
      width: fit-content;
      .button {
        font-size: 20px;
        line-height: 20px;
        &:first-child {
          margin-bottom: 15px;
        }
        &.statblock__btn--reversed {
          background-color: $color-mangor-orange;
          border-color: $color-mangor-orange;
          color: $white !important;
          &:hover {
            background-color: $color-orange;
            border-color: $color-orange;
            color: $white !important;
          }
        }
      }
    }
  }
}
