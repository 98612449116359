.insights-block-container {
  .content-wrapper {
    padding: 0px;
  }
  @media (max-width: 575px) {
    .container-xl {
      padding: 0px;
    }
    .content-wrapper {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
