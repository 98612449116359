@import 'styles/scss/fonts';
@import 'styles/scss/colors';
@import 'styles/scss/mixins';
@import 'styles/scss/variables';
@import 'styles/scss/base';
@import 'styles/scss/buttons';
@import 'styles/scss/links';

.main-container {
  height: calc(100vh - 96px);
  overflow-x: hidden;
  overflow-y: auto;

  @include tablet {
  height: calc(100vh - 65px);
  }

  a:hover {
    text-decoration: none;

    .arrow-icon {
      transform: translateX(5px);
    }
  }

  .common-hidden {
    display: none;
  }

  img {
    height: auto;
  }

  .common-hidden {
    display: none;
  }

  .hidden-header {
    visibility: hidden;
  }
}

.not-found-container {
  text-align: center;
  height: 80vh;
  margin-top: 10vh;
  font-family: $font-copy;

  h1 {
    font-family: $font-heading;
    color: $color-maroon;
  }
}

.hide_outline {
  *:focus {
    outline: none;
  }
}

.seperatorLine {
  border-top: 2px solid $color-iron;
}

.init-loader-text {
  font-family: $font-heading;
  letter-spacing: 0.15rem;
  color: $color-lightgray;
}

.bg {
  &--img {
    background-color: transparent;
  }
  &--darkgray {
    background-color: $mpg-color-gray-100;
  }
  &--blue {
    background-color: $mpg-color-blue-100;
  }
  &--green {
    background-color: $mpg-color-green-100;
  }
  &--lightblue {
    background-color: $color-lightblue-100;
  }
  &--lightgray {
    background-color: $mpg-color-gray-25;
  }
  &--orange {
    background-color: $mpg-color-orange-75;
  }
  &--purple {
    background-color: $mpg-color-violet-75;
  }
  &--red {
    background-color: $mpg-color-red-100;
  }
  &--white {
    background-color: $white;
  }
}

.fg {
  &--blue {
    color: $mpg-color-blue-100;
  }
  &--darkgray {
    color: $mpg-color-gray-100;
  }
  &--white {
    color: $white;
  }
}