@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.insights-breadcrumb-wrapper{
  margin:20px 0px;
}

.insights-breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  background-color: transparent !important;

  li {
    &:last-child {
      min-width: 100px;
      position: relative;
      // top: -2px;
      @include mobile {
        top: 0;
      }
      @include tablet {
        top: 0;
      }

      &:after {
        display: none;
      }
      a {
        &:hover,
        &:focus {
          opacity: 1 !important;
        }
        text-transform: none;
        color: $mpg-color-gray-50 !important;
        cursor: initial;
        letter-spacing: normal;
        text-decoration: none;
        @include mobile {
          max-width: 25ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    a,
    .breadcrumb-link {
      @include heading(5);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      color: $color-blue !important;
      letter-spacing: 0.72px;
      .breadcrumb-link:hover {
        cursor: pointer;
      }
      &:hover,
      &:focus {
        opacity: 0.6;
      }
      text-transform: none;
    }
    .breadcrumb-link {
      cursor: pointer;
    }
  }
}
