@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/colors';

.card-scroll {
  padding: 4rem 0;
  @include tablet {
    padding: 50px 0;
    .container {
      padding: 0;
    }
  }
  .card-scroll-header {
    display: flex;
    padding: 0 1rem;
    margin-bottom: 28px;
    @include mobile {
      margin-bottom: 1.25rem;
      padding: 0 1.875rem;
    }
    .title {
      font-family: $font-heading;
      font-size: 18px;
      font-weight: 400;
      line-height: 18px;
      text-transform: uppercase;
      letter-spacing: 0.72px;
      flex: 1;
      margin-bottom: 0;
    }
    .cta-textlink .cta-text.blue {
      color: $mpg-color-blue-100;
      &:hover {
        color: $mpg-color-blue-75;
      }
    }
  }
  .slick-slider {
    .slick-list {
      padding: 0 1.875rem 1.25rem 0 !important;
      @include tablet {
        .slick-slide {
          opacity: 0.25;
          transition: 0.25s ease-in-out;
          &.slick-current {
            opacity: 1;
          }
        }
      }
      @include mobile {
        padding: 0 1.875rem 1.25rem 0 !important;
      }
      .slick-track {
        display: flex !important;
      }
      .slick-slide {
        height: inherit !important;
        & > div {
          height: 100%;
          .card {
            height: 100%;
            border-radius: 0.875rem;
            overflow: hidden;
            @include mobile {
              margin: 0 15px;
            }
            &.article {
              .title {
                font-size: 25px;
                line-height: 1.875rem;
              }
              .date {
                @include mobile {
                  font-size: 1.25rem;
                }
              }
              .sub-title {
                @include mobile {
                  margin-bottom: 2rem;
                  font-size: 1.25rem;
                }
              }
            }
          }
        }
      }
    }
    .slick-dots {
      li {
        margin: 0;
        button {
          &:before {
            font-size: 0.75rem;
            color: $mpg-color-gray-50;
            opacity: 1;
          }
          @media (hover: none) {
            &:hover {
              &:before {
                opacity: 0.25;
              }
            }
          }
          &:focus-visible {
            @include outlineFocus();
          }
        }
        &.slick-active {
          button {
            &:before {
              color: $mpg-color-gray-100;
            }
            @media (hover: none) {
              &:hover {
                &:before {
                  opacity: 0.75;
                }
              }
            }
          }
        }
      }
    }
  }
  &.no-padding {
    padding-bottom: 0.625rem;
  }
  &.media-card-scroll {
    padding-bottom: 1.25rem;
    .card-scroll-header {
      flex-direction: column;
      .cookies-msg {
        color: darkgray;
        font-style: italic;
        margin-top: 5px;
      }
    }
  }

  &.connector-card-scroll {
    .card {
      flex-direction: row;
      border: none;
    }
  }
  &.connector {
    overflow: hidden;
    .gradient {
      &__green-blue {
        .card-image {
          @include gradientBorderRight(0.625rem, $linear-gradient-green-blue);
          @include tablet {
            @include gradientBorderBottom(0.625rem, $linear-gradient-green-blue);
            max-width: unset;
            border-radius: 0.875rem 0.875rem 0 0;
          }
        }
      }
      &__blue-violet {
        .card-image {
          @include gradientBorderRight(0.625rem, $linear-gradient-blue-violet);
          @include tablet {
            @include gradientBorderBottom(0.625rem, $linear-gradient-blue-violet);
            max-width: unset;
            border-radius: 0.875rem 0.875rem 0 0;
          }
        }
      }
    }
    .card-body {
      .text {
        p {
          color: inherit;
          margin-top: 0;
          margin-bottom: 1.5rem;
        }
      }
      .cta {
        margin-left: 0px !important;
        a {
          background-color: $color-celestial-blue;
          border-color: $color-celestial-blue;
          color: $white !important;
          border-radius: 2rem;
          padding: 1.125rem 2.125rem 0.875rem;
          line-height: 1.2;
          &:hover {
            background-color: $color-lightblue;
            border-color: $color-lightblue;
          }
        }
      }
    }
    .slick-slider {
      .slick-arrow {
        &.slick-prev {
          top: -3rem;
          right: 2.5rem;
          left: auto;
          &:before {
            display: none;
          }
          &:after {
            display: block;
            content: '';
            color: $white;
            font-size: 0.75rem;
            background-image: url('../../../assets/images/icon-slider-left-blue.svg');
            background-size: cover;
            background-repeat: no-repeat;
            width: 1.75rem;
            height: 1.75rem;
          }
          &:focus-visible {
            @include outlineFocus();
          }
        }
        &.slick-next {
          top: -3rem;
          right: 1.25rem;
          &:before {
            display: none;
          }
          &:after {
            display: block;
            content: '';
            color: $white;
            font-size: 0.75rem;
            background-image: url('../../../assets/images/icon-slider-right-blue.svg');
            background-size: cover;
            background-repeat: no-repeat;
            width: 1.75rem;
            height: 1.75rem;
          }
          &:focus-visible {
            @include outlineFocus();
          }
        }
      }
    }
    .card-image {
      max-width: 400px;
    }
  }
  &.carousel__uberflip {
    .card-scroll-header {
      @include mobile {
        flex-direction: column;
      }
      .title {
        @include mobile {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
