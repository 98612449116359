@import './colors';
@import './fonts';
@import './mixins';
@import './theme';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.button {
  @include button();

  @include mobile {
    padding: 1.125rem 1.125rem 0.875rem;
  }
  .cta-icon {
    margin-left: 0.625rem;
    transition: $btn-transition;
    position: relative;
    display: inline-block;
    background-repeat: no-repeat;
  }
  .spinner-border {
    color: $white;
    margin-right: 11px;
  }
  &:hover {
    opacity: 1;
    .cta-icon {
      transform: translateX(5px);
    }
    .spinner-border {
      color: $white;
      margin-right: 0.625rem;
    }
  }
  &.primary-button {
    &--orange {
      @include ctaButton(
        $ui-primary-button-color,
        $ui-primary-button-color,
        $white,
        $ui-primary-button-color-hover,
        $ui-primary-button-color-hover,
        $white
      );
      &-reversed {
        @include ctaButton(
          $white,
          $white,
          $ui-primary-button-color,
          $ui-primary-button-color,
          $ui-primary-button-color,
          $white
        );
      }
    }
    &--blue {
      //@include ctaButton($bg, $border, $color, $hbg, $hborder, $hcolor)
      @include ctaButton(
        $ui-secondary-button-color,
        $ui-secondary-button-color,
        $white,
        $ui-secondary-button-color-hover,
        $ui-secondary-button-color-hover,
        $white
      );
      &-reversed {
        @include ctaButton(
          $white,
          $white,
          $ui-secondary-button-color,
          $ui-secondary-button-color,
          $ui-secondary-button-color,
          $white
        );
      }
    }
  }
  &.secondary-button {
    &--orange {
      @include ctaButton(
        $white,
        $ui-primary-button-color,
        $ui-primary-button-color,
        $ui-primary-button-color,
        $ui-primary-button-color,
        $white
      );
      &-reversed {
        @include ctaButton(
          transparent,
          $white,
          $white,
          $white,
          $white,
          $ui-primary-button-color
        );
      }
    }
    &--blue {
      //@include ctaButton($bg, $border, $color, $hbg, $hborder, $hcolor)
      @include ctaButton(
        $white,
        $ui-secondary-button-color,
        $ui-secondary-button-color,
        $ui-secondary-button-color,
        $ui-secondary-button-color,
        $white
      );
      &-reversed {
        @include ctaButton(
          transparent,
          $white,
          $white,
          $white,
          $white,
          $ui-secondary-button-color
        );
      }
    }
  }

  .spinner-border {
    margin-right: 0.625rem;
    color: inherit;
  }
  &.search-pill {
    background-color: $white;
    border-color: $color-lightgray-100;
    color: $color-lightgray-100 !important;
    letter-spacing: normal;
    text-transform: none !important;
    text-align: left;
    float: left;
    font-family: $font-copy;
    font-weight: 300;
    font-size: 0.875rem;
    padding: 9px 17px 7px 17px;
    transition: $btn-transition;
    &::after {
      background-size: 8px 8px;
      content: url(../../assets/images/icon_close_pill.svg);
      width: 8px;
      height: 8px;
      margin-left: 8px;
      transition: $btn-transition;
      float: right;
    }
    &:hover {
      background-color: $color-lightgray-100;
      border-color: transparent;
      color: $white !important;
      // font-weight: 600;
      &::after {
        filter: brightness(0) invert(1);
      }
    }
  }
  &-text {
    @include anchor();
    background-color: transparent;
    color: $ui-primary-button-color;
    white-space: nowrap;
    &:hover {
      color: $ui-primary-button-color-hover;
      opacity: 1 !important;
    }

    /* HYPERLINK */
    &.hyperlink {
      border-bottom: 1px solid transparent;
    }
    &--reversed {
      color: $white;
      &:hover {
        color: $white;
        opacity: 0.75 !important;
      }
      &.hyperlink {
        border-color: $white;
      }
    }
    &--blue {
      @include ctaLink($ui-secondary-button-color, $ui-secondary-button-color-hover);
      &-reversed {
        @include ctaLink($mpg-color-blue-25, $mpg-color-blue-00);
      }
    }
    &--darkgray {
      @include ctaLink($color-darkgray-100, $color-darkgray-75);
    }
    &--orange {
      @include ctaLink($ui-primary-button-color, $ui-primary-button-color-hover);
      &-reversed {
        @include ctaLink($mpg-color-orange-25, $mpg-color-orange-00);
        &:hover {
          opacity: 1 !important;
        }
      }
    }
    &--darkblue {
      @include ctaLink($mpg-color-blue-100, $mpg-color-blue-75);
    }

    &.show-btn {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-top: 0.625rem;
    }
    // @extend .themeFontColor;
  }
}

.connector-button {
  display: flex;
  align-items: center;
  text-align: center;
  &.blue {
    .fat-line {
      background-color: $color-blue;
    }
    .line {
      .inner-line {
        height: 2px;
        background-color: $color-blue;
      }
    }
    .primary-button {
      margin-top: 0;
    }
  }
  .fat-line {
    width: 100px;
    height: 7px;
    background-color: $color-orange;
    border-radius: 3px;
  }
  .line {
    flex: 1;
    height: 2px;
    .inner-line {
      height: 2px;
      background-color: $color-orange;
      width: 100%;
    }
  }
  button {
    letter-spacing: 1.2px;
    border: 2px solid $color-blue;
    background-color: transparent;
    color: $color-blue;
    font-family: $font-heading;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 1;
    border-radius: 1.625rem;
    padding: 15px 1.25rem;
    // text-transform: uppercase;
    transition: $btn-transition;
    white-space: nowrap;
    &.blue {
      color: $color-blue;
    }
    &.dark-gray {
      color: $color-darkgray;
    }
    &.light-gray {
      color: $color-lightgray;
    }
    &.reversed {
      color: $white;
    }

    &:hover {
      background-color: $color-blue;
      color: $white;
      .cta-icon {
        // @include arrow-Right(white);
        transform: translateX(5px);
      }
    }
    .cta-icon {
      // @include arrow-Right(blue);
      top: 2px;
      @include mobile {
        width: 17px !important;
        height: 0.625rem !important;
        background-size: 17px 0.625rem !important;
        top: 0;
      }
    }
  }
}

.primary-button {
  &.btn-with-icon {
    * {
      font-family: $font-heading;
      font-size: 1rem;
      @include mobile {
        font-size: 0.75rem;
      }
    }
    .button-icon {
      background-size: 1.75rem 21px;
      transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      position: relative;
      background-repeat: no-repeat;
      margin-right: 0.625rem;
      display: inline-block;
      width: 1.75rem;
      height: 21px;
      vertical-align: bottom;
      &.text-icon {
        background-image: url('../../assets/images/icon_text.svg');
      }
      &.email-icon {
        background-image: url('../../assets/images/icon_email_orange.svg');
      }
    }
    &:hover {
      .text-icon {
        background-image: url('../../assets/images/icon_text_white.svg');
      }
      .email-icon {
        background-image: url('../../assets/images/icon_email_white.svg');
      }
    }
  }
}
