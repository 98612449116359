.margin-right-40 {
  margin-right: 40px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.desktop-only {
  display: block;
  @include tablet {
    display: none !important;
  }
}
.mobile-only {
  display: none;
  @include mobile {
    display: block;
  }
}
.d-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-start {
  justify-content: flex-start;
}

.candidate-flow {
  .title {
    font-family: $font-heading;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    color: $color-darkgray;
    text-align: center;
    margin-bottom: 1rem;
    @include mobile {
      font-size: 27px;
    }
  }
  .text {
    font-family: $font-copy;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.4;
    color: #282a32;
    margin: 0 0 0.75rem 0;
    @include mobile {
      font-size: 1rem;
    }
    &.text__large {
      font-size: 1.25rem;
      text-align: center;
      @include mobile {
        font-size: 1rem;
      }
    }
  }
  .text-value {
    font-family: $font-copy;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    color: #282a32;
    margin: 0 0 0.75rem 0;
    @include mobile {
      font-size: 15px;
      line-height: 1.375rem;
    }
  }
}

.gradient_BLUE,
.gradientblue {
  $gradient: #345f9a, #1c3e6b;
  @include gradient(180deg, $gradient);
}
.gradient_BLUE .calloutbar-x,
.gradientblue .calloutbar-x {
  opacity: 0.03;
}
.gradient_GREEN .calloutbar-x,
.gradientgreen .calloutbar-x {
  opacity: 1;
}
.gradient_GREEN,
.gradientgreen {
  $gradient: #5c7d70, #355c4c;
  @include gradient(180deg, $gradient);
}
.dark_blue {
  background-color: #30588f;
}
.light_blue {
  background-color: #4c79af;
}
.pale_blue {
  background-color: #466ea5;
}
.light_green {
  background-color: #6e8f82;
}
.dark_green {
  background-color: #4F7264// #355c4c;
}
.bullet-list {
  ul,
  ol {
    margin-top: 0;
    margin-left: 2.5rem;
    margin-bottom: 1.125rem;
    padding: 0;
  }
  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-bottom: 0;
  }
}

.modal {
  filter: blur(0px) !important;
}
