$font-heading: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-copy: Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-helvetica-light: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-helvetica-med: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-helvetica-bold: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
$font-DIN-medium: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;

/* Inter */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/inter-v12-latin-500.woff2') format('woff2');
}

/* INTER SemiBold 600*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/inter-latin-600-normal.woff2') format('woff2');
}

/* INTER 700*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/inter-latin-700-normal.woff2') format('woff2');
}

/* INTER 400*/
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/inter-latin-400-normal.woff2') format('woff2');
}
