@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.navbox-section {
  text-align: center;
  padding: 2.5rem 0 4rem;

  .sub-title {
    margin-bottom: 2.5rem !important;
  }
  .navbox-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .navbox {
    border-radius: 0.875rem;
  }
  .title {
    @include heading(2);
    text-align: center;
    margin-bottom: 20px !important;
  }
  .button {
    margin-top: 50px;
    &.white {
      background-color: $white !important;
      border-color: $white !important;
      color: $mpg-color-gray-100 !important;
    }
    &.blue {
      background-color: $mpg-color-blue-50 !important;
      border-color: $mpg-color-blue-50 !important;
      color: $white !important;
      &:hover {
        background-color: $mpg-color-blue-75 !important;
        border-color: $mpg-color-blue-75 !important;
      }
    }
  }
  &.gradient__green-blue {
    @include gradient(90deg, $linear-gradient-green-blue);
  }
}
.navbox-section .title,
.navbox-section .sub-title {
  color: inherit;
  margin: 0;
}
.navbox {
  position: relative;
  display: block;
  width: 12.5rem;
  height: 12.5rem;
  margin: 0 0.75rem 2rem;
  background-color: $white;
  cursor: pointer;
  box-shadow: 3px 8px 16px 0px rgba($color-darkgray, 0.16),
    0px 4px 4px 0px rgba($black, 0.25);

  &:hover,
  &:focus {
    box-shadow: 3px 8px 20px 0 rgba($black, 0.08);
  }

  .card-figure {
    display: flex;
    @include justify-content(center);
    @include align-items(center);
    @include flex-direction(column);
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2.625rem 0.5rem;
    @include tablet {
      flex-direction: column;
      align-items: center;
    }
    .card-icon {
      width: auto;
      height: auto;
      max-height: 40%;
      margin: 0 auto;
      margin-bottom: 1rem;
    }
    .caption {
      @include heading(6);
      font-size: 1.25rem;
      font-weight: 600;
      color: $color-darkgray;
      line-height: 1.3;
      text-transform: capitalize;
      letter-spacing: initial;
      margin-bottom: auto;
    }
  }
}
