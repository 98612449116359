@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

.video-block {
  padding: 4rem 0;
  @include mobile {
    padding: 3rem 0;
  }
  .container {
    .video-text-content {
      .headline {
        text-align: center;
      }
      .text {
        text-align: center;
        margin: 0;
        @include mobile {
          max-width: 100%;
        }
      }
    }
    .row {
      align-items: center;
      &.text-to-left {
        flex-direction: row-reverse;
        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
        }
      }
      .video-text-content {
        &.single-vid {
          .headline {
            text-align: left;
            @media screen and (max-width: 767px) {
              margin-top: 0.625rem;
            }
          }
          .text {
            text-align: left;
            margin: 1.5rem 0 0;
          }
        }
      }
    }
  }
  .video-cont {
    margin: 0 auto;
  }
  .img-container {
    text-align: center;
    max-width: 80%;
    margin: auto;
    vertical-align: middle;
    img {
      width: 25%;
      @include mobile {
        width: 40%;
      }
    }
  }
  &.bknd {
    background-size: cover;
    background-repeat: no-repeat;

    // opacity: 0.65;
    @include mobile {
      background-size: cover;
    }
  }

  .text-company {
    font-size: 1.25rem;
    font-family: $font-copy;
    text-align: center;
    max-width: 80%;
    margin: 2.5rem auto;
    @include mobile {
      font-size: 1rem;
      max-width: 100%;
      margin: 1.25rem auto;
      margin-bottom: 1.25rem;
    }
  }

  .video__link {
    display: block;
    margin: 1.5rem 0;
    cursor: pointer;
    position: relative;
  }
  .video__preview {
    width: 100%;
  }
  .video__duration {
    position: absolute;
    bottom: 0.625rem;
    right: 0.625rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: $white;
    letter-spacing: normal;
    background-color: $black;
    padding: 0.25rem 0.5rem;
  }
  .video__title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: $font-helvetica-med;
  }
  .cta__wrap {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .btn__wrap {
    display: flex;
    justify-content: center;
    a {
      font-size: 1rem;
      font-family: $font-heading;
    }
  }
  .cta_link {
    display: flex;
    justify-content: left;
    text-transform: uppercase;
    text-decoration: underline;
    margin: 1.5rem 0 0;
  }
  .button-text--large .cta-text {
    margin-top: 3rem;
    @include mobile {
      margin-top: 2rem;
    }
  }
}
