@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';
@import '../../../../styles/scss/theme';

.article-block,
.details-block {
  &.press {
    padding: 4.5rem 0 6rem;
    padding-bottom: 2.625rem;

    @include mobile {
      padding: 1.875rem 0;
    }

    .breadcrumb {
      margin-bottom: 2rem;
      padding-left: 0;
      background: transparent;
    }

    .article-header {
      .title {
        @extend .articleBlockHeadingColor;
        @include heading(2);
        word-wrap: break-word;
        font-size: 2rem;
        line-height: 38px;
        color: $color-blue;
        font-weight: 600;
      }

      .sub-title {
        @include heading(4);
        font-size: 1.25rem;
        line-height: 1.3;
        font-weight: 400;
        margin: 0.75rem 0 0;
      }
    }

    .article-header-container {
      .breadcrumb {
        li {
          &:last-child {
            position: relative;
            top: 0;

            @include mobile {
              top: 0;
            }

            @include tablet {
              top: 0;
            }

            &:after {
              display: none;
            }

            a {
              color: $color-indicator !important;
              cursor: text !important;
            }
          }

          &:after {
            position: relative;
            top: -2px;

            @include mobile {
              top: 0;
            }

            @include tablet {
              top: 0;
            }

            display: inline-block;
            content: '>';
            color: $color-light-body;
            font-family: $font-heading;
            font-size: 1.125rem;
            padding: 0 0.5rem;
          }

          a,
          .breadcrumb-link {
            color: $color-blue !important;
            text-transform: uppercase;
            line-height: 1 !important;
            text-decoration: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
          }
        }
      }
    }

    .single-article {
      padding-top: 1.125rem;
      border-top: 1px solid #282a32;
      margin-top: 2rem;
      .content {
        img {
          display: none;
        }
      }

      h4 {
        margin-bottom: 1.25rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        margin: 1.875rem 0;

        @include mobile {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }

        &.large {
          font-size: 1.125rem;
          line-height: 1.75rem;

          @include mobile {
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }

        a {
          text-transform: none;
          font-family: $font-copy;
          font-size: 1rem;
          font-weight: 600;
          letter-spacing: 0;
          color: $color-orange;
        }
      }

      .responsive {
        width: 100%;
        margin-bottom: 3.125rem;
        height: auto;
      }

      ol,
      ul {
        padding-top: 0.625rem;

        li {
          font-size: 1rem;
          line-height: 1.5rem;
          margin-bottom: 0.25rem;

          @include mobile {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }
      }
    }
  }
}

.details-rich-text {
  word-wrap: break-word;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .large {
    img {
      max-height: 10rem;
      width: auto;
    }
  }
}

.details-rich-text iframe {
  width: 100%;
  height: 25rem;

  @include desktop {
    width: 35rem;
    height: 25rem;
  }

  @include mobile {
    width: 100%;
  }

  @include tablet {
    width: 100%;
  }
}
