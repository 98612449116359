.location-search {
  .search-icon {
    border: none;
    background: url(../../../../assets/images/icon-location-man-green-pinpoint.svg);
    position: absolute;
    right: 1.125rem;
    top: 1.125rem;
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
    background-repeat: no-repeat;
  }
  input::-ms-clear {
    display: none;
  }
  .input-pin-icon-wrap {
    width: 0.625rem;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    .input-pin-icon {
      background: url(../../../../assets/images/icon-location_pinpoint-gray.svg);
      width: 100%;
      height: 100%;
      display: block;
      background-size: contain;
    }
  }
  .active {
    .input-pin-icon-wrap {
      width: 8px;
      height: 0.75rem;
    }
  }
  .form-block {
    input {
      padding-right: 1.875rem;
      text-overflow: ellipsis;
    }
  }
}
