@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';

.dp-down {
  .dropdown-menu {
    margin-top: 0.625rem !important;
    .dropdown-item {
      span {
        padding-right: 5px;
      }
      a {
        padding: 0 5px;
        color: $color-blue;
        font-family: $font-copy;
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
  .item-label {
    font-size: 1.5rem;
    line-height: 30px;
    font-family: $font-copy;
    margin-bottom: 1.5rem;
    @include mobile {
      font-size: 1.25rem;
    }
  }
  h2 {
    outline: none;
  }
  .dp-down .form-control {
    width: 300px;
  }
  #clear {
    position: relative;
    float: right;
    height: 1.25rem;
    width: 1.25rem;
    top: -37px;
    right: 5px;
    color: white;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    background-image: url('../../../../assets/images/icon__close--black.svg');
    background-repeat: no-repeat;
    background-color: white;
    border: none;
  }
  #search {
    position: relative;
    float: right;
    height: 1.25rem;
    width: 1.25rem;
    top: -37px;
    right: 0.625rem;
    color: white;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    background-image: url('../../../../assets/images/icon-search_mag_glass-left.svg');
    background-repeat: no-repeat;
    background-color: white;
    border: none;
  }
  .input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .floating-label {
    margin-top: -25px;
    font-size: 0.75rem;
    @include mobile {
      margin-top: -20px;
    }
  }
  .no-input {
    margin-top: -10px;
    font-size: 1.25rem;
  }

  .form-control {
    height: 64px;
    font-size: 1.25rem;
  }
  .no-data {
    margin: 0px;
    padding: 5px;
    font-weight: 400;
  }
  button {
    outline: none !important;
    &:focus-visible {
      outline: 5px auto -webkit-focus-ring-color !important;
    }
  }
}

div.fade.fullscreen.modal {
  z-index: 1100;
  padding-left: 0px !important;
  @include small-desktop {
    div.modal-body {
      right: -17px;
    }
  }
}

// .modal-backdrop.show {
//   opacity: 0 !important;
// }

.modal-content .modal-header {
  .container .close {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-family: $font-heading;
    cursor: pointer;
    background-image: url('../../../../assets/images/icon__close--black.svg');
    background-repeat: no-repeat;
    background-position: 100%;
    transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  button.close {
    display: none;
  }
}
