@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';

$user-icon-active: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0yMi40MjQgMThhLjU5Ny41OTcgMCAwIDAtLjQwNy4xNjJsLTMuMzM5IDMuNDQ1LTEuNzEyLTEuNzdhLjU4NC41ODQgMCAwIDAtLjM5OC0uMTYyLjU3OC41NzggMCAwIDAtLjU2OC41ODFjMCAuMTU0LjA2LjMuMTYxLjQwMmwyLjExOSAyLjE4YS41ODQuNTg0IDAgMCAwIC4zOTguMTYyYy4xNTMgMCAuMjk3LS4wNi40MDctLjE2MmwzLjczNy0zLjg0N0EuNjc3LjY3NyAwIDAgMCAyMyAxOC41OWEuNTg3LjU4NyAwIDAgMC0uNTc2LS41OXoiIGlkPSJhIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTS4wMDQgMjAuMzM0Yy4wNTMtLjQwNC4wOTQtLjgxMi4xNjQtMS4yMTEuNTM3LTIuOTM1IDIuMDQ3LTUuMjMgNC40NDItNi45NTcgMS42NTcgMS41OTUgMy42MSAyLjQzNiA1Ljg5IDIuNDMxIDIuMjcyIDAgNC4yMi0uODMyIDUuODk0LTIuNDQuMzMyLjI2Ny42OC41MjEgMSAuODA5IDIuMDE5IDEuODE5IDMuMjA4IDQuMDg4IDMuNTMyIDYuODE0LjAyLjE4My4wNS4zNy4wNzQuNTU0VjIxSDBjLjAwNC0uMjIuMDA0LS40NDUuMDA0LS42NjZ6IiBmaWxsPSIjMjgyQTMyIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNMTAuOTczIDBjLjQuMDguODA3LjEzIDEuMTk1LjI0MSAyLjM3Ni42NjEgNC4xMzQgMi43NjIgNC4zOCA1LjIxOGE2LjA4MiA2LjA4MiAwIDAgMS01LjU4NyA2LjY3OGMtMy4wODUuMjU4LTUuOTI0LTEuOTM4LTYuNDQ3LTQuOTg4QzMuOTUyIDMuODg5IDUuOTkxLjg1IDkuMjI2LjEzNWMuMjYyLS4wNi41My0uMDkxLjc5Ni0uMTM1aC45NXoiIGZpbGw9IiMyODJBMzIiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxjaXJjbGUgZmlsbD0iIzFEQkMxQiIgY3g9IjE5LjUiIGN5PSIyMC41IiByPSI2LjUiLz48dXNlIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIuNTIiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI2EiLz48L2c+PC9zdmc+);
// Manpower Theme Site Header Component Overrides
$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.site-header {
  width: 100vw;
  &.transparent {
    // Unique Homepage Class
    background-color: transparent;
    box-shadow: none;
    .site-header-bar .site-logo {
      display: none;
    }
    .site-header-bar .site-logo--white {
      display: block;
      width: 160px;
      position: relative;
      bottom: 5px;
      @include mobile {
        width: 130px;
      }
    }
    .site-header-bar .main-nav__list .main-nav__item {
      @include desktop {
        margin: 0 0 0 1.75rem !important;
      }
      &.hamburger {
        .hamburger-menu-toggle {
          .line1,
          .line2,
          .line3 {
            background-color: $white;
          }
        }
      }
      &.logged-in {
        .main-nav-dropdown {
          z-index: 1;
          .main-nav-login-block {
            display: none;
          }
          .main-nav-user-block {
            display: block;
          }
        }
        .user-icon {
          background-image: url('../../../assets/images/icon__user--white-active.svg');
          width: 1.625rem;
        }
      }
    }
    .site-header-bar .main-nav__list .main-nav__item .sub-nav__item a {
      color: $color-darkgray !important;
      span {
        &:after {
          background-color: $white;
        }
        .icon-search {
          background-image: url('../../../assets/images/icon__search--white.svg');
        }
      }
      &.sub-nav__link--highlighted {
        text-shadow: 0px 0px 1px $color-orange;
        color: $color-orange !important;
      }
    }
    .site-header-bar .main-nav__list .main-nav__item a {
      color: $white !important;
      span {
        &:after {
          background-color: $white;
        }
        .icon-search {
          background-image: url('../../../assets/images/icon__search--white.svg');
        }
      }
    }
    .site-header-bar .main-nav__list .main-nav__item .icon-search {
      background-image: url('../../../assets/images/icon__search--white.svg');
      position: relative;
      right: 3px;
    }
    .site-header-bar .main-nav__list .main-nav__item .icon-location {
      background-image: url('../../../assets/images/icon__location-pin--white.svg');
      position: relative;
      right: 3px;
    }
    .site-header-bar .main-nav__list .main-nav__item .user-icon {
      background-image: url('../../../assets/images/icon__user--white.svg');
      position: relative;
      top: 3px;
      left: 2px;
    }
    .site-header-bar .lang-nav {
      @include mobile {
        display: none;
      }
      display: flex;
      .title {
        color: $white;
        &:before {
          background-image: url('../../../assets/images/icon__globe--white.svg');
        }
      }
      .link_title,
      .link_title a {
        color: $white;
      }
      button {
        color: $white !important;
        &::after {
          background-image: url('../../../assets/images/icon__caret--white.svg');
        }
      }
    }
    .site-header-bar .main-nav__list {
      .footer-country {
        display: block;
        position: absolute;
        z-index: 10;
        top: 5px;
        right: 0;
        margin-top: 0;
        margin-right: 0;
        @include mobile {
          display: none;
        }
        .title {
          display: inline;
          font-family: $font-heading;
          font-size: 0.75rem;
          padding: 0;
          margin-right: 5px;
          font-weight: 400;
          color: $white;
          &:before {
            content: '';
            position: relative;
            top: 2px;
            margin-right: 4px;
            background-image: url('../../../assets/images/icon__globe--white.svg');
            background-size: 13px 13px;
            display: inline-block;
            width: 13px;
            height: 13px;
          }
        }
        .country {
          display: inline;
          font-family: $font-heading;
          font-size: 0.75rem;
          color: $white !important;
          font-weight: 400;
          letter-spacing: normal;
          text-transform: initial;
          text-decoration: none !important;
          &:after {
            content: '';
            position: relative;
            margin-left: 8px;
            background-image: url('../../../assets/images/icon__caret--white.svg');
            background-size: 0.75rem 6px;
            display: inline-block;
            width: 0.75rem;
            height: 6px;
          }
        }
      }
    }
  }
  .site-header-bar {
    width: 100%;
    @include mobile {
      flex-wrap: nowrap;
      margin: 0;
    }
    .site-logo--white {
      display: none;
    }
    .site-logo {
      display: block;
      position: relative;
      height: 100%;
      @include mobile {
        width: 125px;
      }
      @include desktop {
        width: 200px;
      }
    }

    .mobl-logo-header {
      display: block;
      width: 200px;
      @include mobile {
        width: 135px;
      }
      @include desktop {
        width: 200px;
      }
      @media (min-width: 1024px) and (max-width: 1200px) {
        width: 110px;
        margin-top: 20px;
      }
    }

    .col-auto,
    .col {
      // Override BS style for mobile only for better alignment
      @include mobile {
        margin: 0 -15px;
      }
    }
    .country-links {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      right: 0;
      z-index: 10;
      top: 0;
      // margin: 4px 0;
      padding-right: 15px;
      .lang-nav {
        display: inline-flex;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      @include tablet {
        display: none;
        margin: -5px 0;
      }
      .language-wrapper {
        display: flex;
        align-items: flex-end;
        border-radius: 0 0 0.625rem 0.625rem;
        border: 1px solid $color-lightgray;
        border-top: 0;
        padding: 0 0.625rem;
      }
      .title {
        display: flex;
        font-family: $font-heading;
        font-size: 0.75rem;
        line-height: 16px;
        padding: 0;
        margin-right: 5px;
        font-weight: 400;
        color: $seo-blue;
        letter-spacing: 0.4px;
        align-self: center;
        &:before {
          content: '';
          position: relative;
          top: 2px;
          margin-right: 8px;
          background-image: url('../../../assets/images/icon__globe.svg');
          background-size: 0.75rem 0.75rem;
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          // align-self: center;
        }
      }
      .link_title {
        margin-right: 1.25rem;
        border-radius: 0 0 0.625rem 0.625rem;
        border: 1px solid $color-lightgray;
        border-top: 0;
        padding: 0 0.625rem;
        a {
          display: flex;
          align-items: flex-start;
          color: $color-lightgray !important;
          font-family: $font-heading;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 16px;
          letter-spacing: 0.4px;
          text-decoration: none !important;
          text-transform: capitalize;
          z-index: 1;
          &:hover {
            color: $color-lightgray-75 !important;
          }
        }
      }
      .country {
        display: flex;
        align-items: center;
        color: $color-blue !important;
        font-weight: 400;
        font-size: 0.75rem;
        letter-spacing: normal;
        text-decoration: none !important;
        text-transform: capitalize;
        z-index: 1;
        background: none;
        border: none;
        margin-top: 0px;
        // margin-top: -3px;
        font-family: $font-heading;
        padding: 0;
        &:after {
          // content: '';
          position: relative;
          //top: 2px;
          margin-left: 8px;
          background-image: url('../../../assets/images/icon__caret.svg');
          background-size: 0.625rem 5px;
          display: inline-block;
          width: 0.625rem;
          height: 5px;
        }
      }
    }
    .main-nav__list {
      float: left;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      @include mobile {
        justify-content: flex-end;
      }
      @include tablet {
        justify-content: flex-end;
      }
      .main-nav__item {
        a {
          font-size: 1rem;
          letter-spacing: normal;
          transition: $link-transition;
          span {
            @include desktop {
              display: inline-block;
              font-size: 1.125rem;
            }
          }
        }
        &.search {
          margin-left: auto;
        }

        &.search-mobl {
          @include mobile {
            margin-left: auto;
          }
        }

        &.main-nav__item--marginLeft {
          margin-left: auto;
        }
        &.blog {
          margin-left: auto;
        }
        &.search--mobile,
        &.login--mobile {
          display: block;
          span {
            display: inline-flex;
            align-items: baseline;
            &:after {
              position: absolute;
              content: '';
              background-color: $color-orange;
              bottom: 2.25rem;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 2px;
              transition: 0.25s ease-in-out;
            }
            .search__text {
              margin-left: 5px;
              position: relative;
              //top: 2px;
            }
            .search__text-mobl {
              display: none;
              @include mobile {
                display: inline-block;
                margin-left: 5px;
                position: relative;
                bottom: 1.5px;
              }
            }
          }
          @include tablet {
            display: flex;
            align-items: center;
            span {
              &:after {
                display: none;
              }
            }
          }
        }
        &.logged-in {
          span {
            &:after {
              display: none;
            }
          }
        }
        &.login--mobile {
          @include tablet {
            position: relative;
            top: 4px;
            .main-nav-dropdown {
              top: 60px;
            }
          }
        }
        &.search--mobile {
          @include tablet {
            top: 2px;
            margin-left: auto;
          }
          @include mobile {
            .main-nav__item {
              display: block;
            }
            .search__text {
              display: none;
            }
            .main-nav-dropdown {
              top: 65px;
              //width: auto !important;
              //max-width: 290px !important;
            }
          }
          @include tablet {
            .main-nav-dropdown {
              top: 65px;
              //width: auto !important;
              //max-width: 290px !important;
            }
          }
        }
        &.hamburger {
          display: none;
          @include tablet {
            display: block;
            position: relative;
            top: 3px;
            border: none;
            background: none;
            // height: 60px;
            line-height: 65px;
            padding-top: 4px;
            margin-left: 0.5rem;
          }
        }
        .icon-location {
          display: inline-block;
          position: relative;
          transform: translateY(1px);
          width: 11px;
          height: 15px;
          background-image: url('../../../assets/images/icon__location-pin.svg');
          transition: 0.25s ease-in-out;
        }
        &.login {
          .main-nav-dropdown {
            display: block;
          }
          .login__text {
            font-family: $font-heading;
            display: inline-block;
            padding-left: 5px;
            @include tablet {
              display: none;
            }
          }
          &.logged-in {
            .main-nav-dropdown {
              display: inline-block;
            }
            .login__text {
              display: none;
            }
            &.main-nav__item:hover .main-nav-dropdown {
              transform: translateX(-90%) scale(1, 1);

              .sub-nav__arrow {
                left: 88%;
                transition: none;
              }
            }
          }
        }
        &.mobile-login {
          @include mobile {
            display: block;
          }
        }
        .main-nav-dropdown {
          width: 400px;
          @include mobile {
            width: 257px;
          }
          .main-nav-login-block,
          .main-nav-user-block {
            width: 340px; // 400 - 30px padding x2
            .title {
              font-family: $font-helvetica-med;
              font-size: 1.25rem;
            }
            .large {
              color: $color-lightgray;
            }
          }
          .main-nav-user-block {
            .main-nav-user-links {
              margin-bottom: 1.25rem;
              /* a {
                text-transform: uppercase;
              } */
            }
          }
          .navlist {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 200px;
          }
          .navlist__item {
            margin-bottom: 8px;
            a,
            a:visited {
              font-family: $font-copy;
              text-transform: initial;
              line-height: initial;
              font-size: 13px;
              letter-spacing: normal;
              color: $color-darkgray !important; // *TO-un-DO
              &:hover,
              &:focus {
                color: $color-orange !important; // *TO-un-DO
              }
            }
          }
        }
      }
      .footer-country {
        display: none;
      }
    }
    .mobl {
      @include mobile {
        justify-content: flex-start;
      }
      @include mobile-landscape {
        justify-content: flex-end;
      }
    }
    .newsletter {
      display: none;
    }
  }
  .main-nav-slideout {
    background-color: $white;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    // left: 10%;
    margin-top: 65px;
    .mobile-nav-header {
      margin-bottom: 1.25rem !important;
      @include tablet {
        display: flex;
        justify-content: flex-end;
      }
      .close-text {
        margin: 0;
        position: relative;
        bottom: 4px;
        right: 2px;
        border: none;
        background-color: $color-white-shade;
      }
    }
    .navlist__mobile {
      display: none;
      list-style: none;
      padding: 0;
      .navitem__mobile {
        .header-link-active {
          color: $color-orange !important;
        }
        .main-nav-link {
          font-weight: 600;
        }
        border-bottom: 1px solid rgba($color: $color-lightgray, $alpha: 0.3);
        padding: 15px 0;
        &:first-child {
          border-top: 1px solid rgba($color: $color-lightgray, $alpha: 0.3);
        }
        a,
        a:visited {
          font-family: $font-heading;
          text-transform: initial;
          letter-spacing: normal;
          color: $black;
          font-size: 17px;
        }
        &.navitem__mobile--active {
          .navitem__button {
            &:after {
              transform: rotate(180deg);
            }
          }
          .navitem__panel {
            display: block;
          }
        }
      }
      .navitem__button {
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .navitem__title {
          font-family: $font-heading;
          text-transform: initial;
          font-weight: 600;
          color: $black;
          font-size: 17px;
        }
        &:after {
          content: '';
          position: relative;
          background-image: url('../../../assets/images/icon__caret.svg');
          background-size: 0.75rem 1rem;
          display: inline-block;
          width: 0.75rem;
          height: 1rem;
          transition: all 200ms ease-in-out;
        }
        .header-link-active {
          color: $color-orange !important;
        }
        &:focus {
          @include outlineFocus();
          outline-offset: 10px;
        }
      }
      .navitem__panel {
        display: none;
        .navitem__list {
          list-style: none;
          padding: 0;
          margin: 0 0 0.625rem 0;
          .navlist__item,
          .sub-nav__item {
            margin-top: 1.25rem;
            a {
              font-family: $font-copy;
              color: $color-lightgray-100 !important;
              letter-spacing: normal;
              font-size: 0.875rem;
            }
            .sub-nav__link--highlighted {
              color: $color-orange !important;
            }
          }
        }
        .navitem__list .has-sub-nav {
          a {
            color: $color-lightblue !important;
            font-weight: 600;
          }
        }
      }
      .navlink__mobile {
        padding: 0.625rem 0;
        a,
        a:visited {
          font-family: $font-heading;
          text-transform: initial;
          letter-spacing: normal;
          color: $color-darkgray !important;
          font-weight: 400;
          font-size: 1rem;
        }
        &.login {
          margin-top: 1.25rem;
          &:before {
            content: '';
            position: relative;
            top: 2px;
            margin-right: 0.625rem;
            background-image: url('../../../assets/images/icon__user.svg');
            background-size: 0.875rem 0.875rem;
            display: inline-block;
            width: 0.875rem;
            height: 0.875rem;
          }
        }
        &.country {
          .lang-nav {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // margin-top: 2.5rem;
            .language-wrapper {
              display: flex;
              // padding-top: 30px;
              align-items: center;
              .title {
                font-weight: 400;
                padding-right: 5px;
              }
            }
            .title {
              color: black;
              font-weight: 400;
              text-transform: none;
              &:before {
                content: '';
                position: relative;
                top: 1px;
                margin-right: 5px;
                background-image: url('../../../assets/images/icon__globe.svg');
                background-size: 0.875rem 0.875rem;
                background-repeat: no-repeat;
                display: inline-block;
                width: 0.875rem;
                height: 0.875rem;
              }
            }
            .link_title {
              margin: -3px 5px 0 0;
              width: 100%;
              display: none;
              a {
                margin: inherit;
              }
            }
            .label {
              font-family: $font-heading;
              font-size: 1rem;
            }
            .country__name {
              font-family: $font-copy;
              //font-size: 0.875rem;
              margin-left: 5px;
              letter-spacing: normal;
              text-decoration: underline !important;
            }
            button {
              display: flex;
              align-items: center;
              background: none;
              border: none;
              padding: 0;
            }
          }
        }
      }
      @include tablet {
        display: block;
      }
    }
    .close__icon {
      background-image: url('../../../assets/images/icon__close.png');
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: right;
      margin-right: -11px;
    }
  }
}
.mobile--actives {
  display: block;
  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
}

// .manpower {
//   .site-header .site-header-bar .main-nav__list .main-nav__item a span {
//     display: inline-block;
//     font-size: 1rem;
//   }
//   .site-header .site-header-bar .main-nav__list .main-nav__item .main-nav-dropdown {
//     width: 400px;
//     &.max-search-text {
//       @include mobile {
//         // width: 257px;
//         width: 355px;
//         padding-right: 5vw;
//       }
//       @media (max-width: 580px) {
//         width: 131vw;
//         padding-right: 42vw;
//       }
//       @media (max-width: 520px) {
//         width: 130vw;
//         padding-right: 40vw;
//       }
//       @media (max-width: 480px) {
//         width: 120vw;
//         padding-right: 35vw;
//       }
//       @media (max-width: 420px) {
//         width: 108vw;
//         padding-right: 23vw;
//       }
//       @media (max-width: 400px) {
//         width: 107vw;
//         padding-right: 21vw;
//       }
//       @media (max-width: 380px) {
//         width: 102vw;
//         padding-right: 16vw;
//       }
//       @media (max-width: 359px) {
//         width: 119vw;
//         padding-right: 30vw;
//       }
//     }

//     &.med-search-text {
//       @include mobile {
//         width: 355px;
//         padding-right: 5vw;
//       }
//       @media (max-width: 580px) {
//         width: 131vw;
//         padding-right: 49vw;
//       }
//       @media (max-width: 520px) {
//         width: 131vw;
//         padding-right: 46vw;
//       }
//       @media (max-width: 480px) {
//         width: 118vw;
//         padding-right: 37vw;
//       }
//       @media (max-width: 420px) {
//         width: 125vw;
//         padding-right: 35vw;
//       }
//       @media (max-width: 400px) {
//         width: 124vw;
//         padding-right: 32vw;
//       }
//       @media (max-width: 380px) {
//         width: 113vw;
//         padding-right: 27vw;
//       }
//       @media (max-width: 359px) {
//         width: 119vw;
//         padding-right: 30vw;
//       }
//     }

//     &.no-search-text {
//       @include mobile {
//         width: 355px;
//         padding-right: 5vw;
//       }
//       @media (max-width: 580px) {
//         width: 138vw;
//         padding-right: 56vw;
//       }
//       @media (max-width: 520px) {
//         width: 134vw;
//         padding-right: 54vw;
//       }
//       @media (max-width: 480px) {
//         width: 129vw;
//         padding-right: 47vw;
//       }
//       @media (max-width: 420px) {
//         width: 125vw;
//         padding-right: 42vw;
//       }
//       @media (max-width: 400px) {
//         width: 117vw;
//         padding-right: 38vw;
//       }
//       @media (max-width: 380px) {
//         width: 118vw;
//         padding-right: 35vw;
//       }
//       @media (max-width: 359px) {
//         width: 119vw;
//         padding-right: 30vw;
//       }
//     }
//   }
// }

@media (max-width: 359px) {
  .site-header .site-header-bar .main-nav__list .main-nav__item .icon-search {
    transform: translateY(5px) !important;
    width: 1.125rem !important;
    height: 1.125rem !important;
    background-size: 1.125rem 1.125rem;
  }
  .site-header .site-header-bar .main-nav__list .main-nav__item .search__text-mobl {
    display: none !important;
  }
}

// @media (max-width: 768px) {
//   .container {
//     padding-right: 15px !important;
//     padding-left: 15px !important;
//   }
// }
