@import './variables';
@import './colors';
@import './fonts';

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
  background: -webkit-linear-gradient($direction, $list);
  background: -ms-linear-gradient($direction, $list);
  background: -ms-linear-gradient(top, $list);
}
@mixin logoWidth($theme) {
  @if $theme == 'experis' {
    width: 105px;
  } @else if $theme == 'jw' {
    width: 179px;
    @include mobile-small {
      width: 138px;
    }
  } @else if $theme == 'manpower' {
    width: 161px;
  } @else {
    width: 105px;
  }
}
@mixin bg-theme-color($theme) {
  @if $theme == 'experis' {
    background: transparent
      linear-gradient(180deg, #1c3e6b 0%, #345f9a 100%)
      0%
      0%
      no-repeat
      padding-box;
  } @else if $theme == 'jw' {
    background-color: $green;
  } @else if $theme == 'manpower' {
    background: transparent
      linear-gradient(187deg, #5c7d70 0%, #849d93 100%)
      0%
      0%
      no-repeat
      padding-box;
  } @else {
    background-color: $color-blue;
  }
}

@mixin setThemeBgColor($theme) {
  @if $theme == 'experis' {
    background-color: map-get($theme-color, $theme);
  } @else if $theme == 'jw' {
    background-color: map-get($theme-color, $theme);
  } @else if $theme == 'manpower' {
    background: transparent
      linear-gradient(82deg, $green 0%, $pale_green_light 100%)
      0%
      0%
      no-repeat
      padding-box;
  } @else {
    background-color: $color-blue;
  }
}
@mixin setLocalBranchThemeBgColor($theme) {
  @if $theme == 'experis' {
    background-color: $green;
  } @else if $theme == 'jw' {
    // background-color: $dark_green; //#316666;
  } @else if $theme == 'manpower' {
    background: $green linear-gradient(52deg, $green, #849d93 64%);
    // background-image: linear-gradient(52deg, $green, #849d93 64%);
  }
}

@mixin table-header-theme($theme) {
  @if $theme == 'experis' {
    background-color: $color-darkgray;
  } @else if $theme == 'jw' {
    background-color: $green;
  } @else if $theme== 'manpower' {
    background: transparent
      linear-gradient(87deg, $green 0%, #849d93 100%)
      0%
      0%
      no-repeat
      padding-box;
  } @else {
    background-color: $color-darkgray;
  }
}

@mixin navigation-hover($theme) {
  @if $theme == 'experis' {
    background: $color-hover;
  } @else if $theme == 'jw' {
    background: $color-hover-green;
  } @else if $theme == 'manpower' {
    background: $color-hover-green;
  } @else {
    background: $color-hover;
  }
}

@mixin setDashboardBackground($theme) {
  @if $theme == 'experis' {
    background-color: $white;
  } @else if $theme == 'jw' {
    background-color: $white;
  } @else if $theme == 'manpower' {
    background-color: $white;
  } @else {
    background-color: $white;
  }
}
@mixin tip-bg-color($theme) {
  @if $theme == 'experis' {
    background: $color-dark-pastel-blue 0% 0% no-repeat padding-box;
    border: 1px solid $color-light-steel-blue;
  } @else if $theme == 'jw' {
    background: $color-hover-green 0% 0% no-repeat padding-box;
    border: 1px solid $color-hover-green;
  } @else {
    background: $color-dark-pastel-blue 0% 0% no-repeat padding-box;
    border: 1px solid $color-light-steel-blue;
  }
}

@mixin progressbar-bg-color($theme) {
  @if $theme == 'experis' {
    background: $color-chambray;
  } @else if $theme == 'jw' {
    background-color: $green;
  } @else if $theme == 'manpower' {
    background-color: $white;
    .dashboard-progress {
      color: $color-darkgray;
    }
    .dashboard-tip {
      color: $color-darkgray;
    }
    .dashboard-continue {
      top: 4px;
      .primary-button {
        background-color: $white;
        color: $color-blue !important;
        border-color: $color-blue !important;
        padding: 0.75rem 0.875rem 0.75rem 19px;
        font-size: 0.875rem;
        background-color: $white;
        .arrow-icon {
          display: none;
        }
        &:hover {
          .arrow-icon {
            display: none;
          }
        }
      }
    }
  } @else {
    background: $color-chambray;
  }
}

@mixin arrow-Right($color) {
  width: 1.375rem;
  height: 13px;
  background-size: 1.375rem 13px;
  margin-left: 0.625rem;
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  display: inline-block;
  background-repeat: no-repeat;
  @if $color == orange {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS44MDQgOS4zMjRoMjUuMjZsLTUuNDcgNS42MDRhLjgxLjgxIDAgMCAwIDAgMS4xNTRjLjE2MS4xNjUuNDAzLjI0OC41NjQuMjQ4LjE2IDAgLjQwMi0uMDgzLjU2My0uMjQ4bDYuODM4LTcuMDA1YS44MS44MSAwIDAgMCAwLTEuMTU0TDIxLjcyLjkxOGEuNzY1Ljc2NSAwIDAgMC0xLjEyNyAwIC44MS44MSAwIDAgMCAwIDEuMTU0bDUuNDcgNS42MDRILjgwNWMtLjQ4MiAwLS44MDQuMzMtLjgwNC44MjQgMCAuNDk0LjMyMi44MjQuODA0LjgyNHoiIGZpbGw9IiNDMjU3MDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  } @else if $color == orange-70 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfSWNvbnNfQXJyb3ciIGRhdGEtbmFtZT0iQXRvbXMgLyBJY29ucyAvIEFycm93IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQXRvbXNfSWNvbnNfQXJyb3ctMiIgZGF0YS1uYW1lPSJBdG9tcyAvIEljb25zIC8gQXJyb3ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iI2UwNjkwYSIvPgo8L3N2Zz4K);
  } @else if $color == orange-60 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfSWNvbnNfQXJyb3ciIGRhdGEtbmFtZT0iQXRvbXMgLyBJY29ucyAvIEFycm93IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQXRvbXNfSWNvbnNfQXJyb3ctMiIgZGF0YS1uYW1lPSJBdG9tcyAvIEljb25zIC8gQXJyb3ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iI2ZmN2ExMiIvPgo8L3N2Zz4K);
  } @else if $color == orange-50 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfSWNvbnNfQXJyb3ciIGRhdGEtbmFtZT0iQXRvbXMgLyBJY29ucyAvIEFycm93IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQXRvbXNfSWNvbnNfQXJyb3ctMiIgZGF0YS1uYW1lPSJBdG9tcyAvIEljb25zIC8gQXJyb3ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iI2ZmOTQ0MiIvPgo8L3N2Zz4K);
  } @else if $color == orange-25 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfLV9JY29uc18tX0Fycm93IiBkYXRhLW5hbWU9IkF0b21zIC0gSWNvbnMgLSBBcnJvdyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyI+CiAgPHBhdGggaWQ9IkF0b21zX0ljb25zX0Fycm93IiBkYXRhLW5hbWU9IkF0b21zIC8gSWNvbnMgLyBBcnJvdyIgZD0iTS42MTQsNy44NTRoMTkuM2wtNC4xNzksNC42NTJhLjcxNC43MTQsMCwwLDAsMCwuOTU4LjU1My41NTMsMCwwLDAsLjg2MSwwbDUuMjI0LTUuODE1YS43MTQuNzE0LDAsMCwwLDAtLjk1OEwxNi41OTIuODc2YS41NTMuNTUzLDAsMCwwLS44NjEsMCwuNzE0LjcxNCwwLDAsMCwwLC45NThMMTkuOTEsNi40ODZILjYxNUEuNjE2LjYxNiwwLDAsMCwwLDcuMTdhLjYxNi42MTYsMCwwLDAsLjYxNC42ODRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4wMDEgLTAuNjcpIiBmaWxsPSIjZmY3YTEyIi8+Cjwvc3ZnPgo=);
  } @else if $color == orange-00 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfLV9JY29uc18tX0Fycm93IiBkYXRhLW5hbWU9IkF0b21zIC0gSWNvbnMgLSBBcnJvdyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMiAxMyI+CiAgPHBhdGggaWQ9IkF0b21zX0ljb25zX0Fycm93IiBkYXRhLW5hbWU9IkF0b21zIC8gSWNvbnMgLyBBcnJvdyIgZD0iTS42MTQsNy44NTRoMTkuM2wtNC4xNzksNC42NTJhLjcxNC43MTQsMCwwLDAsMCwuOTU4LjU1My41NTMsMCwwLDAsLjg2MSwwbDUuMjI0LTUuODE1YS43MTQuNzE0LDAsMCwwLDAtLjk1OEwxNi41OTIuODc2YS41NTMuNTUzLDAsMCwwLS44NjEsMCwuNzE0LjcxNCwwLDAsMCwwLC45NThMMTkuOTEsNi40ODZILjYxNUEuNjE2LjYxNiwwLDAsMCwwLDcuMTdhLjYxNi42MTYsMCwwLDAsLjYxNC42ODRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4wMDEgLTAuNjcpIiBmaWxsPSIjZmY5NDQyIi8+Cjwvc3ZnPgo=);
  } @else if $color == blue {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS44MDQgOS4zMjRoMjUuMjZsLTUuNDcgNS42MDRhLjgxLjgxIDAgMCAwIDAgMS4xNTRjLjE2MS4xNjUuNDAzLjI0OC41NjQuMjQ4LjE2IDAgLjQwMi0uMDgzLjU2My0uMjQ4bDYuODM4LTcuMDA1YS44MS44MSAwIDAgMCAwLTEuMTU0TDIxLjcyLjkxOGEuNzY1Ljc2NSAwIDAgMC0xLjEyNyAwIC44MS44MSAwIDAgMCAwIDEuMTU0bDUuNDcgNS42MDRILjgwNWMtLjQ4MiAwLS44MDQuMzMtLjgwNC44MjQgMCAuNDk0LjMyMi44MjQuODA0LjgyNHoiIGZpbGw9IiMzMDU4OEYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  } @else if $color == blue-70 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfSWNvbnNfQXJyb3ciIGRhdGEtbmFtZT0iQXRvbXMgLyBJY29ucyAvIEFycm93IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQXRvbXNfSWNvbnNfQXJyb3ctMiIgZGF0YS1uYW1lPSJBdG9tcyAvIEljb25zIC8gQXJyb3ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iIzMzOTRkNiIvPgo8L3N2Zz4K);
  } @else if $color == lightblue {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS44MDQgOS4zMjRoMjUuMjZsLTUuNDcgNS42MDRhLjgxLjgxIDAgMCAwIDAgMS4xNTRjLjE2MS4xNjUuNDAzLjI0OC41NjQuMjQ4LjE2IDAgLjQwMi0uMDgzLjU2My0uMjQ4bDYuODM4LTcuMDA1YS44MS44MSAwIDAgMCAwLTEuMTU0TDIxLjcyLjkxOGEuNzY1Ljc2NSAwIDAgMC0xLjEyNyAwIC44MS44MSAwIDAgMCAwIDEuMTU0bDUuNDcgNS42MDRILjgwNWMtLjQ4MiAwLS44MDQuMzMtLjgwNC44MjQgMCAuNDk0LjMyMi44MjQuODA0LjgyNHoiIGZpbGw9IiM3NTkzQkMiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  } @else if $color == darkgrey {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS44MDQgOS4zMjRoMjUuMjZsLTUuNDcgNS42MDRhLjgxLjgxIDAgMCAwIDAgMS4xNTRjLjE2MS4xNjUuNDAzLjI0OC41NjQuMjQ4LjE2IDAgLjQwMi0uMDgzLjU2My0uMjQ4bDYuODM4LTcuMDA1YS44MS44MSAwIDAgMCAwLTEuMTU0TDIxLjcyLjkxOGEuNzY1Ljc2NSAwIDAgMC0xLjEyNyAwIC44MS44MSAwIDAgMCAwIDEuMTU0bDUuNDcgNS42MDRILjgwNWMtLjQ4MiAwLS44MDQuMzMtLjgwNC44MjQgMCAuNDk0LjMyMi44MjQuODA0LjgyNHoiIGZpbGw9IiMyODJhMzIiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  } @else if $color == darkgrey-75 {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iQXRvbXNfSWNvbnNfQXJyb3ciIGRhdGEtbmFtZT0iQXRvbXMgLyBJY29ucyAvIEFycm93IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQXRvbXNfSWNvbnNfQXJyb3ctMiIgZGF0YS1uYW1lPSJBdG9tcyAvIEljb25zIC8gQXJyb3ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iIzVlNWY2NSIvPgo8L3N2Zz4K);
  } @else if $color == lightgreen {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQVJST1ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iIzg1OWQ5NCIvPgo8L3N2Zz4K);
  } @else if $color == maroon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS44MDQgOS4zMjRoMjUuMjZsLTUuNDcgNS42MDRhLjgxLjgxIDAgMCAwIDAgMS4xNTRjLjE2MS4xNjUuNDAzLjI0OC41NjQuMjQ4LjE2IDAgLjQwMi0uMDgzLjU2My0uMjQ4bDYuODM4LTcuMDA1YS44MS44MSAwIDAgMCAwLTEuMTU0TDIxLjcyLjkxOGEuNzY1Ljc2NSAwIDAgMC0xLjEyNyAwIC44MS44MSAwIDAgMCAwIDEuMTU0bDUuNDcgNS42MDRILjgwNWMtLjQ4MiAwLS44MDQuMzMtLjgwNC44MjQgMCAuNDk0LjMyMi44MjQuODA0LjgyNHoiIGZpbGw9IiNDMDcwNzgiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  } @else if $color == lightorange {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDIyIDEzIj4KICA8cGF0aCBpZD0iQVJST1ciIGQ9Ik0uNjE0LDcuODU0aDE5LjNsLTQuMTc5LDQuNjUyYS43MTQuNzE0LDAsMCwwLDAsLjk1OC41NTMuNTUzLDAsMCwwLC44NjEsMGw1LjIyNC01LjgxNWEuNzE0LjcxNCwwLDAsMCwwLS45NThMMTYuNTkyLjg3NmEuNTUzLjU1MywwLDAsMC0uODYxLDAsLjcxNC43MTQsMCwwLDAsMCwuOTU4TDE5LjkxLDYuNDg2SC42MTVBLjYxNi42MTYsMCwwLDAsMCw3LjE3YS42MTYuNjE2LDAsMCwwLC42MTQuNjg0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAxIC0wLjY3KSIgZmlsbD0iI2QxODE0MCIvPgo8L3N2Zz4K);
  } @else {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS44MDQgOS4zMjRoMjUuMjZsLTUuNDcgNS42MDRhLjgxLjgxIDAgMCAwIDAgMS4xNTRjLjE2MS4xNjUuNDAzLjI0OC41NjQuMjQ4LjE2IDAgLjQwMi0uMDgzLjU2My0uMjQ4bDYuODM4LTcuMDA1YS44MS44MSAwIDAgMCAwLTEuMTU0TDIxLjcyLjkxOGEuNzY1Ljc2NSAwIDAgMC0xLjEyNyAwIC44MS44MSAwIDAgMCAwIDEuMTU0bDUuNDcgNS42MDRILjgwNWMtLjQ4MiAwLS44MDQuMzMtLjgwNC44MjQgMCAuNDk0LjMyMi44MjQuODA0LjgyNHoiIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
  }
}

@mixin placeholder-text($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin mobile-small {
  @media (max-width: #{$mobile-width-small}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile-plus {
  @media (max-width: #{$mobile-width-plus}) {
    @content;
  }
}

@mixin mobile-large {
  @media (max-width: #{$mobile-width-large}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: #{$mobile-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile-portrait {
  @media (max-width: #{$mobile-width}) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}
@mixin tablet-small {
  @media (max-width: #{$tablet-small}) {
    @content;
  }
}

@mixin tablet-medium {
  @media (max-width: #{$tablet-medium}) {
    @content;
  }
}
@mixin tablet-mini {
  @media (max-width: #{$tablet-mini}) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (max-width: #{$tablet-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (max-width: #{$tablet-width}) and (orientation: portrait) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: #{$desktop-large-width}) and (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop-large-width}) {
    @content;
  }
}
@mixin desktop-small {
  @media (max-width: #{$desktop-small}) {
    @content;
  }
}
@mixin desktop-small-width {
  @media (max-width: #{$desktop-small-width}) {
    @content;
  }
}

@mixin desktop-landscape {
  @media (max-width: #{$desktop-large-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop-portrait {
  @media (max-width: #{$desktop-large-width}) and (orientation: portrait) {
    @content;
  }
}

@mixin desktop-medium {
  @media (max-width: #{$desktop-width-medium}) {
    @content;
  }
}

@mixin mobile-medium {
  @media (max-width: #{$mobile-width-medium}) and (min-width: #{$mobile-width-small}) {
    @content;
  }
}
//covers iphones 6,7,8 and plus
@mixin iphones-medium {
  @media only screen and (min-device-width: $mobile-width-small) and (max-device-height: $medium-iphones-height) and (orientation: portrait) {
    @content;
  }
}

@mixin devices($min, $max) {
  @media (max-width: #{$max}) and (min-width: #{$min}) {
    @content;
  }
}

@mixin ipad {
  @media only screen and (min-device-width: $desktop-small-width) and (max-device-width: $desktop-small-width) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}
@mixin ipad-mini {
  @media only screen and (min-device-width: $desktop-large-width) and (max-device-width: $ipad-mini-width) {
    @content;
  }
}
@mixin ipad-small {
  @media (max-width: #{$ipad-small}) {
    @content;
  }
}
@mixin ipad-medium {
  @media (max-width: #{$ipad-medium}) {
    @content;
  }
}

@mixin devices-with-height($height: 812px, $width: 414px) {
  @media (min-height: #{$height}) and (max-width: #{$width}) {
    @content;
  }
}

@mixin heading($size) {
  font-family: $font-heading;
  font-size: 3rem; /* 48px */
  line-height: 1.25;
  font-weight: 600;
  &.reversed {
    color: $white;
  }
  @if $size == 2 {
    font-size: 1.5rem; /* 24px */
    line-height: 1.25;
    font-weight: 600;
  } @else if $size == 3 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.3;
    font-weight: 400;
  } @else if $size == 4 {
    font-size: 1.125rem; /* 18px */
    line-height: 1.3;
    font-weight: 600;
  } @else if $size == 5 {
    font-size: 1.125rem; /* 18px */
    line-height: 1.3;
    font-weight: 400;
    letter-spacing: 1.125px;
  } @else if $size == 6 {
    font-size: 1rem; /* 16px */
    line-height: 1.5;
    font-weight: 400;
    &.light {
      font-weight: 300;
    }
  } @else if ($size == 10) {
    font-size: 2.375rem;
    line-height: 1.2;
    font-weight: 600;
  }
}

@function pxtorem($size) {
  $remvalue: $size / 16;
  @return $remvalue * 1rem;
}

@mixin fontrem($size) {
  $remvalue: $size / 16;
  font-size: $remvalue * 1rem;
}

@mixin orientation($direction) {
  $orientation-landscape: '(orientation:landscape)';
  $orientation-portrait: '(orientation:portrait)';

  @if $direction == landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }
  @if $direction == portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}

@mixin setThemeSubtitleColor($theme) {
  @if $theme == 'experis' {
    color: $color-cobalt-blue !important;
  } @else if $theme == 'jw' {
    color: $light-green !important;
  }
}

@mixin setThemeHeaderSidebarBgColor($theme) {
  @if $theme == 'experis' {
    background-color: $color-lightblue;
  } @else if $theme == 'jw' {
    background-color: $pale_green;
  }
}
@mixin setHeaderSidebarBottomlineColor($theme) {
  @if $theme == 'experis' {
    border-bottom: 2px solid #6889b6;
  } @else if $theme == 'jw' {
    border-bottom: 2px solid #ffffff;
  }
}

@mixin setHeaderCountryLinkColor($theme) {
  @if $theme == 'experis' {
    color: $color-grayblue;
  } @else if $theme == 'jw' {
    color: $white;
  }
}

@mixin setThemeFontColor($theme) {
  @if $theme == 'experis' {
    color: $color-blue;
  } @else if $theme == 'jw' {
    color: $green;
  } @else if $theme == 'manpower' {
    color: $color-xanadu-green;
  } @else if $theme == 'manpower' {
    color: $green;
  }
}
@mixin home-career-block($theme) {
  @if $theme == 'experis' {
    background-color: $color-teal;
  } @else if $theme == 'jw' {
    background-color: $color-lightblue;
  }
}
@mixin moblogoWidth($theme) {
  @if $theme == 'experis' {
    width: 100px;
  } @else if $theme == 'jw' {
    width: 175px;
  } @else {
    width: 100px;
  }
}

@mixin paddingFix($theme) {
  @if $theme == 'experis' {
    @media screen and (max-width: 992px) and (min-width: 768px) and (orientation: landscape) {
      padding-top: 150px;
    }
    @media screen and (max-width: 570px) and (min-width: 500px) and (orientation: landscape) {
      padding-top: 130px;
    }
  }
}
@mixin home-stat-block-bgimage($theme) {
  @if $theme == 'experis' {
    filter: brightness(10%);
    background-position: right 0px;
  } @else if $theme == 'jw' {
    filter: brightness(1.3);
    background-position: right 50px;
  }
}

@mixin set-border-top($theme) {
  @if $theme == 'manpower' {
    border-top: 3px solid $color-xanadu-green;
  } @else {
    border-top: 3px solid #30588f;
  }
}

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property

@mixin flex($fg: 1, $fs: null, $fb: null) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

@mixin gradientBorderTop($height, $color) {
  border: 0;
  border-top: $height solid;
  border-image-source: linear-gradient(90deg, $color);
  -moz-border-image-source: linear-gradient(90deg, $color);
  border-image-slice: 1;
}

@mixin gradientBorderRight($height, $color) {
  border: 0;
  border-right: $height solid;
  border-image-source: linear-gradient(0deg, $color);
  -moz-border-image-source: linear-gradient(0deg, $color);
  border-image-slice: 1;
}

@mixin gradientBorderLeft($height, $color) {
  border: 0;
  border-left: $height solid;
  border-image-source: linear-gradient(0deg, $color);
  -moz-border-image-source: linear-gradient(0deg, $color);
  border-image-slice: 1;
}

@mixin gradientBorderBottom($height, $color) {
  border: 0;
  border-bottom: $height solid;
  border-image-source: linear-gradient(90deg, $color);
  -moz-border-image-source: linear-gradient(90deg, $color);
  border-image-slice: 1;
}

@mixin cta() {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  border: 0;
  padding: 0;
  text-decoration: none;
  transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@mixin anchor() {
  @include cta();
  font-family: $font-heading;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  line-height: 1;
}

@mixin button() {
  @include anchor();
  align-items: center;
  text-align: center;
  color: $white;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 2rem;
  padding: 1.125rem 2.125rem 0.875rem;
}

@mixin ctaLink($color, $hoverColor) {
  color: $color;
  &:hover {
    color: $hoverColor;
  }
  &.hyperlink {
    border-color: $color;
    &:hover {
      border-color: $hoverColor;
    }
  }
}

@mixin ctaButton($bg, $border, $color, $hbg, $hborder, $hcolor) {
  background-color: $bg;
  border-color: $border;
  color: $color;
  &:hover {
    background-color: $hbg;
    border-color: $hborder;
    color: $hcolor;
  }
}

@mixin card-wipe--apollo($right) {
  .card-wipe--apollo::before {
    position: absolute;
    top: 0;
    right: $right;
    width: 100%;
    height: 100%;
    background: rgba(150, 150, 150, 0.25);
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 220deg)
      translate3d(0, -100%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 220deg)
      translate3d(0, -100%, 0);
  }
}

@mixin outlineFocus() {
  outline: -webkit-focus-ring-color auto 1px;
  outline: -moz-mac-focusring auto 1px;
}
