@import '../../../styles/scss/colors';
@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/theme';

.page-banner-pdf-insights{
.pdf-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EFEFEF;
    padding: 20px;
    border-radius: 28px;
  }
  
  .pdf-container {
    margin-bottom: 20px;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .left-controls, .right-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .control-button {
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    color: #282A32;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border-radius: 58px;
    svg{
        margin-top: -3px;
    }
  }
  


  .control-button:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .control-button.active {
    background-color:#000000;
  }
  
  .download-button, .fullscreen-button {
    margin-left: auto;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    color: #282A32;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border-radius: 58px;
  }

  .right-controls-text{
    margin-left:3px;
  }
  
  .page-info {
    font-size: 16px;
    margin: 0 10px;
  }

  .page-banner-topics-insights, .page-banner-topics-insights > span{
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 12px 0px 24px 0px;
    color: $mpg-color-gray-50;
    font-size: 12px;
    .topics-icon{
      width: 16px;
      height: 16px;
      padding-right:2px
    }
  }


  @include mobile {
    .page-banner-topics-insights, .page-banner-topics-insights > span{
      font-size: 12px;
      margin: 8px 0px 16px 0px;
      .topics-icon{
        width: 12px;
        height: 12px;
      }
    }
    .right-controls-text,.home-pdf-button{
        display: none;
    }

    .right-controls{
        margin-left: 8px;
    }

    .control-button, .download-button, .fullscreen-button{
        padding: 6px 12px;
    }
    .fullscreen-button{
      display: none;
    }

    .page-info{
        font-size: 12px;
    }
  }
}  