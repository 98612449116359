@import '../../../../styles/scss/colors';
@import '../../../../styles/scss/fonts';
@import '../../../../styles/scss/mixins';
@import '../../CardBlocks/cardblock.scss';

// Manpower Theme Card Block Components Overrides
.blog.card-block {
  &.card-article {
    min-height: 20rem;
  }

  &.stat.gradient {
    background-image: linear-gradient(to right, $green, $color-blue-gray);

    .card.stat {
      background-color: transparent;
    }

    .card.stat .stat-line .stat,
    .card.stat p {
      color: $white;
    }
  }

  &.insights {
    overflow: visible !important;

    .container {
      padding: 0 !important;
    }

    .card {
      margin: 0;

      @include mobile {
        margin-bottom: 1.875rem;
      }
    }

    .card-article__cta {
      margin: 0;

      @include mobile {
        margin: 0 1.5rem 1.5rem 1.5rem;
      }

      .card-article--body {
        .title {
          font-family: $font-helvetica-med;
          text-align: left;
          overflow: hidden;
          height: 7rem;

          @include mobile {
            height: unset !important;
          }
        }
      }
    }

    .button-text {
      margin-right: 1.875rem;
    }

    .card .card-body {
      @include mobile {
        padding-bottom: 3.75rem;
      }

      .cta-textlink {
        right: 2.5rem !important;
        width: 100% !important;
        position: absolute !important;
        bottom: 0px !important;
      }
    }

    .card-article--body {
      @include mobile {
        padding: 2.5rem;
      }

      .title {
        font-family: $font-copy;
        text-align: left;
        overflow: hidden;
        height: 7rem;

        @include mobile {
          height: unset !important;
        }
      }
    }
  }

  &.subpage {
    background: $pale-grey;
  }
}
