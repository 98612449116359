@import '../../../styles/scss/mixins';
@import '../../../styles/scss/colors';

.newscard-block {
    position: relative;
    padding-top: 4rem;
    .news-card-header {
        display: flex;
        margin-bottom: 0.125rem;
        @include mobile {
            margin-bottom: 0.125rem;
            padding: 0 1.875rem;
        }

        .title {
            @include heading(5);
            font-size: 1.25rem;
            text-transform: uppercase;
            flex: 1;
            margin-bottom: 0;
        }

        .cta-textlink .cta-text.blue {
            color: $mpg-color-blue-100;
            font-size: 1.125rem;

            &:hover {
                color: $mpg-color-blue-75;
            }
        }
    }

    // .blog {
    //     background-color: $white !important;
    // }
}