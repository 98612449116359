@import '../../../styles/scss/fonts';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/theme';

$gradient: $color-dazzled-blue, $color-darkblue;
$link-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.search-header {
  &.gradient_primary {
    @include gradient(to right, $ui-gradient-primary);
  }
  &.gradient_secondary {
    @include gradient(to left, $ui-gradient-secondary);
  }
  background-color: #efefef;
  padding: 5.5rem 0 3.75rem;
  .search__title {
    .title__text {
      font-family: $font-copy;
      font-size: 1.25rem;
      line-height: 1.3;
      letter-spacing: 1.25px;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include mobile {
        font-size: 1rem;
        letter-spacing: 1.2px;
      }
    }
  }
  .search-everything {
    display: block;
    .button.primary-button {
      font-size: 20px;
      line-height: 20px;
    }
    &.active {
      display: block;

      .primary-button {
        @include tablet {
          margin-top: 20px;
        }
        @include mobile {
          margin-top: 20px;
        }
      }
    }
    &.no-filter {
      padding-bottom: 72px;
    }
    .error-msg {
      background-color: $color-red-100 !important;
      font-weight: $font-helvetica-med;
      color: $white;
    }
  }
  .form-block {
    &.complex {
      input {
        height: 3.5rem;
        line-height: 3.5rem;
        font-size: 1rem;
        padding: 0 1.25rem;
        @include tablet {
          padding: 0 1rem;
        }
        &.filled {
          padding-top: 1rem;
        }
      }
      label {
        left: 1.25rem;
        top: 1rem;
        font-size: 1rem;
        color: #282a32;
        @include tablet {
          left: 1rem;
          line-height: 1;
        }
      }
      input + label .label-text {
        position: relative;
      }
      input:focus + label,
      input.filled + label {
        .label-text {
          display: block;
          font-size: 0.75rem;
        }
      }
      input:focus + label,
      input.filled + label {
        top: 0.5rem;
        opacity: 1;
      }
    }
    .error-msg {
      background-color: $white-light;
      font-weight: $font-helvetica-med;
    }
  }
}

.search-everything-results {
  .cta {
    padding: 60px 0;
    text-align: center;
  }
  .alert-light {
    color: $color-darkgray !important;
  }
  .everything-search-item {
    padding-top: 24px;

    &:last-child {
      .container {
        border: 0;
      }
    }
    .container {
      border-bottom: 1px solid $color-dark-border;
      padding-bottom: 16px;
      .search-item-img {
        img {
          display: block;
          width: 100%;
          border-radius: 0.8125rem;
        }
      }
      .hidden {
        @include mobile {
          display: none;
        }
      }
    }
    .row {
      align-items: center;
    }
    .content {
      padding-right: 60px;
      @include mobile {
        padding-right: 0;
      }
      .title {
        @include heading(3);
        font-weight: 600;
        margin-bottom: 16px;
        color: $color-darkgray;
        text-transform: none;
        letter-spacing: initial;
      }
      p {
        margin: 0;
        font-size: 1rem;
        line-break: 28px;
        line-height: 22px;
      }
      a {
        display: inline-block;
        color: #e0690a;
        font-size: 1.5rem;
        font-weight: 600;
        font-family: $font-heading;
        letter-spacing: 1.2px;
        padding: 0;
        text-decoration: none !important;
        transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        line-height: 30px;
      }
    }
  }
}
.search-ribbon,
.search-pagination {
  .search-ribbon-wrap,
  .search-pagination-wrap {
    &.alert {
      border-bottom: none;
      border-radius: unset;
      background-color: $color-maroon;
      .search-ribbon-inner {
        flex-direction: row-reverse;
        justify-content: space-between;
        @include mobile {
          flex-direction: column-reverse;
        }
      }
      .results .text {
        font-family: $font-copy;
        color: $white;
        position: relative;
        bottom: 5px;
        margin-right: 20px;
        @include mobile {
          margin-right: 0;
        }
        &:before {
          content: '';
          position: relative;
          top: 5px;
          margin-right: 8px;
          background-image: url('../../../../src/assets/images/icon__alert--white.svg');
          background-size: 28px 24px;
          display: inline-block;
          width: 28px;
          height: 24px;
        }
      }
    }
  }
  .search-ribbon-inner {
    flex-wrap: nowrap;
    align-items: center;
    @include mobile {
      align-items: center;
    }
    .text {
      @include fontrem(16);
      font-weight: 400;
      line-height: 1.5;
    }
  }
  .results {
    order: 1;
    @include mobile {
      margin-bottom: 3px;
    }
  }
}
.search-pagination {
  border-top: 1px solid $color-light-border;
  border-bottom: 1px solid $color-light-border;
  .search-pagination-wrap {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    .page-list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      @include mobile {
        margin-bottom: 20px;
      }
      .page-item {
        margin: 0 15px;
        font-family: $font-helvetica-bold;
        font-weight: 400;
        font-size: 1.125rem;
        @include mobile {
          margin: 0 7px;
        }
        &:first-child {
          margin-left: 0;
          span {
            font-size: 1.125rem;
            font-weight: 600;
            margin-right: 5px;
          }
        }
        .page-link {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          display: inline-flex;
          padding: inherit;
          border: none;
          color: $color-blue;
          font-family: $font-helvetica-bold;
          font-weight: 400;
          font-size: 1.125rem;
          border-bottom: 1px solid $color-blue;
          background-color: unset;
        }
        .more {
          border-bottom: 0;
          span {
            font-size: 1.25rem;
            font-weight: 600;
          }
        }
        &.prev,
        &.next {
          &-disabled {
            cursor: not-allowed;
            .disabledCursor {
              color: $color-blue;
              opacity: 0.5;
              pointer-events: none;
              span {
                font-size: 1.25rem;
                font-weight: 600;
              }
              &:hover {
                color: $color-blue;
              }
            }
          }
        }
      }
      .page-item.active .page-link {
        border-bottom: none;
        color: $color-darkgray;
        background: none;
      }
    }
  }
}
.search-ribbon {
  .search-ribbon-wrap {
    display: none;
    // padding: 15px 0;
    padding: 15px 60px 15px 4%;
    border-bottom: 1px solid $color-dark-border;
    &.active {
      display: block;
    }
    @include tablet {
      padding: 15px 10%;
    }
    @include mobile {
      padding: 15px;
    }
  }
  .search-ribbon-inner {
    display: flex;
    flex-wrap: wrap;
  }
  .results {
    flex: 1;
    order: 1; //2;
    @include mobile {
      order: 1;
      width: 100%;
      flex: auto;
      margin-bottom: 10px;
    }
  }

  .text {
    font-family: $font-heading;
  }
}
.alert-inline {
  padding-top: 25px;
}
