@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';


.related-stories {
    padding: 24px;

    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }

    ul {
        list-style: none;
        padding: 0;

        .related-story-item {
            margin-bottom: 16px;         
            border-bottom: 1px solid $pale-grey;
        }
    }

    .related-story-content {
        display: flex;
        padding: 16px 0px;

        .related-story-image {
            flex: 0 0 30%;
            max-width: 30%;
            margin-right: 12px;
            height: 90px;
            color: $mpg-color-gray-100;
        }

        .related-story-text {
            flex: 0 0 70%;
            max-width: 70%;
            margin-right: 12px;
            h3{
                font-family: Arial;
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                margin-right: 12px;
                color: $mpg-color-gray-100;
            }
        }
    }

    .view-more {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 16px 0px;
        color:  $mpg-color-orange-55;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-transform: uppercase;

    }



}