@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.author-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px 0px;

    .author-header {
        font-family: $font-heading;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        text-align: left;
        padding: 0px;
    }

    .author-body {
        display: flex;
        gap: 24px;
        padding: 0;

        .author-image {
            img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }
        }

        .author-info {
            display: flex;
            gap: 24px;
            flex-direction: column;

            .author-about {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .author-details {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    .author-name {
                        font-family: Arial;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 22px;
                        text-align: left;
                    }

                    .author-designation {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 13px;
                        text-align: left;
                        color: rgba(103, 105, 111, 1);
                    }
                }

                .author-saying {
                    font-family: Arial;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                }
            }

            .cta-container {
                margin-left: auto;
                display: flex;
                cursor: pointer;

                .cta-link {
                    font-family: $font-heading;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 600;
                    color: $mpg-color-orange-55;
                    text-decoration: underline;
                    text-align: right;
                    display: flex;
                    align-items: right;

                    @include mobile {
                        font-size: 17px;
                    }
                }
            }
        }
    }
}

@media (max-width: 575px) {

    .author-container {
        padding: 24px;

        .author-body {
            gap: 24px;
            height: auto;

            .author-image {
                flex: 1 1 10%;
            }

            .author-info {
                flex: 1 1 90%;

                .cta-container {
                    margin-left: 0%;
                }
            }
        }
    }
}