.social-card-insights{
    width: 296px;
    border-radius: 14px;
    padding: 24px;
    gap: 16px;
    background-color: rgba(239, 239, 239, 1) ;
    position: relative;
    overflow: hidden;
    margin: 20px 0px;
    @media (max-width: 768px) {
        justify-self: center;
    }
    @media (min-width: 1024px) and (max-width: 1400px) {
      width: 272px;
    }
}
.social-card-insights-icon-circle {
    width: 76px;
    height: 76px;
    border-radius: 40px;
    border: 4px solid rgba(147, 148, 152, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 5px 0px 30px;
    position: relative;
  }

  .social-card-bg-gradient-one{
    border-radius: 100%;
    background: linear-gradient(50deg, #FFF 3.24%, rgba(255, 255, 255, 0.00) 40.53%);
    position: absolute;
    right: -26px;
    top: -197px;
    width: 309px;
    height: 309px;
  }

  .social-card-bg-gradient-two{
    position: absolute;
    left: -86px;
    bottom: -183px;
    border-radius: 100%;
    background: linear-gradient(251deg, #FFF 8.52%, rgba(255, 255, 255, 0.00) 46.73%);
    width: 309px;
    height: 309px;
  }

  .social-card-insights-content{
    position: relative;
    z-index: 1;
  }

  .social-card-insights-text{
    margin: 10px 0px 20px;
    position: relative;
    z-index: 1;

  }
  .social-card-insights-title{
    font-weight: 600;
  }
