@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.search-filters{
    .overlay-custom {
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: 99;
        top: 0;
        left: 0;
        float: left;
        text-align: center;
        animation: slideUp 0.3s ease-in-out;
        margin-top: 25%;
        background: white;
        //padding: 15px;
        margin-top: 17%;
      }
      
      .overlay-content {
        background: white;
        width: 100%;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        padding: 0px 20px;
        border-radius: 10px;
        margin: 5px 0px;
      }
      
    .close-overlay {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid $pale-grey;

        .overlay-title {
            flex: 1;
            text-align: left;
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
        }

        .clear-all {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; 
            text-decoration-line: underline;
        }
    }
      
      @keyframes slideUp {
        from {
          transform: translateY(100%);
        }
        to {
          transform: translateY(0);
        }
      }

    .overlay-buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 12px;
      background: $white;
      box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.08);
      display: flex;
      justify-content: space-around;

      button {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 100% */
        text-transform: uppercase;
        border: 0px;
        background: none;
      }

      .apply-button{
        padding: 16px 32px;
        border-radius: 58px;
        background: $mpg-color-orange-100;
        color: $white;
      }
    }

}

.search-filters-panel{
    @include mobile{
        margin:10px 0px
    }
    .search-filter-layout{
        @include mobile{
            padding:0px 15px;
        }
    }
}

.filter-count{
  display: none;
  @include mobile{
    display: block;
    color: $white;
    font-size: 16px;
    line-height: 16px;
    border-radius: 80px;
    background: $color-celestial-blue;
    display: flex;
    width: 24px;
    justify-content: center;
    align-items: center;
  }
}
