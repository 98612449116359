@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';

.country-search {
  // width: 300px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .list-unstyled a {
    font-size: 16px !important;
    line-height: 22px;
  }
}
.modal.show .fullscreen {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  // min-height: 600px;
  @include mobile {
    padding-left: 0px !important;
  }
  .modal-content {
    height: 100%;
    border: 0;
    border-radius: unset !important;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;

    // @include desktop {
    //   top: 96px;
    // }
    // @include tablet {
    //   top: 65px;
    // }
    // @include mobile {
    //   top: 65px;
    // }
    .modal-header {
      padding-bottom: 0;
      .close {
        //margin: 2rem 1rem -1rem auto;
        span {
          font-size: 25px;
        }
      }
    }
  }

  .modal-body {
    padding-top: 0;
    // overflow-y: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .country-links {
    padding: 0px;
  }
}
