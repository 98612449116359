@import '../../../styles/scss/fonts';
@import '../../../styles/scss/colors';
@import '../../../styles/scss/mixins';
@import '../../../styles/scss/theme';

.search-accordion-section {
  width: 100%;
  .accordion-section {
    text-align: center;
    padding: 0;
    .title {
      @include heading(2);
      padding-bottom: 10px;
      margin-bottom: 0px;
    }
    p,
    span {
      font-size: 20px;
      line-height: 32px;
      color: $color-darkgray;
      margin: 0;
      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
      &.text {
        padding: 10px 0px 35px 0px;
      }
      &.content {
        padding: 10px 0px 35px 0px;
        font-size: 16px;
        line-height: 20px;
        @include mobile {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &.section-heading {
        font-size: 24px;
        line-height: 34px;
        @include mobile {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .accordion-content {
      ul {
        padding-left: 20px;
        li {
          font-size: 20px;
          line-height: 32px;
          color: $color-darkgray;
          @include mobile {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .accordion-block {
    position: relative;
    text-align: left;
    border-bottom: 1px solid $mpg-color-gray-25;
    padding: 20px 4px 20px 0;
    @include mobile {
      padding: 10px 4px 10px 0;
    }
    cursor: auto;
    &.active {
      .icon {
        &:after {
          transform: rotate(180deg);
        }
      }
      .accordion-content {
        display: block;
        font-size: 20px;
        @include mobile {
          font-size: 14px;
        }
      }
    }
    &:first-child {
      // border-top: 1px solid $color-light-body;
    }
    &:last-child {
      margin-bottom: 24px;
      @include mobile {
        margin-bottom: 12px;
      }
    }
    .icon {
      position: absolute;
      font-size: 36px;
      right: 0 !important;
      top: 10px;
      color: $color-light-body;
      font-family: $font-heading;
      @include mobile {
        top: 3px;
      }
      &:after {
        display: block;
        content: '';
        background-size: cover;
        width: 18px;
        height: 18px;
        margin-top: 18px;
        background-image: url('../../../assets/images/icon-down.svg');
        background-size: 100%;
        background-position: center;
        box-sizing: border-box;
        background-repeat: no-repeat;
        @include mobile {
          width: 16px;
          height: 16px;
          margin-top: 10px;
        }
      }
    }
    .accordion-title {
      width: 90%;
      font-size: 1rem;
      line-height: 22px;
      margin-bottom: 0;
      display: block;
      align-items: center;
      font-family: $font-heading;
      text-transform: capitalize;
      font-weight: 600;
      cursor: pointer;
      .sort-order {
        display: inline;
        font-size: 1.25rem;
        line-height: 1.7;
        font-weight: 100;
      }
      .choice {
        font-family: $font-copy;
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 1.42;
        margin-bottom: 0;
      }
      img {
        margin-right: 12px;
      }
      .missing-text {
        color: $color-maroon;
        opacity: 1;
        font-size: 14px;
        font-style: italic;
        margin-left: 10px;
      }
      .filter-dot {
        display: inline-block;
        background-color: $color-blue-100;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 8px;
        padding-right: 10px;
      }
      .info-icon {
        font-size: 10px;
        text-align: center;
        margin-left: 10px;
        margin-top: 4px;
        display: inline-block;
        img {
          max-height: 2rem;
          height: 1.125rem;
          margin-top: -8px;
        }
      }
    }
    .accordion-content {
      margin: 0;
      display: none;
      margin-top: 15px;
    }
  }
  .disability-tooltip {
    position: absolute;
    padding-top: 3px;
    z-index: 1;
    .disability-dropdown-inside {
      background-color: #fff;
      padding: 20px;
      border: 1px solid #ababab;
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
    .triangle {
      position: absolute;
      width: 20px;
      height: 20px;
      top: -7px;
      left: 36%;
      transform: translateX(-50%);
      background-color: #fff;
      transform: rotate(45deg);
      transition: 0.25s ease-in-out;
      border-top: 1px solid #ababab;
      border-left: 1px solid #ababab;
    }
  }
}

.search-filters-global {
  .container {
    @include mobile {
      padding: 20px;
    }
  }
  .accordion-block {
    .accordion-content {
      overflow-y: auto;
      max-height: 500px;
      @include mobile {
        // max-height: 250px;
      }
      .checklist__item:last-child {
        margin-bottom: 13px;
      }
      .form-block {
        label {
          flex-basis: 80%;
          // left: -45px;
          cursor: pointer;
          font-size: 1rem;
          @include mobile {
            padding-top: 3px;
            width: 254px;
          }

          @media (min-width: 1024px) and (max-width: 1400px) {
            width: 220px;
          }
        }
        span {
          @include desktop {
            padding-top: 3px;
          }
        }
      }
      .form-check {
        padding-left: 0rem;
        padding-top: 2px;
        position: relative;
        margin-bottom: 16px;
        label {
          flex-basis: 80%;
          color: $color-darkgray-100;
          font-size: 1rem;
          line-height: 1.5;
          position: relative;
          left: -10px;
          padding-right: 20px;
          cursor: pointer;
        }
        &.radio-toggle {
          input[type='radio']:disabled {
            cursor: not-allowed;
          }
          input[type='radio']:disabled + label {
            color: $color_darkgray_25;
          }
          input[type='radio'] {
            height: 18px;
            width: 18px;
            background-color: $white;
            border: 1px solid $color-lightgray;
            left: -13px;
            top: 3px;
            border-radius: 50%;
            cursor: pointer;
          }

          input[type='radio']:checked:after {
            background-color: $color-blue-100;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            top: 2px;
            left: 2px;
            position: absolute;
            border: 1px solid $color-lightgray;
            @include mobile {
              top: 2px !important;
            }
          }
          input[type='radio']:checked::-ms-check {
            color: $color-blue-100;
            background-color: $color-blue-100;
            width: 16px;
            height: 16px;
          }
        }
      }
      .checkbox {
        label {
          padding-top: 2px;
          &:before {
            border: 1px solid $color-darkgray-100;
            border-radius: 2px;
         }
        }
        input[type='checkbox']:checked + label::before {
          background-color: $color-blue-100;
        }
        label:before {
          top: 6px;
          cursor: pointer;
        }
        label:after {
          top: 12px;
          @include mobile{
            left: 4px;
            top: 5px !important;
          }
        }
      }
    }
  }
}
.search-filters-global .accordion-block .accordion-content::-webkit-scrollbar-track {
  padding: 2px 0;
  background-color: $color-platinum;
  border-radius: 10px;
}

.search-filters-global .accordion-block .accordion-content::-webkit-scrollbar {
  width: 10px;
}

.search-filters-global .accordion-block .accordion-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-dark-black;
}
.topicSearch {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 40px;
  font-size: 16px;
  background-color: white;
  background-image: url('../../../assets/images/Insights/Icon.png');
  background-position: right 18px bottom 7px;
  background-repeat: no-repeat;
  padding: 5px 22px;
  background-color: $mpg-color-gray-25;
  @include mobile {
    font-size: 14px;
  }
}

.checkbox {
  @include mobile {
    label {
      font-size: 13px;
      &:after {
        left: 5px;
        top: 6px;
      }
    }
  }

}


